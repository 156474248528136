import {
  ICalendarFilterOption,
  IFilterSelectionType,
  ISavedFilterOptions
} from 'model/calendar/filters';
import moment from 'moment';
interface FilterSelectionType {
  id: number;
}
interface FilterSelection {
  selectedId: string;
  type: FilterSelectionType;
}

export enum FilterPageNameEnum {
  planner = 'planner',
  waitList = 'waitList',
  provider = 'provider',
  client = 'client'
}

export enum savedFilterNameEnum {
  default = 'default',
  saved = 'saved'
}

export const mapInternalsToFilterSelections = (
  values: any[] | undefined,
  typeId: number
) => {
  let mappedInternals: FilterSelection[] = [];
  values?.forEach(internal => {
    const filterSelection = {
      selectedId: internal,
      type: { id: typeId }
    };
    mappedInternals.push(filterSelection);
  });

  return mappedInternals;
};

export const getFilterSelections = (
  filter: ISavedFilterOptions,
  filterSelectionType: IFilterSelectionType
) => {
  const locations =
    filter?.locations!.length > 0
      ? mapToFilterSelections(
          filter?.locations,
          filterSelectionType['locations']
        )
      : mapToAdditionalFilterSelections(
          filter?.additionalFilter?.locations,
          filterSelectionType['locations']
        );
  const filterSelections = [
    ...locations,
    ...mapToFilterSelections(
      filter?.departments,
      filterSelectionType['departments']
    ),
    ...mapToFilterSelections(filter?.clinics, filterSelectionType['clinics']),
    ...mapToFilterSelections(filter?.programs, filterSelectionType['programs']),
    ...mapToFilterSelections(
      filter?.specialities,
      filterSelectionType['specialities']
    ),
    ...mapInternalsToFilterSelections(
      filter?.internals,
      filterSelectionType['internal']
    ),
    ...mapToFilterSelections(
      filter?.waitListReason,
      filterSelectionType['waitListReason']
    ),
    ...mapToFilterSelections(filter?.language, filterSelectionType['language']),
    ...mapToFilterSelections(filter?.status, filterSelectionType['status']),
    ...mapToFilterSelections(
      filter?.therapyType,
      filterSelectionType['therapyType']
    ),
    ...mapToFilterSelections(
      filter?.serviceType,
      filterSelectionType['serviceType']
    ),
    ...mapToFilterSelections(
      filter?.clientAvailability,
      filterSelectionType['clientAvailability']
    ),
    ...mapToFilterSelections(
      filter?.smartSchedulingStatus,
      filterSelectionType['smartSchedulingStatus']
    ),
    ...mapToFilterSelections(
      filter?.insuranceCategories,
      filterSelectionType['insuranceCategory']
    ),
    ...mapToFilterSelections(
      filter?.insurancePayors,
      filterSelectionType['insurancePayor']
    ),
    ...mapToFilterSelections(
      filter?.insurancePlans,
      filterSelectionType['insurancePlan']
    ),
    ...mapToFilterBoolean(filter?.isVBC, filterSelectionType['isVBC']),
    ...mapToAdditionalFilterSelections(
      filter?.additionalFilter?.days,
      filterSelectionType['days']
    ),
    ...mapToTimeFilterSelections(
      filter?.additionalFilter?.startTime !== null
        ? filter?.additionalFilter?.startTime
        : undefined,
      filterSelectionType['startTime']
    ),
    ...mapToTimeFilterSelections(
      filter?.additionalFilter?.endTime !== null
        ? filter?.additionalFilter?.endTime
        : undefined,
      filterSelectionType['endTime']
    )
  ];

  return filterSelections;
};

export const mapToFilterSelections = (
  list: ICalendarFilterOption[] | undefined,
  typeId: number
) => {
  let mappedValues: FilterSelection[] = [];

  list?.forEach((item: ICalendarFilterOption) => {
    const filterSelection = {
      selectedId: item.value.toString(),
      type: { id: typeId }
    };
    mappedValues.push(filterSelection);
  });

  return mappedValues;
};

export const mapToFilterBoolean = (
  isVBC: boolean | undefined,
  typeId: number
) => {
  let mappedValues: FilterSelection[] = [];
  if (isVBC) {
    const filterSelection = {
      selectedId: isVBC.toString(),
      type: { id: typeId }
    };
    mappedValues.push(filterSelection);
  }
  return mappedValues;
};

export const mapToAdditionalFilterSelections = (
  list: number[] | String[] | undefined,
  typeId: number
) => {
  let mappedValues: FilterSelection[] = [];

  list?.forEach((item: number | String) => {
    const filterSelection = {
      selectedId: item.toString(),
      type: { id: typeId }
    };
    mappedValues.push(filterSelection);
  });

  return mappedValues;
};

export const mapToTimeFilterSelections = (
  time: string | undefined,
  typeId: number
) => {
  let mappedValues: FilterSelection[] = [];
  if (time !== undefined) {
    const filterSelection = {
      selectedId: moment(time)
        .format('HH:mm')
        .toString()!,
      type: { id: typeId }
    };
    mappedValues.push(filterSelection);
  }
  return mappedValues;
};
