import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { CheckListWrapper, CalenderWrapper } from './style';
import { Input, InputOnChangeData } from 'semantic-ui-react';
import { useQuery } from '@apollo/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import DepartmentFilter from './departmentFilter';
import LocationFilter from './locationFilter';
import ClinicFilter from './clinicFilter';
import SavedFilters from './savedFilters';
import ProgramFilter from './programFilter';
import InternaFilter from './statusFilter';
import ProviderCertificationFilter from './providerCertificationFilter';
import {
  calendarFiltersPrefVar,
  defaultFilterPrefVar
} from 'utils/cache/filters';
import {
  ICalendarFilterDataV2,
  ICalendarFilterOption
} from 'model/calendar/filters';
import Loader from '../../../views/components/ui/content/Loader';
import useCalendarFilterFetcherHook from 'hooks/calendarFilterFetcherHook';
import useClickOutsideHook from 'hooks/clickOutsideHook';
import { useReactiveVar } from '@apollo/client';
import FilterTagForm from 'components/calendar/calendarToolbar/FilterTagList/form';
import { mapToOptions } from 'utils/mappers/form';
import { internalFilters } from 'utils/constants/calendarFilters';
import {
  EVENTS_FETCHING_ACTIONS,
  plannerContext
} from 'pages/MainCalendarPage';
import { GET_CALENDAR_FILTERS } from 'api/graphql/v2/queries/Filters';
import { GET_ALL_CORTICA_PROGRAMS } from 'api/graphql/v2/queries/Programs';
import { FilterPageNameEnum } from '../calendarToolbar/FilterTagList/form/utils';

export const searchValueContext = React.createContext('');
export const checkedSavedFilterContext = React.createContext<any>(undefined);

const CalendarFilters: React.FC = () => {
  const { setAction } = useContext(plannerContext);
  const { data, loading } = useQuery<ICalendarFilterDataV2>(
    GET_CALENDAR_FILTERS
  );

  const { data: allCorticaProgramOptions } = useQuery(
    GET_ALL_CORTICA_PROGRAMS,
    {
      onError: (error: any) => {
        console.error('Error fetching Cortica Program options: ', error);
      }
    }
  );

  const {
    defaultFilter,
    savedFilters,
    savedFiltersMap
  } = useCalendarFilterFetcherHook(FilterPageNameEnum.planner);
  const [visible, setVisible] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [checkedSavedFilter, setCheckedSavedFilter] = useState<string>('');
  const filtersRef = useRef<HTMLDivElement>(null);
  const { isLoading, isBulkCancelMode } = useContext(plannerContext);

  const closeFiltersList = useCallback(() => {
    setVisible(false);
  }, []);

  useClickOutsideHook(filtersRef, closeFiltersList);

  //Checked Filters
  const [checkedLocations, setCheckedLocations] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [checkedClinics, setCheckedClinics] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [checkedPrograms, setCheckedPrograms] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [checkedCertifications, setCheckedCertifications] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [checkedDepartments, setCheckedDepartments] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [checkedSpecialities, setCheckedSpecialities] = React.useState<
    ICalendarFilterOption[]
  >([]);

  const [checkedInternals, setCheckedInternals] = React.useState<
    ICalendarFilterOption[]
  >([]);

  const useDefaultFilter = useReactiveVar(defaultFilterPrefVar);
  const filters = useReactiveVar(calendarFiltersPrefVar);

  const [hasLocations, setHasLocations] = useState(true);
  const [hasClinics, setHasClinics] = useState(true);
  const [hasPrograms, setHasPrograms] = useState(true);
  const [hasCertifications, setHasCertifications] = useState(true);
  const [hasStatus, setHasStatus] = useState(true);
  const [editFilterModal, setEditFilterModal] = useState(false);
  const [clickedFilter, setClickedFilter] = useState('');

  const [
    hasDepartmentsOrSpecialities,
    setHasDepartmentsOrSpecialities
  ] = useState(true);
  const [hasSavedFilters, setHasSavedFilters] = useState(true);

  const [dataExist, setDataExist] = useState(true);

  const toggleList = () => {
    if (isBulkCancelMode) return;
    setVisible(prevState => !prevState);
  };

  const handleSearch = (
    _e: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setVisible(true);
    setSearchValue(data?.value);
  };

  const handleSearchedValues = useCallback(
    (list: ICalendarFilterOption[]) => {
      return searchValue === ''
        ? list
        : list?.filter(option => {
            return option.label
              ?.toLowerCase()
              .startsWith(searchValue?.toLowerCase());
          });
    },
    [searchValue]
  );

  const handleSavedFilter = useCallback(
    (name: string) => {
      const savedFilter = savedFiltersMap.get(name);
      setCheckedSpecialities(savedFilter.specialities);
      setCheckedClinics(savedFilter.clinics);
      setCheckedPrograms(savedFilter.programs);
      setCheckedCertifications(savedFilter.providerCertifications);
      setCheckedDepartments(savedFilter.departments);
      setCheckedLocations(savedFilter.locations);
      setCheckedSavedFilter(name);
      setCheckedInternals(savedFilter.internals);
    },
    [savedFiltersMap]
  );

  const applyFilters = useCallback(() => {
    setVisible(false);
    defaultFilterPrefVar(false);
    calendarFiltersPrefVar({
      clinics: checkedClinics,
      programs: checkedPrograms,
      providerCertifications: checkedCertifications,
      locations: checkedLocations,
      departments: checkedDepartments,
      specialities: checkedSpecialities,
      savedFilterName: checkedSavedFilter,
      internals: checkedInternals.map(s => s.value as internalFilters)
    });
    setAction(EVENTS_FETCHING_ACTIONS.APPLY_FILTERS);
  }, [
    checkedClinics,
    checkedPrograms,
    checkedCertifications,
    checkedLocations,
    checkedDepartments,
    checkedSpecialities,
    checkedSavedFilter,
    checkedInternals,
    setAction
  ]);

  const clearAll = useCallback(() => {
    setCheckedSpecialities([]);
    setCheckedClinics([]);
    setCheckedPrograms([]);
    setCheckedCertifications([]);
    setCheckedDepartments([]);
    setCheckedLocations([]);
    setCheckedSavedFilter('');
    setCheckedInternals([]);
  }, []);

  useEffect(() => {
    setDataExist(
      hasLocations ||
        hasClinics ||
        hasPrograms ||
        hasCertifications ||
        hasDepartmentsOrSpecialities ||
        hasSavedFilters ||
        hasStatus
    );
  }, [
    hasLocations,
    hasClinics,
    hasPrograms,
    hasCertifications,
    hasDepartmentsOrSpecialities,
    hasSavedFilters,
    hasStatus
  ]);

  useEffect(() => {
    if (defaultFilter && useDefaultFilter) {
      if (filters.savedFilterName === defaultFilter.name) {
        return;
      }
      setCheckedDepartments(defaultFilter?.departments!);
      setCheckedLocations(defaultFilter?.locations!);
      setCheckedClinics(defaultFilter?.clinics);
      setCheckedPrograms(defaultFilter?.programs!);
      setCheckedCertifications(defaultFilter?.providerCertifications!);
      setCheckedSpecialities(defaultFilter?.specialities!);
      setCheckedSavedFilter(defaultFilter?.name || '');
      setCheckedInternals(
        (defaultFilter?.internals || []) as ICalendarFilterOption[]
      );
      calendarFiltersPrefVar({
        clinics: defaultFilter?.clinics,
        programs: defaultFilter?.programs,
        providerCertifications: defaultFilter?.providerCertifications,
        locations: defaultFilter?.locations,
        departments: defaultFilter?.departments,
        specialities: defaultFilter?.specialities,
        savedFilterName: defaultFilter?.name || '',
        internals: (((defaultFilter?.internals ||
          []) as ICalendarFilterOption[])?.map(s => s.value) ||
          []) as internalFilters[]
      });
      setAction(EVENTS_FETCHING_ACTIONS.APPLY_FILTERS);
      defaultFilterPrefVar(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter, useDefaultFilter]);

  useEffect(() => {
    setCheckedDepartments(filters?.departments!);
    setCheckedLocations(filters?.locations!);
    setCheckedClinics(filters?.clinics);
    setCheckedPrograms(filters?.programs!);
    setCheckedCertifications(filters?.providerCertifications!);
    setCheckedSpecialities(filters?.specialities!);
    setCheckedSavedFilter(filters?.savedFilterName || '');
    setCheckedInternals(mapToOptions(filters?.internals, true));
  }, [filters]);

  const onCloseModal = useCallback(() => {
    setEditFilterModal(false);
  }, [setEditFilterModal]);

  const onSetClickedFilter = useCallback(
    label => {
      setClickedFilter(label);
      setEditFilterModal(true);
    },
    [setClickedFilter, setEditFilterModal]
  );

  return (
    <CalenderWrapper>
      <CheckListWrapper width={258} visible={visible} height={'300px'}>
        <div ref={filtersRef}>
          <div className="filter-input_wrapper" onClick={toggleList}>
            <Input
              className="filter_input"
              placeholder={visible ? 'Search' : 'Filters'}
              onChange={handleSearch}
              disabled={isBulkCancelMode}
            />
            {!visible && (
              <FontAwesomeIcon className="icon" icon={faCaretDown} />
            )}
          </div>

          {visible && (
            <>
              <checkedSavedFilterContext.Provider value={setCheckedSavedFilter}>
                <searchValueContext.Provider value={searchValue}>
                  <div className="list_wrapper">
                    <div className="scrollable_list">
                      {!dataExist && (
                        <p className="no-results_filter">No results found</p>
                      )}
                      {loading && <Loader />}
                      <SavedFilters
                        filters={savedFilters}
                        selectSavedFilter={handleSavedFilter}
                        savedFilter={checkedSavedFilter}
                        defaultFilter={defaultFilter}
                        handleSearchedValues={handleSearchedValues}
                        setHasData={setHasSavedFilters}
                        setClickedFilter={onSetClickedFilter}
                        pageName={FilterPageNameEnum.planner}
                      />
                      <ProgramFilter
                        programsList={
                          allCorticaProgramOptions?.corticaPrograms || []
                        }
                        checkedPrograms={checkedPrograms}
                        setCheckedPrograms={setCheckedPrograms}
                        handleSearchedValues={handleSearchedValues}
                        setHasData={setHasPrograms}
                      />
                      <ProviderCertificationFilter
                        providerCertificationsList={
                          data?.calenderFilters.providerCertifications || []
                        }
                        checkedProviderCertifications={checkedCertifications}
                        setCheckedProviderCertifications={
                          setCheckedCertifications
                        }
                        handleSearchedValues={handleSearchedValues}
                        setHasData={setHasCertifications}
                      />
                      <ClinicFilter
                        clinicsList={
                          data?.calenderFilters?.clinicOptionsList || []
                        }
                        checkedClinics={checkedClinics}
                        setCheckedClinics={setCheckedClinics}
                        handleSearchedValues={handleSearchedValues}
                        setHasData={setHasClinics}
                      />
                      <DepartmentFilter
                        data={data?.calenderFilters}
                        checkedDepartments={checkedDepartments}
                        setCheckedDepartments={setCheckedDepartments}
                        checkedSpecialities={checkedSpecialities}
                        setCheckedSpecialities={setCheckedSpecialities}
                        handleSearchedValues={handleSearchedValues}
                        setHasData={setHasDepartmentsOrSpecialities}
                      />
                      <LocationFilter
                        checkedLocations={checkedLocations}
                        setCheckedLocations={setCheckedLocations}
                        handleSearchedValues={handleSearchedValues}
                        setHasData={setHasLocations}
                      />
                      <InternaFilter
                        internalsList={
                          data?.calenderFilters?.eventFilterStatus || []
                        }
                        checkedInternals={checkedInternals}
                        setCheckedInternals={setCheckedInternals}
                        handleSearchedValues={handleSearchedValues}
                        setHasData={setHasStatus}
                      />
                    </div>
                    <div className="calender-filters_actions">
                      <span onClick={clearAll}>Clear all</span>
                      <button onClick={applyFilters} disabled={isLoading}>
                        Apply
                      </button>
                    </div>
                  </div>
                </searchValueContext.Provider>
              </checkedSavedFilterContext.Provider>
            </>
          )}
          {editFilterModal && (
            <FilterTagForm
              filterName={clickedFilter}
              onClose={onCloseModal}
              pageName={FilterPageNameEnum.planner}
            />
          )}
        </div>
      </CheckListWrapper>
    </CalenderWrapper>
  );
};
export default React.memo(CalendarFilters);
