import React from 'react';
import moment, { Moment } from 'moment';
import { EVENT_OCCURENCE } from 'utils/constants/lists';
import { TimeViewWrapper } from './style';
import { IEvent } from 'model/v2';
import { Icon } from 'semantic-ui-react';
import Skeleton from '@material-ui/lab/Skeleton';
import momenttz from 'moment-timezone';

interface Props {
  event?: IEvent;
  isObservation?: boolean;
  isLoading?: boolean;
}
const TimeView: React.FC<Props> = ({
  event,
  isObservation,
  isLoading
}: Props) => {
  const startDate = event?.startDate;
  const endDate = event?.endDate;
  const originalEndDate = event?.originalEndDate;
  const originalStartDate = event?.originalStartDate;
  const appointmentType = event?.appointmentType;
  const recurrencePattern = event?.recurrencePattern;
  const timeZone = event?.clinic?.timezone;
  const ObservertimeZone = event?.leadEvent?.clinic?.timezone;

  const eventTimes = formatDatesClinicTimeZone(
    startDate,
    endDate,
    timeZone,
    isObservation
  );
  const eventOriginalTimes = formatDatesClinicTimeZone(
    originalStartDate,
    originalEndDate,
    timeZone
  );
  const recurringPattern = EVENT_OCCURENCE.find(
    x => x.value === recurrencePattern?.recurringEvery
  );
  const appointmentTypeName = appointmentType?.parent
    ? appointmentType.parent.title
    : appointmentType?.title;

  return (
    <TimeViewWrapper>
      {!isObservation ? (
        <>
          {!isLoading ? (
            <p className="appointment-type">{appointmentTypeName}</p>
          ) : (
            <Skeleton
              variant="text"
              children={
                <p className="appointment-type">{appointmentTypeName}</p>
              }
            />
          )}
          {!isLoading ? (
            <p className="event-date">{eventTimes?.date}</p>
          ) : (
            <Skeleton
              variant="text"
              children={<p className="event-date">{eventTimes?.date}</p>}
            />
          )}
          {!isLoading ? (
            <p className="event-time">{`${eventTimes?.time} ${timeZone}`}</p>
          ) : (
            <Skeleton
              variant="text"
              children={
                <p className="event-time">{`${eventTimes?.time} ${timeZone}`}</p>
              }
            />
          )}
          {eventOriginalTimes &&
            (!isLoading ? (
              <p className="event-original-time">{`${eventOriginalTimes?.time} ${timeZone}`}</p>
            ) : (
              <Skeleton
                variant="text"
                children={
                  <p className="event-original-time">
                    {`${eventOriginalTimes?.time} ${timeZone}`}
                  </p>
                }
              />
            ))}
          {!!recurringPattern &&
            (!isLoading && recurrencePattern?.recurringType ? (
              <p className="recurring-pattern">
                {`Recurring ${recurringPattern.label} through
            ${moment(recurrencePattern?.recurringUntil).format(
              'MMMM DD, YYYY'
            )}`}
              </p>
            ) : (
              <Skeleton
                variant="text"
                children={
                  <p className="recurring-pattern">
                    {`Recurring weekly through MMMM DD, YYYY`}
                  </p>
                }
              />
            ))}
        </>
      ) : (
        <>
          {!isLoading ? (
            <span>
              <p className="event-date">
                {' '}
                <Icon name="calendar outline" className="time-icon" />
                {eventTimes?.date}
              </p>
              <p className="event-time">
                <Icon name="clock outline" className="time-icon" />
                Observing {`${eventTimes?.time} ${ObservertimeZone}`}
              </p>
            </span>
          ) : (
            <Skeleton>
              <span>
                <p className="event-date">
                  {' '}
                  <Icon name="calendar outline" className="time-icon" />
                  {eventTimes?.date}
                </p>
                <p className="event-time">
                  <Icon name="clock outline" className="time-icon" />
                  Observing {`${eventTimes?.time} ${ObservertimeZone}`}
                </p>
              </span>
            </Skeleton>
          )}
        </>
      )}
    </TimeViewWrapper>
  );
};
export default React.memo(TimeView);

export const formatDates = (
  start: Date | Moment | undefined,
  end: Date | Moment | undefined,
  isObservationFormat?: boolean
) => {
  if (!start && !end) return null;
  return {
    date: isObservationFormat
      ? moment(start).format('dddd MMMM Do, YYYY')
      : moment(start).format('dddd MMMM DD, YYYY'),
    time: `${moment(start).format('hh:mm a')} - ${moment(end).format(
      'hh:mm a'
    )}`
  };
};

export const formatDatesClinicTimeZone = (
  start: any,
  end: any,
  timeZone: any,
  isObservationFormat?: boolean
) => {
  if (!start && !end) return null;
  return {
    date: isObservationFormat
      ? momenttz(start)
          .tz(timeZone)
          .format('dddd MMMM Do, YYYY')
      : momenttz(start)
          .tz(timeZone)
          .format('dddd MMMM DD, YYYY'),
    time: `${momenttz(start)
      .tz(timeZone)
      .format('hh:mm a')} - ${momenttz(end)
      .tz(timeZone)
      .format('hh:mm a')}`
  };
};
