import styled from 'styled-components';
import * as colors from 'utils/constants/theme';

export const ContentWrapper = styled.div`
  padding: 55px 55px 55px 46px;
  height: fit-content;
  background-color: ${colors.EDIT_FORM_BG};
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
    font-weight: normal;
  }
  .search-icon-container {
    width: 100%;
    margin-left: 10px;
  }
  .ant-input {
    border-radius: 4px;
  }
  grid-template-rows: minmax(10px, 80px);
  .grid-row {
    align-items: flex-start !important;
    width: fit-content;
  }
  .search-icon-waitList {
    margin-left: 50px !important;
  }
  .grid-row-address {
    margin-top: 10px;
  }
  .provider-details-container {
    position: relative;
    bottom: 88px;
  }
  .one-line {
    margin-right: 5px;
  }
  .grid-second-Cont {
    // background-color: red;
    width: 20% !important;
    max-width: 220px !important;
  }
  .basic-cont {
    position: relative;
    bottom: 10px;
    margin-bottom: 6px;
  }
  .basic-allergies-cont {
    max-width: 192px !important;
    position: relative;
    bottom: 8px;
  }
  .inner-Grid-last-cont {
    max-width: 160px !important;
    // font-weight: bold;
  }
  .grid-column-speciality {
    display: flex;
    flex-direction: column;
    text-align: left;
    min-width: 300px;
    max-width: 300px;
  }
  .grid-column-emp {
    display: flex;
    flex-direction: column;
    text-align: left;
    min-width: 138px;
    max-width: 138px;
  }
  .grid-column-emp-provider-opt {
    display: flex;
    flex-direction: column;
    text-align: left;
    min-width: 138px;
    max-width: 152px;
  }
  .grid-column-appt {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 300px;
    height: fit-content;
  }
  .grid-column-allow-self-scheduling {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 300px;
    height: fit-content;
  }
  .input-provider-address {
    width: 100%;
    height: 35px;
    z-index: 1;
  }
  .label-style .title {
    font-weight: bold;
  }
  .provider-label {
    font-weight: bold;
  }
  .provider-label-save {
    margin-bottom: 16px;
    font-weight: bold;
  }

  .warning {
    color: ${colors.RED} !important;
  }
  .basic-info {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    color: #415766;
  }
  .label-style {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: red;
    max-width: 340px;
  }
  .narrow-column {
    max-width: 162px;
    min-width: 162px;
  }
  .narrow-column-provider-opt {
    max-width: 182px;
    min-width: 188px;
  }
  .info-last-container {
    position: relative;
    right: 86px;
  }
  .label,
  .editable-label {
    width: 100%;
  }
  .editable-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .grid-second-row {
    bottom: 28px;
  }
  .provider-state-row {
    display: flex;
    margin-top: 20px;
  }
  .provider-state-col {
    width: 60%;
    margin-right: 10px;
  }
  .provider-zip-col {
    width: 39%;
  }

  .address-inpt {
    margin-bottom: 10px !important;
    height: 32px;
    width: 100%;
  }
  .address-inpt-sec {
    margin-bottom: 20px !important;
    height: 32px;
    width: 100%;
  }
  .city-row .address-inpt {
    margin: 18px 0px 20px 0px !important;
  }
  .basic-grid-row {
    justify-content: space-between !important;
  }

  .wide-column {
    max-width: 325px;
    min-width: 325px;
  }
  .extra-wide-colummn {
    min-width: 548px;
    max-width: 548px;
    margin-right: 24px;
  }
  .AddressLineOpt {
    margin-left: -40px;
    padding-top: 11px;
    position: relative;
    bottom: 87px;
    left: 286px;
    color: grey;
    font-size: 0.8em;
  }
  .provider-state {
    padding-top: 0px;
    bottom: 48px;
    margin-left: 10px;
  }
  .State-Select > .ant-select-selector {
    height: 36px !important;
  }
  .opt-Address {
    margin-left: 1%;
    margin-right: 1%;
  }
  .error-grid {
    color: #cc3f3f;
  }
  .state-Column {
    padding-left: 0px !important;
  }

  .grid-column-assitants {
    display: flex;
    flex-direction: column;
    text-align: left;
    min-width: 300px;
    max-width: 300px;
  }
  .assistant-base-col {
    margin-right: 80px;
  }
  .grid-column-assitant-items {
    align-items: 'center';
    display: flex;
  }
  .grid-column-assitant-item {
    padding: 0px 0px 10px 0px;
    float: left;
    width: 166px;
  }
  .trash-icon-assistant {
    color: ${colors.RED};
    padding: 4px 0px 0px 7px;
    float: left;
    display: flex;
    justify-content: space-between;
    width: 40px;
    margin-top: 6px;
  }
  .append-icon-assitant-sec {
    padding-left: 180px;
  }
  .assistant-inc-btn {
    color: rgb(111, 75, 241);
    height: 20px !important;
  }
  .asst-trash-icon {
    margin-top: -1px;
    margin-left: 4px;
  }
  .append-icon-assitant .assistant-inc-btn {
    margin-bottom: 3px;
  }
  .trash-icon-assistants {
    color: rgb(111, 75, 241);
    height: 18px;
  }
  .append-icon-assitant {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 0px 0px;
    bottom: 4px;
    position: relative;
  }
  .grid-row-speciality {
    align-items: top !important;
    width: fit-content;
  }
  .narrow-column-supervisor label {
    line-height: 2.4em;
  }
  .narrow-column-supervisor p {
    margin-top: 7px !important;
  }
  .grid-row > .column label {
    padding-bottom: 0 !important;
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 32px;
    color: rgba(0, 0, 0, 0.85);
  }
  .grid-row > .column label.label {
    height: auto;
    color: #415766;
  }
  .grid-column-links {
    display: block;
    width: 100% !important;
    padding: 2px !important;
    .grid-column-links {
      border: 1px solid blue;
    }
    .custom-input {
      width: 100% !important;
      display: flex;
    }
    .custom-grid-width {
      width: 35% important;
    }
    .text-input {
      width: 86%;
      padding-right: 3px;
      input {
        border: 1px solid #d9d9d9 !important;
      }
    }
    .icon-container {
      width: 7%;
      display: flex !important;
      align-items: center !important;
      align-items: center !important;
    }
    .align-flex-end {
    }
    input {
      width: 100% !important;
      border-radius: 4px !important;
      padding: 7px 5px;
      margin-bottom: 8px !important;
      border: 1px solid #d9d9d9 !important;
    }
  }
  .width-100 {
    width: 100% !important;
  }
  .info-text-small {
    color: rgb(111, 75, 241);
  }
  .add-link-button {
    padding: 3px 5px;
    color: #ffffff;
    background-color: rgb(111, 75, 241);
    border: 1px solid rgb(111, 75, 241);
    border-radius: 4px;
    cursor: pointer;
  }
  button {
    cursor: pointer;
  }
  .info-wrapper {
    width: 100%;
    display: flex;
    margin-bottom: 12px;
  }
  .color-purple {
    color: rgb(111, 75, 241);
  }
  .provider-info-outer-cont {
    width: 1308px;
  }
  .DisplayName {
    width: 80%;
  }
  .;
`;

export const LabelWrapper = styled.div`
  .title {
    font-family: Lato;
    font-size: 14px;
    color: #000000;
  }
  .label {
    font-family: Lato;
    font-size: 16px;
    color: #415766;
  }
  .disabled {
    border: 1px solid #dadee1;
    color: #676e72;
    background-color: #f5f5f5;
  }
`;

export const TelehealthWrapper = styled.div`
  overflow: auto;
  max-height: 220px;
  .ant-checkbox-wrapper {
    display: block;
    padding-left: 8px;
  }
  .ant-checkbox-group {
    display: block;
    padding-left: 20px;
  }
  .ant-checkbox-inner {
    display: inline-block;
  }
`;

export const TelehealthLinksWrapper = styled.div`
display: block;
width: 100% !important
padding: 2px !important;
.grid-column-links {
  border: 1px solid blue;
}
.custom-input{
  width: 100% !important; 
  display: flex;
}
.text-input{
  width: 70%;
  padding-right: 3px;
}
.width-30 {
  width: 15%;
  display: flex;
  align-items: center;
}
.input {
  width: 100% !important;
  border-radius: 4px !important;
  padding: 9px 5px;
  margin-bottom: 8px !important;
}
.color-red {
  color: ${colors.RED};
}
.button-mini {
  cursor: pointer;
}
`;

export const AssistantsWrapper = styled.div`
  overflow: auto;
  max-height: 220px;
  .ant-checkbox-wrapper {
    display: block;
    padding-left: 8px;
  }
  .ant-checkbox-group {
    display: block;
    padding-left: 20px;
  }
  .ant-checkbox-inner {
    display: inline-block;
  }
`;
