import gql from 'graphql-tag';
import { eventsFragments } from 'api/graphql/v2/fragments/Events';
import { TIMEZONE } from 'utils/constants/default';
import { providerFragments } from '../fragments/Providers';
import { AvailabilityTemplateFragments } from '../fragments/AvailabilityTemplate';

export const GET_EVENT = gql`
  query($id: Int!) {
    event(id: $id) {
      ...FullEventData
    }
    isLastEventInSeries(id: $id)
    isConverted(id: $id)
  }
  ${eventsFragments.fullEventData}
`;

export const IS_LAST_EVENT = gql`
  query($id: Int!, $masterId: Int) {
    isLastEventInSeries(id: $id, masterId: $masterId)
  }
`;

export const FETCH_DRIVE_TIME_ADDRESS = gql`
  query GetDriveEventAddress(
    $providerId: Int!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    getDriveEventAddress(
      providerId: $providerId
      startDate: $startDate
      endDate: $endDate
    ) {
      destinationAddress
      startingAddress
    }
  }
`;

export const GET_CLIENT_EVENTS_AND_AVAILABILITY = gql`
  query(
    $clientsIDs: [Int!]!
    $startDate: DateTime!
    $endDate: DateTime!
    $canceled: Boolean
    $isDelete: Boolean
  ) {
    clientsPlannerEvents(
      clientsIds: $clientsIDs
      startDate: $startDate
      endDate: $endDate
      canceled: $canceled
      isDelete: $isDelete
      timeZone: "${TIMEZONE}"
    ) {
      ...calendarPlannerEventData
    }
    clientsDayAvailabilities(
      clientIds: $clientsIDs
    ) {
      id
      dayAvailabilities {
        dayOfWeek
        startTime
        endTime
        location
      }
    }
  }
  ${eventsFragments.calendarPlannerEventData}
`;

export const GET_CONVERSION_STATUS = gql`
  query($id: Int!) {
    isConverted(id: $id)
  }
`;

export const GET_STAFF_EVENTS = gql`
  query(
    $providersIDs: [Int!]!
    $startDate: DateTime!
    $endDate: DateTime!
    $canceled: Boolean
    $isDelete: Boolean
  ) {
    providersPlannerEvents(
      providersIds: $providersIDs
      startDate: $startDate
      endDate: $endDate
      canceled: $canceled
      isDelete: $isDelete
      timeZone: "${TIMEZONE}"
    ) {
      ...calendarPlannerEventData
    }
    providerWithOpenAvailability(providersIds: $providersIDs, startDate: $startDate) 
    {
      providertemplatewithavailability {
        id
        name
        employment
        isDefaultTemplateInherited
        speciality {
          id
          department
          abbreviation
        }
        clinic {
          id
          timezone
        }
        dailyMaxHours {
          id
          dayOfWeek
          value
        }
       availability {
        ...ProviderDayAvailability
       }
      }
    }
    clinicWithItsHub {
      id
      name
      abbreviation
      timezone
      clinicType
      clinicHub {
        id
        name
        abbreviation
        clinicType
        timezone
      }
    }
  }
  
  ${eventsFragments.calendarPlannerEventData}
  ${AvailabilityTemplateFragments.ProviderDayAvailabilityPlanner}
`;

export const GET_STAFF_CANCELED_EVENTS = gql`
  query($providersIDs: [Int!], $startDate: DateTime!, $endDate: DateTime!) {
    providersCanceledEvents(
      providersIds: $providersIDs
      startDate: $startDate
      endDate: $endDate
      timeZone: "${TIMEZONE}"
    ) {
      ...CalendarEventData
    }
  }
  ${eventsFragments.calendarEventData}
`;

export const GET_CLIENT_CANCELED_EVENTS = gql`
  query($clientsIDs: [Int!], $startDate: DateTime!, $endDate: DateTime!) {
    clientsCanceledEvents(
      clientsIds: $clientsIDs
      startDate: $startDate
      endDate: $endDate
      timeZone: "${TIMEZONE}"
    ) {
      ...CalendarEventData
    }
  }
  ${eventsFragments.calendarEventData}
`;

export const GET_CLIENT_DELETED_EVENTS = gql`
  query($clientsIDs: [Int!], $startDate: DateTime!, $endDate: DateTime!) {
    clientsDeletedEvents(
      clientsIds: $clientsIDs
      startDate: $startDate
      endDate: $endDate
      timeZone: "${TIMEZONE}"
    ) {
      ...CalendarEventData
    }
  }
  ${eventsFragments.calendarEventData}
`;

export const GET_STAFF_DELETED_EVENTS = gql`
  query($providersIDs: [Int!], $startDate: DateTime!, $endDate: DateTime!) {
    providersDeletedEvents(
      providersIds: $providersIDs
      startDate: $startDate
      endDate: $endDate
      timeZone: "${TIMEZONE}"
    ) {
      ...CalendarEventData
    }
  }
  ${eventsFragments.calendarEventData}
`;

export const GET_FULL_EVENT = gql`
  query($id: Int!, $canceled: Boolean) {
    event(id: $id, canceled: $canceled) {
      ...FullEventData
    }
    isLastEventInSeries(id: $id)
    isConverted(id: $id)
  }
  ${eventsFragments.fullEventData}
`;

export const GET_FULL_OBSERVER_EVENT = gql`
  query($id: Int!, $canceled: Boolean) {
    observerEvent(id: $id, canceled: $canceled) {
      ...FullObserverEventData
    }
  }
  ${eventsFragments.fullObserverEventData}
`;

export const GET_BULK_RECURRING_EVENTS_COUNT = gql`
  query($ids: [Int!]!) {
    getBulkRecurringEventsCount(ids: $ids)
  }
`;

export const GET_OBSERVATION_OF_LEAD_EVENT = gql`
  query($id: Int!) {
    observationOfSeriesLeadEvent(id: $id) {
      observations {
        id
        startDate
        endDate
        duration
        locationType
        telehealthLink
        provider {
          ...Provider
        }
      }
      startDate
      endDate
      locationType
    }
  }
  ${providerFragments.provider}
`;

export const GET_EVENT_WITH_OBSERVERS = gql`
  query($id: Int!) {
    event(id: $id) {
      observations {
        id
        startDate
        provider {
          ...Provider
        }
      }
    }
  }
  ${providerFragments.provider}
`;

export const GET_SINGLE_EVENT_OBSERVER_COUNT = gql`
  query($ids: [Int!]!) {
    SingleEventObservations(Ids: $ids)
  }
`;

export const GET_RECCURING_OBSERVER_COUNT = gql`
  query($ids: [Int!]!) {
    getObservationCountOfSeriesFromLeadEventId(ids: $ids)
  }
`;

export const GET_SEARCH_ADDRESS = gql`
  query($querySearch: String!) {
    searchAddress(querySearch: $querySearch) {
      addressLine1
      city
      state
      freeformAddress
      postalCode
      lat
      lon
    }
  }
`;

export const GET_DRIVE_TIME_MILEAGE = gql`
  query($querySearch: String!) {
    getDriveTimeMileage(querySearch: $querySearch)
  }
`;

export const FETCH_ESTIMATED_DRIVE_TIME = gql`
  query getEstimatedDriveTime(
    $providerId: Int!
    $startDate: DateTime!
    $endDate: DateTime!
    $address: String!
    $clinicAddress: String!
    $isOffSite: Boolean!
  ) {
    getEstimatedDriveTime(
      providerId: $providerId
      startDate: $startDate
      endDate: $endDate
      address: $address
      clinicAddress: $clinicAddress
      isOffSite: $isOffSite
    ) {
      minTravelTimeInMins
      maxTravelTimeInMins
    }
  }
`;

export const GET_UNAVAILABLE_PTO_EVENTS = gql`
  query($providerIds: [Int!]!, $endDate: DateTime!) {
    getUnavailableAndPTOEvents(providerIds: $providerIds, endDate: $endDate) {
      id
      startDate
      endDate
      provider {
        id
      }
    }
  }
`;
