import { RescheduleModalType } from 'model/event';
import moment from 'moment';
import { mapBaseAdminEvent, mapBaseEvent } from 'utils/mappers/request/events';

export const compareEvents = events => {
  let intersection = [];
  let first = events.filter(event => {
    return event.type === 'inClinic';
  });
  let second = events.filter(event => {
    return event.type === 'offSite';
  });

  const searchTime = (arr1, arr2) => {
    arr1.map(item => {
      const result2 = arr2.map(({ start, end }) => {
        const isSame = moment(item.start).isSame(start);
        const isBetweenStart = moment(item.start).isBetween(start, end);
        const isBetweenEnd = moment(item.end).isBetween(start, end);
        const isBetweenStartReverse = moment(start).isBetween(
          item.start,
          item.end
        );
        const isBetweenEndReverse = moment(start).isBetween(
          item.start,
          item.end
        );
        const isIn =
          isSame ||
          isBetweenStart ||
          isBetweenEnd ||
          isBetweenStartReverse ||
          isBetweenEndReverse;
        return isIn && { start, end };
      });

      return result2.map(({ start, end }) => {
        return (
          start &&
          intersection.push({
            id: item.id + 'interseption',
            start: new Date(Math.max(start, item.start)),
            end: new Date(Math.min(end, item.end)),
            isError: true,
            type: 'interseption'
          })
        );
      });
    });
  };
  searchTime(first, second);
  const nextEvents = events.concat(intersection);
  return nextEvents;
};

export const getDefaultPopupFormValues = (
  clinicId,
  roomId,
  address,
  locationCategory,
  telehealthLink
) => ({
  clinic: clinicId ? { id: clinicId } : undefined,
  room: roomId ? { id: roomId } : undefined,
  reason: undefined,
  notes: '',
  address,
  locationCategory,
  telehealthLink,
  type: RescheduleModalType.singleMappedValue
});

export const mapEventDataToBeRescheduled = (
  fullEvent,
  data,
  newStartDate,
  newEndDate,
  provider,
  oldProvider,
  clinicsMap
) => {
  const newEvent = mapBaseEvent(fullEvent, provider, oldProvider);
  const newStartDateMoment = moment(newStartDate);
  const newEndDateMoment = moment(newEndDate);
  const clinicId = data.clinic ? data.clinic.id : newEvent.clinic.id;
  const clinicMapped = clinicsMap.get(clinicId);
  return {
    ...newEvent,
    room: { id: data.room?.id },
    clinic: {
      id: clinicId,
      timezone: clinicMapped.timezone
    },
    telehealthLink: data.telehealthLink,
    startDate: moment(newStartDate).toDate(),
    endDate: moment(newEndDate).toDate(),
    duration: moment
      .duration(newEndDateMoment.diff(newStartDateMoment))
      .asMinutes(),
    address: data.address,
    locationCategory: data.locationCategory
  };
};

export const mapAdminEventToBeRescheduled = (
  event,
  provider,
  newStartDate,
  newEndDate,
  data,
  startingAddrList,
  destAddrList,
  driveTimeAddress
) => {
  const newEvent = mapBaseAdminEvent(event, provider);
  const newStartDateMoment = moment(newStartDate);
  const newEndDateMoment = moment(newEndDate);
  return {
    ...newEvent,
    driveTimeSourceAddr: driveTimeAddress
      ? startingAddrList === undefined
        ? null
        : startingAddrList?.[0]
      : data.driveTimeSourceAddr,
    driveTimeDesteAddr: driveTimeAddress
      ? destAddrList === undefined
        ? null
        : destAddrList?.[0]
      : data.driveTimeDesteAddr,
    driveTimeMileage: parseFloat(data.driveTimeMileage),
    isFirstOrLastDriveToNonClinic: data.isFirstOrLastDriveToNonClinic,
    reimbursableMileage: data.reimbursableMileage,
    startDate: moment(newStartDate).toDate(),
    endDate: moment(newEndDate).toDate(),
    duration: moment
      .duration(newEndDateMoment.diff(newStartDateMoment))
      .asMinutes()
  };
};
