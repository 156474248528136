import React, { Dispatch, SetStateAction } from 'react';
import { FormItem } from 'views/containers/form';
import {
  PlainTimepicker,
  TimerTextField,
  Datepicker
} from 'api/sharedComponents/reactHookFormComponents';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  calculateEndDate,
  durationToMinutesDuration,
  minutesDurationToDuration
} from 'utils/format/time';
import moment, { Moment } from 'moment';
import { DateWrapper } from '../style';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { IEvent, SidebarState } from 'model/v2';
import { SIDEBAR_ACTIONS } from 'pages/MainCalendarPage/AppointmentSidebar';
import { APPOINTMENT_CATEGORY } from 'utils/constants/lists';
import { ADMIN_APPT_ID_FOR_DURATION } from 'utils/constants/appointmentsTypes';
interface IProps {
  setAbaObserversList?: Dispatch<SetStateAction<IEvent[]>>;
  setSeriesEventObservations?: Dispatch<SetStateAction<IEvent[]>>;
  leadEvent?: IEvent;
  abaObserversList?: IEvent[];
  setEvent?: Dispatch<SetStateAction<any>>;
  sidebarState?: SidebarState;
  timeValidate?: boolean;
  setTimeValidate?: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
}

const DateForm: React.FC<IProps> = ({
  setAbaObserversList,
  setSeriesEventObservations,
  abaObserversList,
  setEvent,
  leadEvent,
  sidebarState,
  setTimeValidate,
  disabled
}) => {
  const methods = useFormContext();
  const {
    duration,
    startDate,
    endDate,
    smart,
    eventTypeName,
    appType
  } = useWatch({
    control: methods.control,
    name: [
      'duration',
      'startDate',
      'endDate',
      'smart',
      'eventTypeName',
      'appType'
    ]
  });

  const { apptCategory } = methods.watch(['apptCategory']);
  const eventTypes = ['ABA', 'DT'];
  const isAbaReschedule = eventTypes.includes(
    leadEvent?.appointmentType?.eventType?.name!
  );
  let changeDateDiff = 0;
  const DayHandler = React.useCallback(
    (val: Moment) => {
      if (setEvent && isAbaReschedule) {
        const newDate = moment(moment(val).format('YYYY-MM-DD'));
        const oldDate = moment(
          moment(leadEvent?.startDate).format('YYYY-MM-DD')
        );
        leadEvent!.startDate = moment(
          moment(val).format('YYYY-MM-DD') +
            ' ' +
            moment(leadEvent?.startDate).format('HH:mm:ss')
        );
        changeDateDiff = newDate.diff(oldDate, 'days');
        setEvent(leadEvent);
        if (setAbaObserversList) {
          setAbaObserversList(current =>
            current.map((obj: any) => {
              const momentStartDate = moment(obj.startDate).add(
                changeDateDiff,
                'days'
              );
              const momentEndDate = moment(obj.endDate).add(
                changeDateDiff,
                'days'
              );
              return {
                ...obj,
                endDate: momentEndDate,
                startDate: momentStartDate
              };
            })
          );
        }
        if (setSeriesEventObservations) {
          setSeriesEventObservations(current =>
            current.map((obj: any) => {
              const momentStartDate = moment(obj.startDate).add(
                changeDateDiff,
                'days'
              );
              const momentEndDate = moment(obj.endDate).add(
                changeDateDiff,
                'days'
              );
              obj.observations = obj.observations?.map((observer: any) => {
                const observerStartDate = moment(observer.startDate).add(
                  changeDateDiff,
                  'days'
                );
                const observerEndDate = moment(observer.endDate).add(
                  changeDateDiff,
                  'days'
                );
                return {
                  ...observer,
                  endDate: observerEndDate,
                  startDate: observerStartDate
                };
              });
              return {
                ...obj,
                endDate: momentEndDate,
                startDate: momentStartDate
              };
            })
          );
        }
      }
      methods.setValue('selectedDay', val);
      methods.setValue('dirtyEdit', true);
    },
    [methods]
  );

  const updateObserverData = (
    setAbaObserversList: (arg0: (current: any) => any) => void
  ) => {
    if (isAbaReschedule) {
      const leadEventStartDate = moment(leadEvent?.startDate);

      const newEventStartDate = moment(methods.getValues('startDate'));

      const forwardOffset = moment
        .duration(newEventStartDate.diff(leadEventStartDate))
        .asMinutes();

      if (leadEvent && setEvent) {
        const modifiedEvent = leadEvent;

        modifiedEvent.startDate = newEventStartDate;

        const momentEndDate = moment(newEventStartDate).add(
          duration,
          'minutes'
        );

        modifiedEvent.endDate = momentEndDate;
        setEvent(modifiedEvent);
      }

      setAbaObserversList(current =>
        current.map((obj: any) => {
          const momentStartDate = moment(obj.startDate).add(
            forwardOffset,
            'minutes'
          );
          const momentEndDate = moment(momentStartDate).add(
            obj.duration,
            'minutes'
          );
          return {
            ...obj,
            endDate: momentEndDate,
            startDate: momentStartDate
          };
        })
      );
    }
  };

  const updateSeriesObserverData = (
    setSeriesEventObservations: (arg0: (current: any) => any) => void
  ) => {
    if (isAbaReschedule) {
      setSeriesEventObservations(current =>
        current.map((obj: any) => {
          const leadEventStartDate = moment(obj?.startDate);

          const newEventStartDate = moment(
            moment(obj?.startDate).format('YYYY-MM-DD') +
              ' ' +
              moment(methods.getValues('startDate')).format('HH:mm:ss')
          );

          const forwardOffset = moment
            .duration(newEventStartDate.diff(leadEventStartDate))
            .asMinutes();

          const momentStartDate = moment(obj.startDate).add(
            forwardOffset,
            'minutes'
          );
          const momentEndDate = moment(momentStartDate).add(
            obj.duration,
            'minutes'
          );
          obj.observations = obj.observations?.map((observer: any) => {
            const observerStartDate = moment(observer.startDate).add(
              forwardOffset,
              'minutes'
            );
            const observerEndDate = moment(observerStartDate).add(
              observer.duration,
              'minutes'
            );
            return {
              ...observer,
              endDate: observerEndDate,
              startDate: observerStartDate
            };
          });
          return {
            ...obj,
            endDate: momentEndDate,
            startDate: momentStartDate
          };
        })
      );
    }
  };

  const startDateHandler = React.useCallback(
    event => {
      const time = event.target.value;
      const momentValue = moment(time, 'HH:mm');
      methods.setValue('dirtyEdit', true);
      if (sidebarState?.action !== SIDEBAR_ACTIONS.EDIT)
        methods.setValue('startDate', momentValue);
      if (
        sidebarState?.action === SIDEBAR_ACTIONS.EDIT &&
        apptCategory?.split(': ')[1] === APPOINTMENT_CATEGORY[0].value
      ) {
        const newStartDateTime = startDate
          .clone()
          .set('hours', momentValue.hours())
          .set('minutes', momentValue.minutes());
        methods.setValue('startDate', newStartDateTime);
        const startTime = moment(
          moment(newStartDateTime).format('h:mmA'),
          'h:mmA'
        );
        const endTime = moment(moment(endDate).format('h:mmA'), 'h:mmA');
        const duration = endTime.diff(startTime, 'minutes');
        methods.setValue('duration', duration);
        methods.trigger(['duration']);
        if (setTimeValidate) {
          if (endTime.isBefore(startTime) || endTime.isSame(startTime)) {
            setTimeValidate(true);
          } else if (duration < 5) {
            setTimeValidate(true);
          } else {
            setTimeValidate(false);
          }
        }
        if (leadEvent && setEvent) {
          const modifiedEvent = leadEvent;
          modifiedEvent.startDate = momentValue;
          modifiedEvent.endDate = endDate;
          modifiedEvent.duration = duration;
          setEvent(modifiedEvent);
        }
      } else if (
        apptCategory?.split(': ')[1] === APPOINTMENT_CATEGORY[3].value &&
        sidebarState?.action === SIDEBAR_ACTIONS.EDIT &&
        !ADMIN_APPT_ID_FOR_DURATION[process.env.REACT_APP_STAGE!].includes(
          appType
        )
      ) {
        methods.setValue('startDate', momentValue);
        let endTime = methods.getValues('endDate');
        let duration_Altered = moment
          .duration(endTime?.diff(momentValue))
          .asMinutes();
        methods.setValue('duration', duration_Altered);
        methods.trigger(['startDate', 'endDate']);
      } else {
        methods.setValue('startDate', momentValue);
        methods.setValue(
          'endDate',
          momentValue.clone().add(duration, 'minutes')
        );
      }

      methods.trigger(['startDate', 'endDate']);

      if (abaObserversList && setAbaObserversList) {
        updateObserverData(setAbaObserversList);
      }
      if (setSeriesEventObservations) {
        updateSeriesObserverData(setSeriesEventObservations);
      }
    },
    [methods, duration, appType]
  );

  const endDateHandler = React.useCallback(
    event => {
      const time = moment(event.target.value, 'HH:mm');
      const endDates = startDate
        ?.clone()
        .set('hours', time.hours())
        .set('minutes', time.minutes());
      let duration = moment.duration(endDates?.diff(startDate)).asMinutes();
      if (
        sidebarState?.action === SIDEBAR_ACTIONS.EDIT &&
        apptCategory?.split(': ')[1] === APPOINTMENT_CATEGORY[0].value
      ) {
        const startTime = moment(moment(startDate).format('h:mmA'), 'h:mmA');
        const endTime = moment(moment(endDates).format('h:mmA'), 'h:mmA');
        duration = endTime.diff(startTime, 'minutes');
        // methods.trigger(['startDate']);
        if (setTimeValidate) {
          if (endTime.isBefore(startTime) || endTime.isSame(startTime)) {
            setTimeValidate(true);
          } else if (duration < 5) {
            setTimeValidate(true);
          } else {
            setTimeValidate(false);
          }
        }
      }
      methods.setValue('dirtyEdit', true);
      methods.setValue('endDate', endDates);
      methods.setValue('duration', duration);
      methods.trigger(['endDate', 'startDate', 'duration']);
      const newEventEndDate = moment(methods.getValues('endDate'));

      if (leadEvent && setEvent) {
        const modifiedEvent = leadEvent;
        modifiedEvent.endDate = newEventEndDate;
        modifiedEvent.duration = duration;

        setEvent(modifiedEvent);
      }
    },
    [methods, startDate]
  );

  const durationHandler = React.useCallback(
    event => {
      methods.setValue('dirtyEdit', true);
      methods.setValue('selectedOpening', null);
      const minutes = durationToMinutesDuration(event);
      methods.setValue('duration', minutes);
      methods.trigger('duration');
      if (!minutes || !startDate) return;
      methods.setValue('endDate', calculateEndDate(startDate, minutes));
      methods.trigger('endDate');

      if (leadEvent && setEvent) {
        const modifiedEvent = leadEvent;
        const durationNew = methods.getValues('duration');

        modifiedEvent.duration = durationNew;
        const newEndDate = moment(methods.getValues('endDate'));

        modifiedEvent.endDate = newEndDate;

        setEvent(modifiedEvent);
      }
    },
    [methods, startDate]
  );

  return (
    <DateWrapper>
      <h2 className="section-title">Date &amp; Time</h2>
      <div className="date-first-row">
        <FormItem optional={false} label="Date">
          <Controller
            name="selectedDay"
            control={methods.control}
            render={fieldProps => {
              return (
                <Datepicker
                  disabled={disabled}
                  className="date-style"
                  name="selectedDay"
                  placeholder="MM/DD/YYYY"
                  format="MM/DD/YYYY"
                  field={fieldProps}
                  onChange={DayHandler}
                  errors={methods.errors}
                  allowClear={false}
                />
              );
            }}
          />
        </FormItem>
        <FormItem optional={false} label="Start Time">
          <Controller
            name="startDate"
            control={methods.control}
            render={fieldProps => (
              <TimerTextField
                className="time-style"
                field={fieldProps}
                errors={methods.errors}
                value={startDate?.format('HH:mm')}
                onChange={startDateHandler}
                disabled={disabled}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 900
                }}
              />
            )}
          />
        </FormItem>
        <FormItem optional={false} label="End Time">
          <Controller
            name="endDate"
            control={methods.control}
            render={fieldProps => (
              <TimerTextField
                className="time-style"
                field={fieldProps}
                errors={methods.errors}
                value={endDate?.format('HH:mm')}
                disabled={
                  eventTypeName === EVENT_TYPES.DT ||
                  eventTypeName === EVENT_TYPES.MED ||
                  eventTypeName === smart ||
                  disabled
                }
                onChange={endDateHandler}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 900
                }}
              />
            )}
          />
        </FormItem>
      </div>
      <div className="date-second-row">
        <FormItem optional={false} label="Duration">
          <Controller
            name="duration"
            control={methods.control}
            render={fieldProps => (
              <PlainTimepicker
                className="date-style"
                name="duration"
                format="HH:mm"
                inputReadOnly={
                  !getFeatureAvailability(FEATURES.FIVE_MINUTE_SCHEDULING)
                }
                pattern="[0-9]{2}:[0-9]{2}"
                minuteStep={15}
                disabled={
                  eventTypeName === EVENT_TYPES.DT ||
                  eventTypeName === EVENT_TYPES.MED ||
                  disabled
                }
                value={minutesDurationToDuration(duration)}
                onChange={durationHandler}
                errors={methods.errors}
                field={fieldProps}
              />
            )}
          />
        </FormItem>
      </div>
    </DateWrapper>
  );
};
export default React.memo(DateForm);
