import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Skeleton } from '@material-ui/lab';
import RecurringResultItemCard from './RecurringResultItemCard';
import {
  IAddress,
  ISmartResultCard,
  ISmartScheduleConsolidatedResults
} from 'model/v2';
import { DTA_SPECIALITIES } from 'utils/constants/appointmentsTypes';
import ExpandMoreFilled from 'assets/img/ExpandMoreFilled.png';
import ExpandLessFilled from 'assets/img/ExpandLessFilled.png';
import { ICalendarResource } from 'model/calendar/filters';

const RecurringResultProviderGrouping = ({
  providerName,
  providerResults,
  loading,
  expanded,
  handleExpandToggle,
  uniqueClientsCount,
  appointmentTypeTitle,
  clientTimezone,
  clientAddresses,
  index,
  updateSelectedCards,
  setOpeningCards,
  providersMap
}: {
  providerName: string;
  providerResults: ISmartScheduleConsolidatedResults;
  loading: boolean;
  expanded: boolean;
  handleExpandToggle: (providerName: string) => void;
  uniqueClientsCount: number;
  appointmentTypeTitle: string;
  clientTimezone: string;
  clientAddresses: IAddress[];
  index: number;
  updateSelectedCards: (idx: number, isChecked: boolean) => void;
  setOpeningCards: React.Dispatch<React.SetStateAction<ISmartResultCard[]>>;
  providersMap: Map<number, ICalendarResource>;
}) => {
  return (
    <>
      <div className="provider-div">
        <Grid>
          <Grid.Row className="provider-row">
            <Grid.Column width="8">
              <label className="provider-name">
                {providerName}
                {', '}
                {
                  providerResults.openingCards[0]!.provider.speciality
                    ?.abbreviation
                }{' '}
                (
                {providerResults.openingCards[0]!.provider.clinic?.abbreviation}
                ){' '}
              </label>
            </Grid.Column>
            <Grid.Column width="7">
              <div>
                <label className="available-days">
                  Available: {providerResults.availableDays} Days;{' '}
                  {providerResults.totalAvailableDuration}
                </label>
              </div>
              {DTA_SPECIALITIES[process.env.REACT_APP_STAGE!].includes(
                providerResults.openingCards[0].provider.speciality!.id!
              ) && (
                <>
                  {!loading ? (
                    <>
                      <div>
                        <label className="available-days">
                          Clients Count: {uniqueClientsCount}
                        </label>
                      </div>
                    </>
                  ) : (
                    <>
                      <Skeleton
                        variant="text"
                        children={
                          <div>
                            <label className="available-days">
                              Clients Count:0
                            </label>
                          </div>
                        }
                      />
                    </>
                  )}
                </>
              )}
            </Grid.Column>
            <Grid.Column width="1">
              <img
                src={expanded ? ExpandLessFilled : ExpandMoreFilled}
                alt="Expand Icon"
                onClick={() => {
                  handleExpandToggle(providerName);
                }}
                style={{ cursor: 'pointer' }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div
        style={{
          display: expanded ? 'block' : 'none'
        }}
      >
        {providerResults.openingCards.map((result: ISmartResultCard) => (
          <RecurringResultItemCard
            clientTimezone={clientTimezone}
            appointmentTypeTitle={appointmentTypeTitle}
            result={result}
            index={index++}
            updateSelectedCards={updateSelectedCards}
            setOpeningCards={setOpeningCards}
            clientAddresses={clientAddresses}
            key={`${result.provider.id}-${result.clinic?.id}-${result.dayOfWeek}-${result.allowedLocations}`}
            providersMap={providersMap}
          />
        ))}
      </div>
    </>
  );
};
export default React.memo(RecurringResultProviderGrouping);
