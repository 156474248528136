import ModalSemantic from 'components/modalSemantic';
import React, { useEffect } from 'react';
import { SubstituteModalWrapper } from './style';
import { Button, Grid } from 'semantic-ui-react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  GROUPED_CANCEL_OPTIONS,
  LOCATION_TYPE_LIST
} from 'utils/constants/lists';
import { yupResolver } from '@hookform/resolvers/yup';
import { APPOINTMENT_CANCEL_SCHEMA } from 'utils/validators/calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faClock,
  faLongArrowAltRight,
  faMapMarkerAlt,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { ICancellationReason, IOption } from 'model';
import { mapToOption } from 'utils/mappers/form';
import { formatProviderDisplayName } from 'utils/format';
import moment from 'moment';
import {
  IEvent,
  IFullAppointmentType,
  IProvider,
  SingleSmartResultInterface
} from 'model/v2';
import CancelReasonDropdown from './CancelModal/CancelReasonDropdown';
import CancelNoteTextArea from './CancelModal/CancelNoteTextArea';
import { CANCEL_EVENT, UPDATE_EVENT } from 'api/graphql/v2/mutations/Event';
import { useMutation } from 'react-apollo';
import {
  ABA_EXTENSION_SUBSTITUTE_APPT_IDS
  // DT_SUBSTITUTE_SUB_APPT_IDS,
  // MAKEUP_SESSION_APPT_IDS,
  // MAKEUP_SESSION_SUB_APPT_IDS,
  // SUBSTITUTE_APPT_IDS
} from 'utils/constants/appointmentsTypes';
import {
  selectedItemToSubstituteVar,
  updateCalendarPref
} from 'utils/cache/calendar';
import { getSubstituteApptId } from 'utils/mappers/request/V2/event';

interface Props {
  onClose: () => void;
  event: any;
  cancellationReasons: ICancellationReason[];
  substituteProvider?: IProvider;
  isPendingConfirmation?: boolean;
  setVisible: (val: boolean) => void;
  appointmentType: IFullAppointmentType;
  appTypesMap: Map<number, IFullAppointmentType>;
  adjacentEvent?: IEvent;
  isDirectSubstitute?: boolean;
  selectedResult?: SingleSmartResultInterface;
  providersMap: Map<any, any>;
  providerTelehealthLink?: string;
}

const SubstituteConfirmModal: React.FC<Props> = ({
  onClose,
  event,
  cancellationReasons,
  substituteProvider,
  isPendingConfirmation,
  setVisible,
  appointmentType,
  appTypesMap,
  adjacentEvent,
  isDirectSubstitute,
  selectedResult,
  providersMap,
  providerTelehealthLink
}: Props) => {
  return (
    <ModalSemantic
      open={true}
      onClose={onClose}
      trigger={<div></div>}
      modalWidth={550}
      content={
        <SubstituteForm
          event={event}
          onClose={onClose}
          cancellationReasons={cancellationReasons}
          substituteProvider={substituteProvider}
          isPendingConfirmation={isPendingConfirmation}
          setVisible={setVisible}
          appointmentType={appointmentType}
          appTypesMap={appTypesMap}
          adjacentEvent={adjacentEvent}
          isDirectSubstitute={isDirectSubstitute}
          selectedResult={selectedResult}
          providersMap={providersMap}
          providerTelehealthLink={providerTelehealthLink}
        />
      }
    />
  );
};
export default React.memo(SubstituteConfirmModal);

const SubstituteForm: React.FC<Props> = ({
  event,
  onClose,
  cancellationReasons,
  substituteProvider,
  isPendingConfirmation,
  setVisible,
  appointmentType,
  appTypesMap,
  adjacentEvent,
  isDirectSubstitute,
  selectedResult,
  providersMap,
  providerTelehealthLink
}: Props) => {
  const [cancelReasons, setCancelReasons] = React.useState<IOption[]>([]);
  const address = `${
    event?.address?.addressLine1 ? event?.address?.addressLine1 + ', ' : ''
  } ${
    event?.address?.addressLine2 ? event?.address?.addressLine2 + ', ' : ''
  } ${event?.address?.city ? event?.address?.city + ', ' : ''} ${
    event?.address?.state ? event?.address?.state + ', ' : ''
  } ${event?.address?.zipCode || ''}`;
  let startDate = event?.startDate;
  let endDate = event?.endDate;
  if (adjacentEvent) {
    if (adjacentEvent?.endDate === startDate)
      startDate = adjacentEvent?.startDate;
    if (adjacentEvent?.startDate === endDate) endDate = adjacentEvent?.endDate;
  } else if (selectedResult) {
    substituteProvider = providersMap.get(selectedResult.provider.id);
    startDate = selectedResult.startTime;
    endDate = selectedResult.endTime;
  }
  useEffect(() => {
    const reasons: ICancellationReason[] = cancellationReasons.filter(
      (x: ICancellationReason) => x.type !== 1
    );
    setCancelReasons(
      mapToOption(
        reasons?.map(reason => {
          return {
            id: reason.reasonId,
            value: reason.name
          };
        })
      )
    );
  }, [cancellationReasons]);
  const methods = useForm({
    defaultValues: {
      recurrent: GROUPED_CANCEL_OPTIONS[0].value,
      cancelNote: ''
    },
    resolver: yupResolver(APPOINTMENT_CANCEL_SCHEMA(!true, true))
  });
  const [saving, setSaving] = React.useState(false);
  const [updateEvent] = useMutation(UPDATE_EVENT);
  const [cancelEvent] = useMutation(CANCEL_EVENT);
  const successCallbackBehavior = React.useCallback(() => {
    setVisible(false);
    updateCalendarPref(
      moment(
        selectedResult?.startTime ? selectedResult?.startTime : event?.startDate
      )
    );
    setSaving(false);
    selectedItemToSubstituteVar(0);
    onClose();
  }, [setSaving, event, substituteProvider]);

  // const getSubstituteApptId = (eventApptId: number, makeupSession: boolean) => {
  //   let envSubstitueApptId =
  //     SUBSTITUTE_APPT_IDS[process.env.REACT_APP_STAGE!][eventApptId];
  //   if (makeupSession) {
  //     envSubstitueApptId =
  //       MAKEUP_SESSION_APPT_IDS[process.env.REACT_APP_STAGE!][eventApptId];
  //   }
  //   const apptId = envSubstitueApptId ? envSubstitueApptId : eventApptId;
  //   const appointmentType = appTypesMap.get(apptId)!;
  //   const appointmentSubTypeId = event?.appointmentType?.parent?.id
  //     ? event?.appointmentType?.id
  //     : undefined;
  //   let substituteApptId = event?.appointmentType?.parent?.id
  //     ? event?.appointmentType?.id
  //     : apptId;
  //   let envSubstitueSubApptId =
  //     DT_SUBSTITUTE_SUB_APPT_IDS[process.env.REACT_APP_STAGE!][
  //       appointmentSubTypeId
  //     ];
  //   if (makeupSession) {
  //     envSubstitueSubApptId =
  //       MAKEUP_SESSION_SUB_APPT_IDS[process.env.REACT_APP_STAGE!][
  //         appointmentSubTypeId
  //       ];
  //   }
  //   appointmentType.appointmentSubTypes?.map(subtype => {
  //     if (subtype.id! === envSubstitueSubApptId) {
  //       substituteApptId = subtype.id;
  //     }
  //     return;
  //   });
  //   return substituteApptId;
  // };

  const errorCallbackBehavior = React.useCallback(() => {
    setVisible(false);
    updateCalendarPref(moment(event?.startDate));
    setSaving(false);
    selectedItemToSubstituteVar(0);
    onClose();
  }, [setSaving]);
  const submitForm = React.useCallback(
    async data => {
      const eventApptId =
        event?.appointmentType?.parent?.id || event?.appointmentType?.id;
      // let substituteApptId = getSubstituteApptId(eventApptId, false);
      let substituteApptId = getSubstituteApptId(
        eventApptId,
        false,
        appTypesMap,
        event
      );
      let reqEvent = JSON.parse(JSON.stringify(event));
      let updatedObserversData: IEvent[] = [];
      if (adjacentEvent) {
        cancelEvent({
          variables: {
            data: {
              event: {
                id: event.id,
                provider: { id: event.provider?.id }
              },
              recurrent: false,
              cancelReasonId: data?.cancelReason,
              cancelNote: data?.cancelNote || '',
              isHardDeleteCr: true
            }
          }
        })
          .then(() => {})
          .catch(() => {});
        if (adjacentEvent.endDate === event.startDate)
          adjacentEvent.endDate = event?.endDate;
        if (adjacentEvent.startDate === event.endDate)
          adjacentEvent.startDate = event?.startDate;
        adjacentEvent.duration = moment
          .duration(
            moment(adjacentEvent.endDate!).diff(
              moment(adjacentEvent.startDate!)
            )
          )
          .asMinutes();
        reqEvent = adjacentEvent;
        reqEvent.client = event?.client;
        substituteApptId = adjacentEvent.appointmentType?.id;
        updatedObserversData = adjacentEvent.observations!;
      } else if (!isDirectSubstitute && selectedResult === undefined) {
        substituteApptId =
          ABA_EXTENSION_SUBSTITUTE_APPT_IDS[process.env.REACT_APP_STAGE!];
      }
      if (selectedResult) {
        reqEvent.startDate = selectedResult.startTime;
        reqEvent.endDate = selectedResult.endTime;
        substituteProvider = selectedResult.provider;
        isPendingConfirmation = selectedResult.isInVisible;
        substituteApptId = getSubstituteApptId(
          eventApptId,
          true,
          appTypesMap,
          event
        );
      }

      console.log('[substituteconfirm] req event', reqEvent);
      const mappedEvent = {
        id: reqEvent?.id,
        client: reqEvent?.client
          ? {
              id: reqEvent?.client?.id
            }
          : null,
        provider: {
          id: substituteProvider?.id
        },
        clinic: {
          id: reqEvent?.clinic?.id || reqEvent?.client?.clinic?.id,
          timezone:
            reqEvent?.clinic?.timezone || reqEvent?.client?.clinic?.timezone
        },
        appointmentType: {
          id: substituteApptId
        },
        room:
          reqEvent?.locationType === LOCATION_TYPE_LIST[0].id && reqEvent?.room
            ? {
                id: event?.room.id
              }
            : null,
        startDate: reqEvent?.startDate,
        endDate: reqEvent?.endDate,
        duration: reqEvent?.duration,
        locationType: reqEvent?.locationType,
        locationCategory: reqEvent?.locationCategory,
        clinicPreference: reqEvent?.clinicPreference,
        paymentMethod: reqEvent?.paymentMethod,
        address:
          reqEvent?.locationType === LOCATION_TYPE_LIST[1].id
            ? reqEvent?.address
            : null,
        recurrencePattern: event?.recurrencePattern,
        note: reqEvent?.note?.note
          ? {
              id: reqEvent?.note?.id,
              note: reqEvent?.note?.note || ''
            }
          : null,
        telehealthLink:
          reqEvent?.locationType === LOCATION_TYPE_LIST[2].id
            ? selectedResult?.telehealthLink ||
              providerTelehealthLink ||
              undefined
            : undefined,
        isPendingConfirmation: isPendingConfirmation,
        abaObserverList: reqEvent?.abaObserversList
      };
      setSaving(true);
      let request = updateEvent({
        variables: {
          data: {
            recurrent: false,
            event: mappedEvent,
            cancelNote: data?.cancelNote,
            cancelReasonId: data?.cancelReason,
            updatedObserversData: updatedObserversData
          }
        }
      });
      if (request) {
        await request
          .then(() => {
            successCallbackBehavior();
          })
          .catch(() => {
            errorCallbackBehavior();
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const submitError = React.useCallback(() => {
    methods.trigger();
  }, [methods]);
  let newLocationType = event?.locationType;
  let newClinic = event?.clinic;
  let newTeleHealthLink =
    selectedResult?.telehealthLink || providerTelehealthLink || 'N/A';
  let newlocationCategory = event?.locationCategory;
  let newAddress = address;
  if (adjacentEvent) {
    newLocationType = adjacentEvent?.locationType;
    newClinic = adjacentEvent?.clinic;
    newTeleHealthLink = adjacentEvent?.telehealthLink!;
    newlocationCategory = adjacentEvent?.locationCategory;
    newAddress = `${
      adjacentEvent?.address?.addressLine1
        ? adjacentEvent?.address?.addressLine1 + ', '
        : ''
    } ${
      adjacentEvent?.address?.addressLine2
        ? adjacentEvent?.address?.addressLine2 + ', '
        : ''
    } ${
      adjacentEvent?.address?.city ? adjacentEvent?.address?.city + ', ' : ''
    } ${
      adjacentEvent?.address?.state ? adjacentEvent?.address?.state + ', ' : ''
    } ${adjacentEvent?.address?.zipCode || ''}`;
  }
  return (
    <SubstituteModalWrapper>
      <h2 className="substitute-modal-title">Confirm Appointment</h2>
      {event.observationsCount > 0 ? (
        <>
          <p className="substitute-modal-text">
            Please review new appointment details. Once you click Confirm, the
            original appointment will be cancelled and the new appointment
            scheduled. Any observations associated with this appointment will
            also be cancelled. Remember to rebook observations appointments and
            inform{' '}
            {event?.appointmentType?.eventType?.name === 'ABA' ? (
              <>
                <span> BIs </span>
              </>
            ) : (
              <>
                <span> the provider </span>
              </>
            )}{' '}
            about the schedule change.
          </p>
        </>
      ) : (
        <>
          <p className="substitute-modal-text">
            Please review new appointment details. Once you click Confirm, the
            original appointment will be cancelled and the new appointment
            scheduled.
          </p>
        </>
      )}
      <FormProvider {...methods}>
        <Grid style={{ margin: '0' }}>
          <Grid.Row className="outer-shell">
            <Grid.Column className="w-42-inline pad-8-px">
              <p className="substitute-modal-p modal-underline f-14 p-title">
                Previous{' '}
                {event.appointmentType?.parent?.id
                  ? event.appointmentType?.parent?.title
                  : event.appointmentType?.title}{' '}
                appointment{' '}
              </p>
              <p className="substitute-modal-p f-12">
                <span className="pad-8-px">
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <span>{formatProviderDisplayName(event?.provider)}</span>
              </p>
              <p className="substitute-modal-p f-12">
                <span className="pad-8-px">
                  <FontAwesomeIcon icon={faCalendar} />
                </span>
                <span>
                  {moment(event?.startDate).format('dddd')}{' '}
                  {moment(event?.startDate).format('LL')}
                </span>
              </p>
              <p className="substitute-modal-p f-12">
                <span className="pad-8-px">
                  <FontAwesomeIcon icon={faClock} />
                </span>
                <span>
                  {moment(event?.startDate).format('hh:mm a')} -{' '}
                  {moment(event?.endDate).format('hh:mm a')}
                </span>
              </p>
              <p className="substitute-modal-p f-12">
                <span className="pad-8-px">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </span>
                {event?.locationType === 'In Clinic' && (
                  <>
                    <span>{event?.clinic?.name}</span>
                  </>
                )}
                {event?.locationType === 'Telehealth' && (
                  <>
                    <span>Telehealth</span>
                    <br></br>
                    {event?.telehealthLink ? (
                      <>
                        <a href={event?.telehealthLink} target="_blank">
                          <span
                            style={{ paddingLeft: '16px', color: '#3D89B6' }}
                          >
                            {event?.telehealthLink}
                          </span>
                        </a>
                      </>
                    ) : (
                      <>
                        <span style={{ paddingLeft: '16px', color: '#3D89B6' }}>
                          N/A
                        </span>
                      </>
                    )}
                  </>
                )}
                {event?.locationType === 'Off-Site' && (
                  <>
                    <span>{event?.locationCategory}</span>
                    <br></br>
                    <p style={{ paddingLeft: '16px' }}>{address}</p>
                  </>
                )}
              </p>
            </Grid.Column>
            <Grid.Column className="w-16-inline pad-8-px">
              <p className="arrow-cls">
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </p>
            </Grid.Column>
            <Grid.Column className="w-42-inline pad-8-px">
              <p className="substitute-modal-p modal-underline f-14 p-title">
                New{' '}
                {appointmentType?.title
                  ? appointmentType?.title
                  : event?.appointmentType.title}{' '}
                appointment{' '}
              </p>
              <p className="substitute-modal-p f-12">
                <span className="pad-8-px">
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <span>{formatProviderDisplayName(substituteProvider)}</span>
              </p>
              <p className="substitute-modal-p f-12">
                <span className="pad-8-px">
                  <FontAwesomeIcon icon={faCalendar} />
                </span>
                <span>
                  {moment(startDate).format('dddd')}{' '}
                  {moment(startDate).format('LL')}
                </span>
              </p>
              <p className="substitute-modal-p f-12">
                <span className="pad-8-px">
                  <FontAwesomeIcon icon={faClock} />
                </span>
                <span>
                  {moment(startDate).format('hh:mm a')} -{' '}
                  {moment(endDate).format('hh:mm a')}
                </span>
              </p>
              <p className="substitute-modal-p f-12">
                <span className="pad-8-px">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </span>
                {newLocationType === 'In Clinic' && (
                  <>
                    <span>{newClinic?.name}</span>
                  </>
                )}
                {newLocationType === 'Telehealth' && (
                  <>
                    <span>Telehealth</span>
                    <br></br>
                    {newTeleHealthLink != 'N/A' ? (
                      <>
                        <a href={newTeleHealthLink} target="_blank">
                          <span
                            style={{ paddingLeft: '16px', color: '#3D89B6' }}
                          >
                            {newTeleHealthLink}
                          </span>
                        </a>
                      </>
                    ) : (
                      <>
                        <span style={{ paddingLeft: '16px', color: '#3D89B6' }}>
                          {newTeleHealthLink}
                        </span>
                      </>
                    )}
                  </>
                )}
                {newLocationType === 'Off-Site' && (
                  <>
                    <span>{newlocationCategory}</span>
                    <br></br>
                    <p style={{ paddingLeft: '16px' }}>{newAddress}</p>
                  </>
                )}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <FormProvider {...methods}>
              <CancelReasonDropdown
                cancelReasons={cancelReasons}
                isDeleteOrAdminEvent={false}
              />
              <CancelNoteTextArea isDeleteOrAdminEvent={false} />
            </FormProvider>

            <p className="substitute-modal-p f-12 red pad-15-px">
              Remember to contact caregivers with updated appointment
              information. You can take a screenshot of this window before
              clicking Confirm.
            </p>
            <div className="modal-actions">
              <Button onClick={onClose} basic disabled={saving}>
                GO Back
              </Button>
              <Button
                style={{ backgroundColor: '#6F42F5', color: '#fff' }}
                onClick={methods.handleSubmit(submitForm, submitError)}
                type="submit"
                loading={saving}
                disabled={saving}
              >
                Confirm
              </Button>
            </div>
          </Grid.Row>
        </Grid>
      </FormProvider>
    </SubstituteModalWrapper>
  );
};
