import gql from 'graphql-tag';
import { appointmentTypesFragments } from '../appointment-types';

export const clinicFragments = {
  clinicData: gql`
    fragment ClinicData on Clinic {
      id
      clinicName
      clinicAbbreviation
      departmentId
      street
      city
      state
      zip
      phone
      fax
      timezone
      clinicType
      clinicHub
      rooms
      staff
      patients
      centralReachId
      centralReachLocationId
      centralReachPk
      dummyAdminId
      emailGroupIdentifier
    }
  `,
  clinicWithoutRoomsData: gql`
    fragment ClinicWithoutRoomsData on ClinicWithoutRooms {
      id
      clinicName
      clinicAbbreviation
      departmentId
      timezone
      staff
      patients
    }
  `,
  clinicWithoutRoomsWithoutClientsData: gql`
    fragment ClinicWithoutRoomsWithoutClientsData on ClinicWithoutRoomsWithoutClients {
      id
      clinicName
      clinicAbbreviation
      departmentId
      clinicType
      clinicHub
      timezone
      staff
    }
  `,
  buisnessHoursData: gql`
    fragment BusinessHoursData on BusinessHours {
      dayOfWeek
      opens
      closes
    }
  `,
  servicesData: gql`
    fragment ServiceData on Service {
      service
      defaultLength
    }
  `,
  roomsData: gql`
    fragment RoomsData on Room {
      id
      email
      roomName
      seats
      hasTV
      hasInstruments
      hasPhone
      roomType
      clinicID
    }
  `,
  clinicStaffData: gql`
    fragment ClinicStaffData on User {
      id
      name
      displayName
      email
      clinicId
      firstName
      lastName
      speciality
      appointmentTypes {
        id
        isClinical
      }
    }
  `,
  editClinic: gql`
    fragment EditClinic on Clinic {
      id
      clinicName
      clinicAbbreviation
      departmentId
      street
      city
      state
      zip
      phone
      fax
      timezone
      rooms
      clinicType
      clinicHub
      businessHours {
        dayOfWeek
        opens
        closes
      }
      centralReachId
      centralReachLocationId
      centralReachPk
      dummyAdminId
    }
  `
};

export const GET_CLINIC_LIST = gql`
  {
    clinics {
      ...ClinicData
      businessHours {
        ...BusinessHoursData
      }
    }
  }
  ${clinicFragments.clinicData}
  ${clinicFragments.buisnessHoursData}
`;

export const GET_CLINICS = gql`
  {
    clinics {
      ...ClinicData
      roomsList {
        id
        email
        roomName
        seats
        hasTV
        hasInstruments
        hasPhone
        roomType
        clinicID
      }
      staffList {
        ...ClinicStaffData
      }
    }
  }
  ${clinicFragments.clinicData}
  ${clinicFragments.clinicStaffData}
`;

export const GET_CLINIC_FORM_DATA = gql`
  query {
    clinics {
      id
      clinicName
      clinicAbbreviation
      clinicType
      clinicHub
      timezone
    }
    clinicTypes {
      id
      label
    }
  }
`;

export const GET_CLINIC = gql`
  query($id: String!) {
    clinic(id: $id) {
      ...ClinicData
      businessHours {
        ...BusinessHoursData
      }
      services {
        ...ServiceData
      }
      roomsList {
        ...RoomsData
      }
    }
    clinicOptionsList {
      id
      clinicName
      clinicAbbreviation
      timezone
    }
    clinicTypes {
      id
      label
    }
  }
  ${clinicFragments.clinicData}
  ${clinicFragments.buisnessHoursData}
  ${clinicFragments.servicesData}
  ${clinicFragments.roomsData}
`;

export const GET_CLINIC_CLIENT_LIST = gql`
  query(
    $id: String!
    $from: Int
    $size: Int
    $search: [JSONObject]
    $sort: [JSONObject]
    $filter: [JSONObject]
  ) {
    clinicClients(
      id: $id
      from: $from
      size: $size
      sort: $sort
      filter: $filter
      search: $search
    ) {
      data {
        id
        name
        dob
        gender
        phenoType
      }
      count
    }
  }
`;

export const GET_CLINIC_STAFF_LIST = gql`
  query(
    $id: String!
    $from: Int
    $size: Int
    $search: [JSONObject]
    $sort: [JSONObject]
    $filter: [JSONObject]
  ) {
    clinicUsers(
      id: $id
      from: $from
      size: $size
      sort: $sort
      filter: $filter
      search: $search
    ) {
      data {
        id
        name
        displayName
        speciality
      }
      count
    }
    specialities {
      id
      title
    }
  }
`;

export const GET_NEW_APPOINTMENT_FORM_DATA = gql`
  query {
    appointmentTypes {
      ...AppointmentTypeData
    }
    appointmentABATypes {
      ...AppointmentTypeData
    }
    adminAppointmentTypes {
      id
      value
    }
    eventTypes
  }
  ${appointmentTypesFragments.appointmentTypesData}
`;

export const GET_NEW_CLIENT_DATA = gql`
  {
    clinicsWithoutRooms {
      ...ClinicWithoutRoomsData
      staffList {
        ...ClinicStaffData
      }
    }
    patientRelationships {
      id
      value
    }
    clientTypes {
      id
      value
      label
    }
  }
  ${clinicFragments.clinicWithoutRoomsData}
  ${clinicFragments.clinicStaffData}
`;

export const GET_CLINIC_LIST_WITH_STAFF = gql`
  query {
    clinicsWithoutRoomsWithoutClients {
      ...ClinicWithoutRoomsWithoutClientsData
      staffList {
        ...ClinicStaffData
      }
    }
  }
  ${clinicFragments.clinicWithoutRoomsWithoutClientsData}
  ${clinicFragments.clinicStaffData}
`;

export const GET_CALENDAR_DATA = gql`
  {
    clinicsWithoutRooms {
      ...ClinicWithoutRoomsData
      staffList {
        ...ClinicStaffData
      }
      businessHours {
        ...BusinessHoursData
      }
    }
    appointmentTypes {
      ...AppointmentTypeData
    }
    appointmentABATypes {
      ...AppointmentTypeData
    }
    adminAppointmentTypes {
      id
      value
    }
  }
  ${clinicFragments.clinicWithoutRoomsData}
  ${clinicFragments.buisnessHoursData}
  ${clinicFragments.clinicStaffData}
  ${appointmentTypesFragments.appointmentTypesData}
`;

export const GET_CALENDAR_DATA_SIDE_BAR = gql`
  {
    clinicsWithoutRoomsWithoutClients {
      ...ClinicWithoutRoomsWithoutClientsData
      staffList {
        ...ClinicStaffData
      }
      businessHours {
        ...BusinessHoursData
      }
    }
  }
  ${clinicFragments.clinicWithoutRoomsWithoutClientsData}
  ${clinicFragments.buisnessHoursData}
  ${clinicFragments.clinicStaffData}
`;

export const GET_CLINIC_ROOMS = gql`
  query($id: String!) {
    clinicRooms(id: $id) {
      ...RoomsData
    }
  }
  ${clinicFragments.roomsData}
`;

export const ADD_NEW_CLINIC = gql`
  mutation($clinic: ClinicInput!) {
    addClinic(clinic: $clinic) {
      ...EditClinic
    }
  }
  ${clinicFragments.editClinic}
`;

export const GET_ALL_ROOMS = gql`
  query {
    getAllRooms {
      ...RoomsData
    }
  }
  ${clinicFragments.roomsData}
`;

export const ADD_NEW_ROOM = gql`
  mutation($room: RoomInput!) {
    addRoom(room: $room) {
      ...RoomsData
    }
  }
  ${clinicFragments.roomsData}
`;
export const GET_ROOM = gql`
  query($id: String!) {
    getRoomById(id: $id) {
      ...RoomsData
    }
  }
  ${clinicFragments.roomsData}
`;
