import { useMemo, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TitleWrapper } from './staff.style';
import Table from 'views/containers/table';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import {
  GET_ABBREVIATED_PROVIDERS_LIST,
  GET_PROVIDER_INITIAL_LOAD_DATA,
  GET_PROVIDER_MEMBER
} from 'api/graphql/v2/queries/Providers';
import { DEFAULT_SORT } from 'utils/constants/default';
import React, { useState } from 'react';
import {
  ICalendarFilterData,
  ICalendarFilterOption
} from 'model/calendar/filters';
import {
  IEditProviderForm,
  IProvider,
  PROVIDER_STATUS_LIST
} from 'model/v2/providers';
import FilterTagList from 'components/calendar/calendarToolbar/FilterTagList';
import { CALENDAR_FILTER_ENUM } from 'utils/constants/calendarFilters';
import { mapProviderTableInfo } from 'utils/mappers/response/staff';
import { PROVIDER_COLUMNS } from 'utils/constants/tables/provider';
import {
  WaitListFilterWrapper,
  WaitListPopOver,
  WaitlistWrapper
} from 'views/components/waitlistPage/waitlist.styled';
import { table_Skeletal_Loader } from 'utils/mappers/carePlans';
import { Skeleton } from 'antd';
import PopOverComp from 'components/popover';
import {
  FilterPageNameEnum,
  savedFilterNameEnum
} from 'components/calendar/calendarToolbar/FilterTagList/form/utils';
import InfiniteScroll from 'react-infinite-scroll-component';

import SideBar from 'components/sidebar/sidebar';
import { ProviderCalendarFiltersAndSearchWrapper } from 'components/calendar/calendarFiltersAndSearch/style';
import WaitListSideDrawer from 'views/components/sideDrawer/waitListSideDrawer';
import useCalendarFilterFetcherHook from 'hooks/calendarFilterFetcherHook';
import { defaultFilterPrefVar } from 'utils/cache/filters';
import { useReactiveVar } from '@apollo/client';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { mapWaitListClientAvailabilityFilters } from 'helpers/clientAvailabilityHelper';
import { cloneWithoutTypename } from 'utils/common';
import { UPDATE_PROVIDER } from 'api/graphql/v2/mutations/Providers';
import MainButton from 'views/components/button';
import ProviderFilterLayout from '../filterLayout';
import ClientSearch from '../input/clientInput';
import ProviderSearch from '../input/providerInput';
import { PROVIDER_ROSTER_STATUS_SELECT } from 'utils/constants/tables/staff';
import { IClientLanguages, IProviderSelect } from 'model/client';
import { updateFinalResults } from 'utils/mappers/response/clients';
import { ProviderCertification } from 'model/v2';
export const searchValueContext = React.createContext('');
export const checkedSavedFilterContext = React.createContext<any>(undefined);

type tableData = {
  providerId: number;
};
interface OptionType {
  displayName: string;
  id?: any;
}
export const providerRosterCntx: any = React.createContext(undefined);

const Staff = () => {
  const history = useHistory();
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(false);
  const [sidebarData, setSidebarData] = useState();
  const [tableData, setTableData] = useState<tableData[] | []>([]);
  const [list, setList] = useState<OptionType[]>([]);
  const [providerSearch, setProviderSearch] = useState({
    id: undefined,
    name: ''
  });
  const [clientSearch, setClientSearch] = useState({ id: null, name: '' });
  const [clientList, setClientList] = useState<OptionType[]>([]);
  const [checkedFilter, setCheckedFilter] = useState('');
  const [checkedSavedFilter, setCheckedSavedFilter] = useState('');
  const [isShowDrawer, setIsShowDrawer] = useState<Boolean>(false);
  const [checkedProviderAvailability, setProviderAvailability] = useState<any>(
    []
  );
  const [pageNo, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [providerAllergies, setProviderAllergies] = useState([]);
  const useDefaultFilter = useReactiveVar(defaultFilterPrefVar);
  const [hasSavedFilters, setHasSavedFilters] = useState(true);
  const [selectTypeData, setSelectTypeData] = useState([]);
  const [calendarFilterData, setCalendarFilterData] = useState<
    ICalendarFilterData
  >();
  const [checkedLanguage, setCheckedLanguage] = useState<
    ICalendarFilterOption[]
  >([]);
  const [providerLocations, setProviderLocations] = useState();
  const [clickedFilter, setClickedFilter] = useState('');
  const [onKeysDown, setOnKeyDown] = useState(false);
  const [enteredProviders, setEnteredProviders] = useState<OptionType[]>([]);
  const [isSearchPhase, setIsSearchPhase] = useState(false);
  const [isInputCleared, setIsInputCleared] = useState(false);
  const [filterLanguages, setFilterLanguages] = useState({});

  useEffect(() => {
    setCheckedFilter(checkedSavedFilter);
  }, [checkedSavedFilter]);

  const [getProviderInitalLoadContent, { loading: IsLoading }] = useLazyQuery(
    GET_PROVIDER_INITIAL_LOAD_DATA,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data: any) => {
        let {
          petAllergies,
          filterSelections,
          filterList,
          providerLocations
        } = data.getProviderIntialLoadData;
        const allAllergies = petAllergies?.map((item: any) => ({
          value: item.id,
          label: item.value
        }));

        if (!defaultFilter)
          setCheckedStatus([
            {
              value: PROVIDER_STATUS_LIST.ACTIVE,
              label: PROVIDER_STATUS_LIST.ACTIVE
            }
          ]);
        setFilterLanguages(
          filterList?.languages?.reduce(
            (total: Record<string, number>, data: IClientLanguages) => {
              total[data?.name!] = data.id;
              return total; // Make sure to return total after setting the value
            },
            {}
          )
        );
        filterList.corticaProgram = filterList?.corticaProgram?.map(
          (data: any) => {
            return {
              ...data,
              title: data.programName
            };
          }
        );
        filterList.providerCertifications = filterList?.providerCertifications?.map(
          (data: ProviderCertification) => {
            return {
              ...data,
              title: data.certificationName
            };
          }
        );
        setProviderAllergies(allAllergies);
        setSelectTypeData(filterSelections);
        setCalendarFilterData(filterList);
        setProviderLocations(providerLocations);
      }
    }
  );
  useEffect(() => {
    getProviderInitalLoadContent();
  }, []);
  const allProviderCertOptions = calendarFilterData?.providerCertifications;

  const {
    defaultFilter,
    savedFilters,
    savedFiltersMap,
    allFilters,
    isSavedFilterLoading,
    useFilterRemovalHook,
    SavedFilter,
    refetch
  } = useCalendarFilterFetcherHook(FilterPageNameEnum.provider, 'no-cache');

  const [additionalFilter, setAdditionalFilter] = useState<any>({
    isFilterApplied: false,
    locations: [],
    startTime: null,
    endTime: null,
    days: []
  });

  const prepareSubmitData = (data: IEditProviderForm) => {
    data = {
      ...data,
      telehealthLinks: data?.telehealthLinks
    };
    delete data.providerCapacity?.rampingPercentage;
    return data;
  };

  const [update] = useMutation(UPDATE_PROVIDER);

  const handleStaffUpdate = useCallback(
    async (updatedField, providerInfo, updateType) => {
      if (providerInfo) {
        let data = prepareSubmitData(providerInfo);
        let provider = cloneWithoutTypename(data);
        provider[updateType] = updatedField;
        if (updateType === 'status') {
          let [activeMap] = PROVIDER_ROSTER_STATUS_SELECT;
          provider.isActive = updatedField === activeMap.value ? true : false;
        }
        setTableData((prevData: tableData[]) => {
          return prevData.map((data: tableData) => {
            if (data.providerId === providerInfo.id) {
              return {
                ...data,
                [updateType]: updatedField
              };
            } else
              return {
                ...data
              };
          });
        });
        const isProviderCapacityUpdateRequired = false;
        delete provider?.clinic?.abbreviation;
        delete provider?.speciality?.title;
        delete provider?.name;
        delete provider?.speciality?.abbreviation;
        delete provider?.certificationMappings;
        provider.corticaPrograms = provider?.corticaPrograms?.map(
          (data: any) => {
            return {
              id: data.id
            };
          }
        );
        await updateProvider(provider, isProviderCapacityUpdateRequired);
      }
    },
    []
  );

  const updateProviderLanguage = (
    providerId: number,
    editType: string,
    optionType: string,
    providerInfo: tableData
  ) => async (value: IProviderSelect, selectedValues: string[]) => {
    const mappedFunc = updateFinalResults({
      id: providerId,
      filterLanguages,
      additionalUpdateFields: [],
      calendarFilterData,
      value,
      selectedValues,
      editType,
      optionType,
      selectedOptions: []
    });
    const mappedTabbleData = mappedFunc.next().value;
    handleStaffUpdate(mappedTabbleData, providerInfo, editType);
  };

  const updateProvider = useCallback(
    async (provider: IProvider, isProviderCapacityUpdateRequired: boolean) => {
      await update({
        variables: {
          data: provider,
          isProviderCapacityUpdateRequired
        },
        refetchQueries: [
          {
            query: GET_PROVIDER_MEMBER,
            variables: {
              id: provider.id
            }
          }
        ]
      })
        .then()
        .catch(error => {
          console.log('Error while saving staff', JSON.stringify(error));
        });
    },
    [update]
  );

  const clearAdditionalFilter = React.useCallback(() => {
    setCheckedFilter('');
    setAdditionalFilter({
      isFilterApplied: false,
      locations: [],
      startTime: null,
      endTime: null,
      days: []
    });
  }, [setCheckedFilter, setAdditionalFilter]);

  const onSearchChange = (e: any) => {
    let [value] = e;
    if (value?.name === '') {
      setProviderSearch({ id: undefined, name: '' });
      setEnteredProviders([]);
      setTableData([]);
      setIsInputCleared(true);
      setHasMore(true);
    } else if (value) {
      setProviderSearch({ id: value.id, name: value.name });
      setIsInputCleared(false);
    }
  };

  const onclientSearchChange = (e: any) => {
    let [value] = e;
    if (!value?.name) {
      setClientSearch({ id: null, name: '' });
    } else if (value) {
      setClientSearch({ id: value.id, name: value.name });
    }
  };

  // const [selectedButtonIndex, setSelectedButtonIndex] = useState<number>(-1);

  //The following 4 variables manage state for which options have been checked in dropdowns
  const [checkedClinics, setCheckedClinics] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [checkedCertifications, setCheckedCertifications] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [checkedPrograms, setCheckedPrograms] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [checkedStatus, setCheckedStatus] = useState<ICalendarFilterOption[]>(
    []
  );
  const [checkedDepartments, setCheckedDepartments] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [checkedSpecialities, setCheckedSpecialities] = React.useState<
    ICalendarFilterOption[]
  >([]);

  useEffect(() => {
    setPage(1);
    setTableData([]);
    setHasMore(true);
  }, [
    checkedClinics,
    checkedCertifications,
    checkedPrograms,
    checkedDepartments,
    checkedSpecialities,
    checkedStatus,
    checkedProviderAvailability,
    clientSearch.id,
    providerSearch.id,
    checkedLanguage,
    additionalFilter.isFilterApplied
  ]);

  const filterSelectionType: any = {};
  useMemo(() => {
    selectTypeData?.map((selectionType: any) => {
      filterSelectionType[selectionType.typeName] = selectionType.id;
    });
  }, [selectTypeData, filterSelectionType]);

  const handleSavedFilter = useCallback(
    name => {
      const savedFilter = savedFiltersMap.get(name);
      if (!savedFilter) return;
      SavedFilter(
        FilterPageNameEnum.provider,
        {
          setCheckedClinics,
          setCheckedCertifications,
          setCheckedStatus,
          setCheckedPrograms,
          setCheckedSpecialities,
          setProviderAvailability,
          setCheckedDepartments,
          setCheckedLanguage
        },
        setAdditionalFilter,
        setCheckedSavedFilter,
        savedFilterNameEnum.saved,
        savedFilter,
        name
      );
    },
    [savedFiltersMap]
  );
  useEffect(() => {
    if (checkedFilter && checkedFilter !== '') handleSavedFilter(checkedFilter);
  }, [savedFilters]);
  useEffect(() => {
    if (defaultFilter && checkedSavedFilter === '') {
      SavedFilter(
        FilterPageNameEnum.provider,
        {
          setCheckedClinics,
          setCheckedCertifications,
          setCheckedStatus,
          setCheckedPrograms,
          setCheckedSpecialities,
          setProviderAvailability,
          setCheckedDepartments,
          setCheckedLanguage
        },
        setAdditionalFilter,
        setCheckedSavedFilter,
        savedFilterNameEnum.default,
        defaultFilter,
        false,
        defaultFilterPrefVar
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter, useDefaultFilter]);
  const handleSearchedValues = useCallback(
    list => {
      return list;
    },
    [list]
  );
  const onSetClickedFilter = useCallback(
    label => {
      setClickedFilter(label);
    },
    [setClickedFilter]
  );

  const handleStaffRemoveFilter = useFilterRemovalHook(
    setCheckedSavedFilter,
    CALENDAR_FILTER_ENUM,
    {
      setFilterOptions: {
        clinics: setCheckedClinics,
        providerCertifications: setCheckedCertifications,
        programs: setCheckedPrograms,
        providerAvailability: setProviderAvailability,
        specialities: setCheckedSpecialities,
        status: setCheckedStatus,
        departments: setCheckedDepartments,
        language: setCheckedLanguage
      }
    }
  );

  /* 
  The current strategy is a pagination approach leveraging server-side filtering, 
  passing the filters that users select to the query on the backend, and returning the relevent data.
  */
  let checkedClinicsIds = checkedClinics.map(clinic => clinic.value);
  let checkedCertificationNames = checkedCertifications.map(
    providerCertifications => providerCertifications.value
  );
  let checkedDepartmentIds = checkedDepartments.map(
    department => department.value
  );
  let checkedspecialtiesIds = checkedSpecialities.map(
    specialty => specialty.value
  );
  let checkedLanguageLabel = checkedLanguage.map(language => language.label);

  let checkedStatusLabel = checkedStatus?.map(status => status.label);
  let checkedProgramIds = checkedPrograms.map(status => status.label);
  const loaderView = table_Skeletal_Loader(
    Skeleton,
    FilterPageNameEnum.provider,
    tableData
  );
  const {
    loading: providerDataLoading,
    data: allProviderData,
    refetch: refetchActualQuery
  } = useQuery(GET_ABBREVIATED_PROVIDERS_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      from: pageNo * 25 - 25,
      size: 25,
      search: providerSearch.id ? [providerSearch.id] : undefined,
      name: providerSearch.name,
      sort: DEFAULT_SORT,
      clinic: [...checkedClinicsIds],
      certificationName: [...checkedCertificationNames],
      specialty: [...checkedspecialtiesIds],
      status: [...checkedStatusLabel!],
      corticaProgram: [...checkedProgramIds],
      clientSearch: clientSearch.id,
      department: [...checkedDepartmentIds],
      language: [...checkedLanguageLabel],
      additionalFilter: mapWaitListClientAvailabilityFilters(
        checkedProviderAvailability,
        additionalFilter,
        { clientLocations: providerLocations }
      )
    },
    onCompleted: () => {
      setIsSearchPhase(false);
      setOnKeyDown(false);
    },
    onError: error => {
      console.log('Error when querying to fetch list of providers:', error);
    }
  });

  useEffect(() => {
    if (allProviderData && !providerDataLoading && !isSearchPhase) {
      const newProviders = mapProviderTableInfo(
        allProviderData?.providerRoaster?.providers,
        Select,
        providerAllergies,
        allProviderCertOptions,
        handleStaffUpdate
      );
      setTableData(prevPatients => {
        return pageNo > 1 ? [...prevPatients, ...newProviders] : newProviders;
      });
      if (newProviders.length < 25) {
        setHasMore(false);
      }
    }
  }, [
    allProviderData,
    providerDataLoading,
    handleStaffUpdate,
    pageNo,
    providerAllergies,
    allProviderCertOptions,
    isSearchPhase
  ]);

  const addStaff = useCallback(() => {
    history.push({
      pathname: '/providers/new',
      state: {
        title: 'Add Staff Info'
      }
    });
  }, [history]);

  const handleNoteAdded = (clientId: string, addedNote: any) => {
    setTableData((prevData: any) => {
      return prevData.map((item: any) => {
        if (item.providerId === clientId) {
          return {
            ...item,
            notes: [...item.notes, addedNote],
            notesCount: item.notesCount + 1
          };
        }
        return item;
      });
    });
  };

  const staffFilters = {
    clinics: checkedClinics,
    providerCertifications: checkedCertifications,
    specialities: checkedSpecialities,
    departments: checkedDepartments,
    locations: [],
    programs: checkedPrograms,
    language: checkedLanguage,
    status: checkedStatus,
    savedFilterName: ''
  };

  const clearAllFilters = useCallback(() => {
    setCheckedClinics([]);
    setCheckedCertifications([]);
    setCheckedPrograms([]);
    setCheckedSpecialities([]);
    setProviderAvailability([]);
    setCheckedStatus([]);
    setCheckedDepartments([]);
    setCheckedLanguage([]);
  }, []);

  const navigateToProviderProfile = (rowId: number) => {
    !providerDataLoading && window.open('/providers/' + rowId, '_blank');
  };

  //Clear all filters
  const clearAllSelectedFilters = useCallback(() => {
    setCheckedFilter('');
    clearAllFilters();
  }, []);

  //Infinity scroll logic to get next set of results
  const fetchMoreData = () => {
    if (providerDataLoading) {
      return;
    } else {
      setPage(prevPage => prevPage + 1);
    }
  };

  const [
    fetchProviderRoster,
    { loading: providerDataLoadingwithIds, data: providersDataWithIds }
  ] = useLazyQuery(GET_ABBREVIATED_PROVIDERS_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const newProviders = mapProviderTableInfo(
        providersDataWithIds?.providerRoaster?.providers,
        Select,
        providerAllergies,
        allProviderCertOptions,
        handleStaffUpdate
      );
      setTableData(newProviders);
    },
    onError: error => {
      console.log('Error when querying to fetch list of providers:', error);
    }
  });

  // Trigger query manually when `enteredProviders` or `onKeysDown` changes
  useEffect(() => {
    if (enteredProviders?.length > 0 && onKeysDown) {
      setIsSearchPhase(true);
      setTableData([]);
      fetchProviderRoster({
        variables: {
          from: pageNo * 25 - 25,
          size: 25,
          search: enteredProviders?.map((id: any) => id.id) || [],
          name: providerSearch.name,
          sort: DEFAULT_SORT,
          clinic: [...checkedClinicsIds],
          specialty: [...checkedspecialtiesIds],
          status: [...checkedStatusLabel!],
          corticaProgram: [...checkedProgramIds],
          clientSearch: clientSearch.id,
          department: [...checkedDepartmentIds],
          additionalFilter: mapWaitListClientAvailabilityFilters(
            checkedProviderAvailability,
            additionalFilter,
            { clientLocations: providerLocations }
          )
        }
      });
      setIsInputCleared(false);
    }
  }, [enteredProviders, onKeysDown, fetchProviderRoster]);

  useEffect(() => {
    if (providerSearch.name === '') {
      setIsSearchPhase(false);
      setEnteredProviders([]);
      setTableData([]);
      setIsInputCleared(true);
    }
  }, [providerSearch.name]);

  useEffect(() => {
    if (isInputCleared) {
      setTableData([]);
      refetchActualQuery();
      setIsInputCleared(false);
    }
  }, [refetchActualQuery, isInputCleared]);

  return (
    <div>
      <TitleWrapper>
        <div className="provider-title-outer-wrap">
          <div className="inner-wrap provider-title">
            <h1>Provider Roster</h1>
          </div>
          <MainButton
            className="Add-Staff-btn"
            title={'Add Staff'}
            onClick={addStaff}
          />
        </div>
      </TitleWrapper>
      <providerRosterCntx.Provider value={setCheckedSavedFilter}>
        <WaitListFilterWrapper isStaff={true}>
          <div className="actions">
            <div className="dropdown-wrapper provider-dropdown-wrapper"></div>
            <ProviderFilterLayout
              isLoading={IsLoading}
              FilterData={calendarFilterData!}
              checkedClinics={checkedClinics}
              setCheckedClinics={setCheckedClinics}
              checkedCertifications={checkedCertifications}
              setCheckedCertifications={setCheckedCertifications}
              checkedPrograms={checkedPrograms}
              setCheckedPrograms={setCheckedPrograms}
              checkedStatus={checkedStatus}
              setCheckedStatus={setCheckedStatus}
              checkedDepartments={checkedDepartments}
              setCheckedDepartments={setCheckedDepartments}
              checkedSpecialities={checkedSpecialities}
              setCheckedSpecialities={setCheckedSpecialities}
              setIsShowDrawer={setIsShowDrawer}
              setCheckedLanguage={setCheckedLanguage}
              checkedLanguage={checkedLanguage}
            >
              {() => {
                let ClientSearchWrapper = (
                  <ProviderCalendarFiltersAndSearchWrapper isStaffPage={true}>
                    <ClientSearch
                      clientSearch={clientSearch.name}
                      onclientSearchChange={onclientSearchChange}
                      setClientList={setClientList}
                      clientList={clientList}
                    />
                  </ProviderCalendarFiltersAndSearchWrapper>
                );
                let ProviderSearchWrapper = (
                  <ProviderCalendarFiltersAndSearchWrapper isStaffPage={true}>
                    <ProviderSearch
                      providerSearch={providerSearch.name}
                      onSearchChange={onSearchChange}
                      setList={setList}
                      list={list}
                      setOnKeyDown={setOnKeyDown}
                      setEnteredProviders={setEnteredProviders}
                    />
                  </ProviderCalendarFiltersAndSearchWrapper>
                );
                return { ClientSearchWrapper, ProviderSearchWrapper };
              }}
            </ProviderFilterLayout>
          </div>
        </WaitListFilterWrapper>
        <WaitlistWrapper isStaff={true}>
          <div className="inner-wrap staff-filters">
            <FilterTagList
              filters={staffFilters}
              refetch={refetch}
              onRemoveFilter={handleStaffRemoveFilter}
              showSavedIcon={true}
              additionalFilter={additionalFilter}
              clearAdditionalFilter={clearAdditionalFilter}
              checkedFilter={checkedFilter}
              setCheckedSavedFilter={setCheckedSavedFilter}
              pageName={FilterPageNameEnum.provider}
              clearAllSelectedFilters={clearAllSelectedFilters}
              showClearFilters={true}
            />
          </div>
          <div className="inner-wrap provider-wrap">
            <InfiniteScroll
              dataLength={tableData.length}
              next={fetchMoreData}
              hasMore={hasMore}
              className="infinite-scroll-component"
              endMessage={
                <p style={{ textAlign: 'center' }}>No more providers</p>
              }
              loader={''}
            >
              <Table
                className="custom-table-class"
                columns={PROVIDER_COLUMNS(
                  providerDataLoading,
                  PopOverComp,
                  WaitListPopOver,
                  setShowSidebar,
                  setSidebarData,
                  navigateToProviderProfile,
                  handleStaffUpdate,
                  providerAllergies,
                  calendarFilterData,
                  updateProviderLanguage
                )}
                data={
                  providerDataLoading || providerDataLoadingwithIds
                    ? loaderView
                    : tableData
                }
                total={
                  providerDataLoading
                    ? 6
                    : allProviderData?.providerRoaster?.count
                }
                clearAllSelectedFilters={clearAllSelectedFilters}
                page={pageNo}
                pagination={false}
                onPageChangeCallback={() => {}}
                onRowSelect={() => {}}
                rowClassName={() => {
                  return 'custom-provider-row';
                }}
              />
            </InfiniteScroll>

            {showSidebar ? (
              <SideBar
                show={showSidebar}
                close={() => setShowSidebar(!setShowSidebar)}
                sidebarData={sidebarData}
                setSidebarData={setSidebarData}
                onNoteAdded={handleNoteAdded}
                pageName={FilterPageNameEnum.provider}
                activeTab={'notes'}
              />
            ) : (
              <></>
            )}
          </div>
          {isShowDrawer && (
            <WaitListSideDrawer
              isShowDrawer={isShowDrawer}
              refetch={refetch}
              setAdditionalFilter={setAdditionalFilter}
              waitListDataLoading={IsLoading}
              Skeleton={Skeleton}
              additionalFilter={additionalFilter}
              locations={providerLocations}
              location={location}
              setIsShowDrawer={setIsShowDrawer}
              selectSavedFilter={handleSavedFilter}
              savedFilter={checkedSavedFilter}
              handleSearchedValues={handleSearchedValues}
              setHasData={setHasSavedFilters}
              setClickedFilter={onSetClickedFilter}
              savedFilters={savedFilters}
              defaultFilter={defaultFilter}
              isSavedFilterLoading={isSavedFilterLoading && IsLoading}
              pageName={FilterPageNameEnum.provider}
              setCheckedFilter={setCheckedFilter}
              checkedFilter={checkedFilter}
              clearAllFilters={clearAllFilters}
              filterSelectionType={filterSelectionType}
              allFilters={allFilters}
              hasSavedFilters={hasSavedFilters}
              clickedFilter={clickedFilter}
            />
          )}
        </WaitlistWrapper>
      </providerRosterCntx.Provider>
    </div>
  );
};
export default Staff;
