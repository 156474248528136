import * as yup from 'yup';
import {
  IAppointmentType,
  IEventType,
  Provider,
  PROVIDER_STATUS_LIST
} from 'model/v2';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';

const STAFF_FORM_VALIDATORS = (
  appointmentTypesOptions: IAppointmentType[]
) => ({
  APPOINTMENT_TYPES: yup.array().required('Appointment is required'),
  CLINIC: yup.number().required('Clinic is required'),
  SPECIALITY: yup.number().required('Speciality is required'),
  STATUS: yup.string().nullable(),
  IS_TEST_PROVIDER: yup.boolean().required('Test Provider is required'),
  SALESFORCE_ID: yup.string().required('Salesforce Id is required'),
  IS_transportation: yup.boolean(),
  EMPLOYMENT: yup
    .string()
    .nullable()
    .required('Employment is required'),
  CAPACITY: yup.number().when('status', {
    is: (status: PROVIDER_STATUS_LIST) =>
      ![
        PROVIDER_STATUS_LIST.TERMINATED,
        PROVIDER_STATUS_LIST.LEAVE_OF_ABSENCE
      ].includes(status),
    then: yup
      .number()
      .required('Capacity is required')
      .typeError('Capacity value invalid')
      .min(1, 'Capacity must be more than or equal to 1')
      .max(50, 'Capacity must be less than or equal to 50')
  }),
  CR_ID: yup
    .number()
    .typeError('CR Id must be a number')
    .required('CR Id is required'),
  LANGUAGES: yup.array(),
  ATHENA_ID: yup
    .number()
    .transform(value => (isNaN(value) ? null : value))
    .nullable(true)
    .test(
      'AthenaIdTest',
      'Athena Id is required with clinical appointment',
      function(value?: number | null) {
        return this.parent.appointmentTypes
          ? validateAthenaId(
              this.parent.appointmentTypes,
              appointmentTypesOptions,
              value
            )
          : true;
      }
    ),
  TELEHEALTH: yup
    .array()
    .min(1, 'Licensed For is required')
    .nullable()
    .required('Licensed For is required'),
  ASSISTANTS: yup.array().nullable()
});

export const STAFF_ADD_FORM_SCHEMA = (
  appointmentTypesOptions: IAppointmentType[]
) => {
  const validator = STAFF_FORM_VALIDATORS(appointmentTypesOptions);
  return yup.object().shape({
    [Provider.capacity]: validator.CAPACITY,
    [Provider.employment]: validator.EMPLOYMENT,
    [Provider.status]: validator.STATUS,
    [Provider.isTestProvider]: validator.IS_TEST_PROVIDER,
    [Provider.salesForceId]: validator.SALESFORCE_ID,
    [Provider.transportation]: validator.IS_transportation,
    [Provider.appointmentTypes]: validator.APPOINTMENT_TYPES,
    [Provider.languages]: validator.LANGUAGES,
    [Provider.clinic]: yup.object().shape({
      id: validator.CLINIC
    }),
    [Provider.speciality]: yup.object().shape({
      id: validator.SPECIALITY
    }),
    [Provider.email]: yup.string().required('Email is required'),
    [Provider.crId]: yup
      .number()
      .typeError('CR ID must be a number')
      .required('CR Id is required'),
    [Provider.firstName]: yup
      .string()
      .required('Please Enter Email and click on Import'),
    [Provider.lastName]: yup
      .string()
      .required('Please Enter Email and click Import'),
    [Provider.telehealth]: validator.TELEHEALTH,
    [Provider.displayName]: yup
      .string()
      .required('Please Enter Email and click Import'),
    [Provider.athenaId]: validator.ATHENA_ID,
    [Provider.addressLine1]: validateProviderAddress(
      'AddressLine1',
      'Address is required'
    ),
    [Provider.addressLine2]: yup.string(),
    [Provider.city]: validateProviderAddress('city', 'City is required'),
    [Provider.zipCode]: validateProviderAddress(
      'zipCode',
      'Zip Code is required'
    ),
    [Provider.state]: validateProviderAddress('state', 'State is required')
  });
};
export const STAFF_EDIT_FORM_SCHEMA = (
  appointmentTypesOptions: IAppointmentType[]
) => {
  const validator = STAFF_FORM_VALIDATORS(appointmentTypesOptions);
  let schema = yup.object().shape({
    [Provider.capacity]: validator.CAPACITY,
    [Provider.employment]: validator.EMPLOYMENT,
    [Provider.status]: validator.STATUS,
    [Provider.appointmentTypes]: validator.APPOINTMENT_TYPES,
    [Provider.clinic]: yup.object().shape({
      id: validator.CLINIC
    }),
    [Provider.speciality]: yup.object().shape({
      id: validator.SPECIALITY
    }),
    [Provider.crId]: validator.CR_ID,
    [Provider.languages]: validator.LANGUAGES,
    [Provider.telehealth]: validator.TELEHEALTH,
    [Provider.athenaId]: validator.ATHENA_ID,
    [Provider.assistantsInput]: validator.ASSISTANTS,
    [Provider.addressLine1]: validateProviderAddress(
      'AddressLine1',
      'Address is required'
    ),
    [Provider.addressLine2]: yup.string(),
    [Provider.city]: validateProviderAddress('city', 'City is required'),
    [Provider.zipCode]: validateProviderAddress(
      'zipCode',
      'Zip Code is required'
    ),
    [Provider.state]: validateProviderAddress('state', 'State is required')
  });

  return schema;
};

const validateProviderAddress = (fieldValue: string, errorMessage: string) => {
  return yup.string().test(fieldValue, errorMessage, function() {
    const addressLine2 = this.parent.addressLine2 ?? false;
    const addressLine1 = this.parent.addressLine1 ?? false;
    const state = this.parent.state ?? false;
    const city = this.parent.city ?? false;
    const zipCode = this.parent.zipCode ?? false;
    let shouldShowError: boolean = false;
    switch (fieldValue) {
      case 'city':
        shouldShowError =
          (addressLine1 || addressLine2 || state || zipCode) && !city;
        break;
      case 'state':
        shouldShowError =
          (addressLine1 || addressLine2 || city || zipCode) && !state;
        break;
      case 'zipCode':
        shouldShowError =
          (addressLine1 || addressLine2 || city || state) && !zipCode;
        break;
      case 'AddressLine1':
        shouldShowError =
          (zipCode || addressLine2 || city || state) && !addressLine1;
        break;
      default:
        shouldShowError = false;
    }

    return !shouldShowError;
  });
};

const validateAthenaId = (
  selectedAppointmentTypes: string[],
  appointmentTypesOptions: IAppointmentType[],
  id?: number | null
) => {
  const appointmentEventTypeMap: Map<string, IEventType> = new Map();

  appointmentTypesOptions.forEach(it => {
    appointmentEventTypeMap.set(`${it.id}`, it.eventType!);
  });
  return (
    selectedAppointmentTypes.filter(
      item =>
        appointmentEventTypeMap.get(item)?.name === EVENT_TYPES.DT ||
        appointmentEventTypeMap.get(item)?.name === EVENT_TYPES.MED ||
        appointmentEventTypeMap.get(item)?.name === EVENT_TYPES.Admin
    ).length === 0 || id !== undefined
  );
};
