import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState
} from 'react';
import { Icon } from 'semantic-ui-react';
import Tag from './Tag';
import { ICalendarFilter } from 'model/calendar/filters';
import { FilterTagListWrapper } from './style';
import FilterTagForm from './form';
import {
  calendarFiltersPrefVar,
  defaultFilterPrefVar
} from 'utils/cache/filters';
import {
  CALENDAR_FILTER_ENUM,
  internalFilters
} from 'utils/constants/calendarFilters';
import { isEmptyObject } from 'utils/common';
import {
  EVENTS_FETCHING_ACTIONS,
  plannerContext
} from 'pages/MainCalendarPage';

interface IProps {
  filters: ICalendarFilter;
  pageName: string;
  onRemoveFilter?: (type: CALENDAR_FILTER_ENUM, value: string) => void;
  showSavedIcon?: boolean;
  additionalFilter?: Record<string, any>;
  clearAdditionalFilter?: () => void;
  checkedFilter?: string;
  setCheckedSavedFilter?: Dispatch<SetStateAction<string>>;
  selectedFilter?: string;
  clearAllSelectedFilters?: () => void;
  showClearFilters?: boolean;
  refetch?: () => void;
}

const FilterTagList: React.FC<IProps> = ({
  filters,
  onRemoveFilter,
  showSavedIcon,
  additionalFilter,
  clearAdditionalFilter,
  pageName,
  checkedFilter,
  setCheckedSavedFilter,
  clearAllSelectedFilters,
  showClearFilters,
  refetch
}: IProps) => {
  const [editFilterModal, setEditFilterModal] = useState(false);
  const { setAction } = useContext(plannerContext);
  const { isLoading } = useContext(plannerContext);

  const handleRemoveTag = useCallback(
    (type: CALENDAR_FILTER_ENUM, value: string) => {
      let filtersObject: ICalendarFilter = {
        clinics: filters.clinics,
        programs: filters.programs,
        providerCertifications: filters.providerCertifications,
        locations: filters.locations,
        departments: filters.departments,
        specialities: filters.specialities,
        savedFilterName: '',
        internals: filters.internals,
        status: filters.status,
        waitListReason: filters.waitListReason,
        therapyType: filters.therapyType,
        serviceType: filters.serviceType,
        clientAvailability: filters.clientAvailability,
        additionalFilter: additionalFilter,
        corticaProgram: filters.corticaProgram,
        smartSchedulingStatus: filters.smartSchedulingStatus,
        language: filters.language,
        isVBC: filters.isVBC,
        insuranceCategories: filters.insuranceCategories,
        insurancePayors: filters.insurancePayors,
        insurancePlans: filters.insurancePlans
      };

      if (type === CALENDAR_FILTER_ENUM.internals) {
        filtersObject[type] = (filters[type] as internalFilters[])?.filter(
          f => f !== value
        );
      } else if (type === CALENDAR_FILTER_ENUM.isVBC) {
        filtersObject[type] = false;
      } else {
        filtersObject[type] = filters[type]!.filter(
          (f: any) => f.value !== value
        );
      }
      defaultFilterPrefVar(false);
      setAction(EVENTS_FETCHING_ACTIONS.APPLY_FILTERS);
      calendarFiltersPrefVar(filtersObject);
    },
    [filters, setAction]
  );

  const getRemoveTagHandler = (type: CALENDAR_FILTER_ENUM, value: string) => {
    return () => {
      onRemoveFilter
        ? onRemoveFilter(type, value)
        : handleRemoveTag(type, value);
    };
  };

  const therapyTypeTags = filters?.therapyType?.map((f: any) => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.therapyType}
        label={f.label}
        handleRemoveTag={getRemoveTagHandler(
          CALENDAR_FILTER_ENUM.therapyType,
          f.value
        )}
        value={f.value}
        isReadOnly={isLoading}
      />
    );
  });

  const language = filters.language?.map((f: any) => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.language}
        label={f.label}
        handleRemoveTag={getRemoveTagHandler(
          CALENDAR_FILTER_ENUM.language,
          f.value
        )}
        value={f.value}
        isReadOnly={isLoading}
      />
    );
  });
  const smartSchedulingStatusTags = filters?.smartSchedulingStatus?.map(
    (f: any) => {
      return (
        <Tag
          type={CALENDAR_FILTER_ENUM.smartSchedulingStatus}
          label={f.label}
          handleRemoveTag={getRemoveTagHandler(
            CALENDAR_FILTER_ENUM.smartSchedulingStatus,
            f.value
          )}
          value={f.value}
          isReadOnly={isLoading}
        />
      );
    }
  );

  const corticaProgram = filters?.corticaProgram?.map((f: any) => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.corticaProgram}
        label={f.label}
        handleRemoveTag={getRemoveTagHandler(
          CALENDAR_FILTER_ENUM.corticaProgram,
          f.value
        )}
        value={f.value}
        isReadOnly={isLoading}
      />
    );
  });

  const AdditionalFilterTag = () => {
    if (additionalFilter?.isFilterApplied) {
      return (
        <Tag
          type={CALENDAR_FILTER_ENUM.therapyType}
          label={'Additional Filter'}
          handleRemoveTag={clearAdditionalFilter}
          value={'Additional Filter'}
          isReadOnly={isLoading}
        />
      );
    } else return <></>;
  };
  const clientAvailability = filters?.clientAvailability?.map((f: any) => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.clientAvailability}
        label={f.label}
        handleRemoveTag={getRemoveTagHandler(
          CALENDAR_FILTER_ENUM.clientAvailability,
          f.value
        )}
        value={f.value}
        isReadOnly={isLoading}
      />
    );
  });

  const waitListReason = filters?.waitListReason?.map((f: any) => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.waitListReason}
        label={f.label}
        handleRemoveTag={getRemoveTagHandler(
          CALENDAR_FILTER_ENUM.waitListReason,
          f.value
        )}
        value={f.value}
        isReadOnly={isLoading}
      />
    );
  });

  const ServiceTypeTags = filters?.serviceType?.map((f: any) => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.serviceType}
        label={f.label}
        handleRemoveTag={getRemoveTagHandler(
          CALENDAR_FILTER_ENUM.serviceType,
          f.value
        )}
        value={f.value}
        isReadOnly={isLoading}
      />
    );
  });

  const clinicTags = filters?.clinics?.map(f => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.clinics}
        label={f.label}
        handleRemoveTag={getRemoveTagHandler(
          CALENDAR_FILTER_ENUM.clinics,
          f.value
        )}
        value={f.value}
        isReadOnly={isLoading}
      />
    );
  });

  const vbcTags = filters?.isVBC ? (
    <Tag
      type={CALENDAR_FILTER_ENUM.isVBC}
      label="VBC Only"
      handleRemoveTag={getRemoveTagHandler(CALENDAR_FILTER_ENUM.isVBC, 'true')}
      value={'true'}
      isReadOnly={isLoading}
    />
  ) : null;

  const insuranceCategoriesTags = filters?.insuranceCategories?.map(
    (f: any) => {
      return (
        <Tag
          type={CALENDAR_FILTER_ENUM.insuranceCategories}
          label={f.label}
          handleRemoveTag={getRemoveTagHandler(
            CALENDAR_FILTER_ENUM.insuranceCategories,
            f.value
          )}
          value={f.value}
          isReadOnly={isLoading}
        />
      );
    }
  );

  const insurancePayors = filters?.insurancePayors?.map((f: any) => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.insurancePayors}
        label={f.label}
        handleRemoveTag={getRemoveTagHandler(
          CALENDAR_FILTER_ENUM.insurancePayors,
          f.value
        )}
        value={f.value}
        isReadOnly={isLoading}
      />
    );
  });

  const insurancePlans = filters?.insurancePlans?.map((f: any) => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.insurancePlans}
        label={f.label}
        handleRemoveTag={getRemoveTagHandler(
          CALENDAR_FILTER_ENUM.insurancePlans,
          f.value
        )}
        value={f.value}
        isReadOnly={isLoading}
      />
    );
  });

  const programTags = filters.programs?.map(f => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.programs}
        label={f.label}
        handleRemoveTag={getRemoveTagHandler(
          CALENDAR_FILTER_ENUM.programs,
          f.value
        )}
        value={f.value}
        isReadOnly={isLoading}
      />
    );
  });

  const providerCertificationTags = filters.providerCertifications?.map(f => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.providerCertifications}
        label={f.label}
        handleRemoveTag={getRemoveTagHandler(
          CALENDAR_FILTER_ENUM.providerCertifications,
          f.value
        )}
        value={f.value}
        isReadOnly={isLoading}
      />
    );
  });

  const locationTags = filters?.locations?.map(f => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.locations}
        label={f.label}
        handleRemoveTag={getRemoveTagHandler(
          CALENDAR_FILTER_ENUM.locations,
          f.value
        )}
        value={f.value}
        isReadOnly={isLoading}
      />
    );
  });

  const departmentTags = filters?.departments?.map(f => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.departments}
        label={f.label}
        handleRemoveTag={getRemoveTagHandler(
          CALENDAR_FILTER_ENUM.departments,
          f.value
        )}
        value={f.value}
        isReadOnly={isLoading}
      />
    );
  });

  const specialitiesTags = filters?.specialities?.map(f => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.specialities}
        label={f.label}
        handleRemoveTag={getRemoveTagHandler(
          CALENDAR_FILTER_ENUM.specialities,
          f.value
        )}
        value={f.value}
        isReadOnly={isLoading}
      />
    );
  });

  const internalTags = (filters?.internals as internalFilters[])?.map(f => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.internals}
        label={f[0]?.toUpperCase() + f?.slice(1)}
        handleRemoveTag={handleRemoveTag}
        value={f}
        isReadOnly={isLoading}
      ></Tag>
    );
  });

  const statusTags = filters?.status?.map(f => {
    return (
      <Tag
        type={CALENDAR_FILTER_ENUM.status}
        label={f.label[0]?.toUpperCase() + f?.label.slice(1)}
        handleRemoveTag={getRemoveTagHandler(
          CALENDAR_FILTER_ENUM.status,
          f.value
        )}
        value={f.value}
        isReadOnly={isLoading}
      ></Tag>
    );
  });

  const onToggleModal = useCallback(() => {
    setEditFilterModal(!editFilterModal);
  }, [setEditFilterModal, editFilterModal]);

  const clearSelectedFilters = useCallback(() => {
    if (clearAllSelectedFilters) clearAllSelectedFilters();
    if (clearAdditionalFilter) clearAdditionalFilter();
  }, [clearAllSelectedFilters, clearAdditionalFilter]);
  return (
    <>
      <FilterTagListWrapper>
        {(!isEmptyObject(filters) ||
          filters?.isVBC ||
          additionalFilter?.isFilterApplied) &&
          showSavedIcon &&
          (!checkedFilter || checkedFilter === '' ? (
            <Icon name="bookmark outline" onClick={onToggleModal} />
          ) : (
            <Icon name="bookmark" className="saved_bookmark" />
          ))}
        <div className="tags-container">
          {locationTags} {clinicTags} {ServiceTypeTags} {therapyTypeTags}{' '}
          {vbcTags} {insuranceCategoriesTags} {insurancePayors} {insurancePlans}
          {statusTags}
          {smartSchedulingStatusTags} {waitListReason}
          {departmentTags} {specialitiesTags}
          {internalTags}
          {programTags}
          {corticaProgram}
          {providerCertificationTags}
          {clientAvailability}
          {language}
          {AdditionalFilterTag()}
          {(!isEmptyObject(filters) ||
            filters?.isVBC ||
            additionalFilter?.isFilterApplied) &&
            showClearFilters! && (
              <span className="clear-filters" onClick={clearSelectedFilters}>
                {' '}
                Clear Filters
              </span>
            )}
        </div>
      </FilterTagListWrapper>
      {editFilterModal && (
        <FilterTagForm
          pinnedFilter={filters}
          additionalFilter={additionalFilter}
          onClose={onToggleModal}
          pageName={pageName}
          setCheckedSavedFilter={setCheckedSavedFilter}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default FilterTagList;
