import { SmartScheduleLocation } from 'model/v2/location';
import { DRIVE_TIME } from 'utils/constants/default';
import { GENERIC_LOCATION_TYPES } from 'utils/constants/lists';
import {
  driveEndTimeIndex,
  driveStartTimeIndex,
  driveTimeIndex
} from 'utils/validators/smartSchedule';
import moment from 'moment';
import { IEvent, ISmartResultCard, TimeSlot, TimeSlots } from 'model/v2';
export const getPreferredLocation = (
  isInClinic?: boolean,
  isOffsite?: boolean,
  isTelehealth?: boolean
) => {
  if (isInClinic) return SmartScheduleLocation.InClinic;
  if (isOffsite) return SmartScheduleLocation.Offsite;
  if (isTelehealth) return SmartScheduleLocation.Telehealth;
  return undefined;
};

export const onSmartScheduleCardLocationDropdownChange = (
  location: string,
  index: number,
  setValue: (name: string, value: any, config?: any) => void,
  singleDT?: boolean
) => {
  if (location === SmartScheduleLocation.InClinic) {
    if (!singleDT) {
      setValue(`results[${index}].isInClinic`, true);
      setValue(`results[${index}].isOffsite`, false);
      setValue(`results[${index}].isTelehealth`, false);
    } else {
      setValue(`result.isInClinic`, true);
      setValue(`result.isOffsite`, false);
      setValue(`result.isTelehealth`, false);
    }
  }

  if (location === SmartScheduleLocation.Offsite) {
    if (!singleDT) {
      setValue(`results[${index}].isInClinic`, false);
      setValue(`results[${index}].isOffsite`, true);
      setValue(`results[${index}].isTelehealth`, false);
    } else {
      setValue(`result.isInClinic`, false);
      setValue(`result.isOffsite`, true);
      setValue(`result.isTelehealth`, false);
    }
  }

  if (location === SmartScheduleLocation.Telehealth) {
    if (!singleDT) {
      setValue(`results[${index}].isInClinic`, false);
      setValue(`results[${index}].isOffsite`, false);
      setValue(`results[${index}].isTelehealth`, true);
    } else {
      setValue(`result.isInClinic`, false);
      setValue(`result.isOffsite`, false);
      setValue(`result.isTelehealth`, true);
    }
  }
};

export const SetLocationCategory = (
  id: number,
  index: number,
  setValue: (name: string, value: any, config?: any) => void,
  singleDT?: boolean
) => {
  if (!singleDT) {
    setValue(`results[${index}].locationCategory`, id);
  } else {
    setValue(`locationCategory`, id);
  }
};

export const getInitialLocationValue = (allowedLocations: string[]) => {
  if (allowedLocations.includes(GENERIC_LOCATION_TYPES[0])) {
    return GENERIC_LOCATION_TYPES[0];
  } else if (allowedLocations.includes(GENERIC_LOCATION_TYPES[1])) {
    return GENERIC_LOCATION_TYPES[1];
  } else if (allowedLocations.includes(GENERIC_LOCATION_TYPES[2])) {
    return GENERIC_LOCATION_TYPES[2];
  } else {
    return GENERIC_LOCATION_TYPES[0];
  }
};

export const getAllowedLocationSingleDT = (
  isInClinic: boolean,
  isTelehealth: boolean,
  isOffsite: boolean
) => {
  let allowedLocationsList: string[] = [];
  if (isInClinic) {
    allowedLocationsList.push('in-clinic');
  }
  if (isOffsite) {
    allowedLocationsList.push('off-site');
  }
  if (isTelehealth) {
    allowedLocationsList.push('telehealth');
  }
  return allowedLocationsList;
};

export const onSmartScheduleCardDriveTimeSelected = (
  checked: boolean,
  index: number,
  setValue: (name: string, value: any, config?: any) => void
) => {
  if (checked) {
    setValue(driveTimeIndex(index), DRIVE_TIME);
  } else {
    setValue(driveTimeIndex(index), 0);
    setValue(driveStartTimeIndex(index), 0);
    setValue(driveEndTimeIndex(index), 0);
  }
};

export const getWeekStartAndEnd = (date: Date) => {
  const weekStartDate = moment(date)
    .startOf('week')
    .toDate();
  const weekEndDate = moment(date)
    .endOf('week')
    .toDate();

  return { weekStartDate, weekEndDate };
};

export const selectEventsBetweenWeek = (
  eventsMap: Record<number, IEvent[]>,
  searchDate: Date
) => {
  let { weekStartDate, weekEndDate } = getWeekStartAndEnd(searchDate!);
  const filteredEventsMap: Record<number, IEvent[]> = {};
  weekEndDate = moment(weekEndDate)
    .add(2, 'weeks')
    .toDate();
  Object.keys(eventsMap).forEach(key => {
    const events = eventsMap[parseInt(key)];

    const filteredEvents = events
      .filter(event => {
        const startDate = moment(event.startDate!).toDate();
        const endDate = moment(event.endDate!).toDate();
        return startDate >= weekStartDate && endDate <= weekEndDate;
      })
      .sort((a, b) => {
        const dateA = moment(a.startDate!).toDate();
        const dateB = moment(b.startDate!).toDate();
        return dateA.getTime() - dateB.getTime();
      });

    if (filteredEvents.length > 0) {
      filteredEventsMap[parseInt(key)] = filteredEvents;
    }
  });

  return filteredEventsMap;
};
export const abaFilterOverlappingTimeRanges = (
  firstWeekUnavailableEvents: Record<number, IEvent[]>,
  smartResultCards: ISmartResultCard[]
) => {
  return smartResultCards.map(card => {
    const providerEvents = firstWeekUnavailableEvents[card.provider?.id!];

    if (!providerEvents || providerEvents!.length === 0) return card;
    const adjustedTimeRanges = card.timeRanges!.map(range => {
      const adjustTimeSlots = (
        slots: TimeSlot[],
        effectiveStartDate: string
      ) => {
        let adjustedSlots: TimeSlot[] = [];
        slots.forEach(slot => {
          let currentSlot: TimeSlot | null = { ...slot };
          providerEvents.forEach(event => {
            if (!currentSlot) return;
            const eventStart = moment(event.startDate);
            const eventEnd = moment(event.endDate);
            const slotStart = moment(currentSlot.startTime);
            const slotEnd = moment(currentSlot.endTime);
            if (
              (slotStart.isSameOrAfter(eventStart) &&
                slotEnd.isSameOrBefore(eventEnd)) ||
              (slotStart.isBefore(eventStart) && slotEnd.isAfter(eventEnd)) ||
              (slotStart.isBefore(eventStart) && slotEnd.isAfter(eventStart)) ||
              (slotStart.isBefore(eventEnd) && slotEnd.isAfter(eventEnd))
            ) {
              effectiveStartDate = moment(effectiveStartDate)
                .add(1, 'week')
                .toISOString();
              currentSlot = {
                startTime: slotStart.add(1, 'week').toISOString(),
                endTime: slotEnd.add(1, 'week').toISOString(),
                adjacentType: 0
              };
              return;
            }
          });
          if (currentSlot) adjustedSlots.push(currentSlot);
        });
        return { adjustedSlots, effectiveStartDate };
      };
      const fullProviderRanges = adjustTimeSlots(
        range.fullProviderRanges,
        range.effectiveStartDate
      );
      const clientAvailableRanges = adjustTimeSlots(
        range.clientAvailableRanges,
        range.effectiveStartDate
      );
      return {
        ...range,
        effectiveStartDate: fullProviderRanges.effectiveStartDate,
        fullProviderRanges: fullProviderRanges.adjustedSlots,
        clientAvailableRanges: clientAvailableRanges.adjustedSlots
      };
    });

    return {
      ...card,
      timeRanges: adjustedTimeRanges
    };
  });
};

export const dtFilterOverlappingTimeRanges = (
  firstWeekUnavailableEvents: Record<number, IEvent[]>,
  smartResultCards: ISmartResultCard[]
) => {
  return smartResultCards.map(card => {
    const providerEvents = firstWeekUnavailableEvents[card.provider?.id!];
    if (!providerEvents || providerEvents!.length === 0) return card;
    const adjustTimeSlots = (slots: TimeSlots[]) => {
      let adjustedSlots: TimeSlots[] = [];
      slots.forEach(slot => {
        let currentSlot: TimeSlots | null = slot;

        providerEvents.forEach(event => {
          if (!currentSlot) return;
          const eventStart = moment(event.startDate);
          const eventEnd = moment(event.endDate);
          const slotStart = moment(currentSlot.timeSlot.startTime);
          const slotEnd = moment(currentSlot.timeSlot.endTime);
          if (
            (slotStart.isSameOrAfter(eventStart) &&
              slotEnd.isSameOrBefore(eventEnd)) ||
            (slotStart.isBefore(eventStart) && slotEnd.isAfter(eventEnd)) ||
            (slotStart.isBefore(eventStart) && slotEnd.isAfter(eventStart)) ||
            (slotStart.isBefore(eventEnd) && slotEnd.isAfter(eventEnd))
          ) {
            currentSlot = {
              ...slot,
              effectiveStartDate: moment(currentSlot.effectiveStartDate)
                .add(1, 'week')
                .toISOString(),
              timeSlot: {
                ...slot.timeSlot,
                startTime: slotStart.add(1, 'week').toISOString(),
                endTime: slotEnd.add(1, 'week').toISOString()
              }
            };
            return;
          }
        });
        if (currentSlot) adjustedSlots.push(currentSlot);
      });
      return adjustedSlots;
    };

    return {
      ...card,
      timeSlots: adjustTimeSlots(card.timeSlots!)
    };
  });
};
