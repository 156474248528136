import React, { ChangeEvent, useCallback, useRef, useState } from 'react';

// import Loader from '../../../ui/content/Loader';
import Loader from '../../../../src/views/components/ui/content/Loader';
import { CheckListWrapper } from 'components/calendar/filters/style';
import { default as ProviderClinicFilter } from 'views/components/waitlistPage/components/filters/mainFilter';
import { waitListFilterHelper } from 'helpers/filterHekper';
import WaitListCommonFilter from 'views/components/waitlistPage/components/filters/waitListCommonFilter';
import FilterListFilled from 'assets/img/FilterListFilled.png';
import {
  FilterPlaceHolder,
  ICalendarFilterData,
  ICalendarFilterOption,
  ProviderFilterType
} from 'model/calendar/filters';
import { PROVIDER_STATUS_LIST } from 'model/v2/providers';
import { programTypeFilter } from 'utils/common';
import ProviderDepartmentFilter from './filter/DepartmentFilter';

interface Props {
  isLoading: boolean;
  FilterData: ICalendarFilterData;
  checkedClinics: ICalendarFilterOption[];
  setCheckedClinics: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  checkedCertifications: ICalendarFilterOption[];
  setCheckedCertifications: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  checkedPrograms: ICalendarFilterOption[];
  setCheckedPrograms: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  checkedDepartments: ICalendarFilterOption[];
  checkedStatus: ICalendarFilterOption[];
  setCheckedStatus: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  setCheckedDepartments: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  checkedSpecialities: ICalendarFilterOption[];
  setCheckedSpecialities: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  setIsShowDrawer: React.Dispatch<React.SetStateAction<Boolean>>;
  children: () => {
    ClientSearchWrapper: JSX.Element;
    ProviderSearchWrapper: JSX.Element;
  };
  setCheckedLanguage: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  checkedLanguage: ICalendarFilterOption[];
}
const ProviderFilterLayout = ({
  isLoading,
  FilterData,
  checkedClinics,
  setCheckedClinics,
  checkedCertifications,
  setCheckedCertifications,
  checkedPrograms,
  setCheckedPrograms,
  checkedStatus,
  setCheckedStatus,
  checkedDepartments,
  setCheckedDepartments,
  checkedSpecialities,
  setCheckedSpecialities,
  setIsShowDrawer,
  children,
  setCheckedLanguage,
  checkedLanguage
}: Props) => {
  const clinicFiltersRef = useRef<HTMLDivElement>(null);
  const certificationsFiltersRef = useRef<HTMLDivElement>(null);
  const specialtyFiltersRef = useRef<HTMLDivElement>(null);
  const statusFiltersRef = useRef<HTMLDivElement>(null);
  const programsRef = useRef<HTMLDivElement>(null);
  const languageRef = useRef<HTMLDivElement>(null);

  const [hasClinics, setHasClinics] = useState(true);
  const [hasCertifications, setHasCertifications] = useState(true);
  const [hasPrograms, setHasPrograms] = useState(true);
  const [hasStatus, setHasStatus] = useState(true);
  const [hasSpecialties, setHasSpecialties] = useState(true);
  const [hasLanguage, setHasLanguage] = useState(true);

  let { ClientSearchWrapper, ProviderSearchWrapper } = children();

  const filterRefs: any = {
    specialty: specialtyFiltersRef,
    clinic: clinicFiltersRef,
    providerCertifications: certificationsFiltersRef,
    status: statusFiltersRef,
    programs: programsRef,
    language: languageRef
  };

  const [searchValue, setSearchValue] = useState<Record<string, string>>({
    clinic: '',
    providerCertifications: '',
    specialty: '',
    status: '',
    programs: '',
    language: ''
  });

  const [filterDropdownExpand, setFilterDropdownExpand] = useState<
    Record<string, boolean>
  >({
    clinic: false,
    providerCertifications: false,
    specialty: false,
    status: false,
    programs: false
  });

  const toggleFilterList = (type: string) => {
    setFilterDropdownExpand({
      ...filterDropdownExpand,
      [type]: !filterDropdownExpand[type]
    });
  };

  const handleGenericBlur = (
    e: React.FocusEvent<HTMLDivElement>,
    filterType: string
  ) => {
    const currentRef = filterRefs[filterType];
    if (
      currentRef.current &&
      (e.relatedTarget === currentRef.current ||
        currentRef.current.contains(e.relatedTarget))
    ) {
      return; // Do nothing if the click was inside the dropdown
    }

    if (filterDropdownExpand[filterType]) {
      setFilterDropdownExpand((prevState: Record<string, boolean>) => ({
        ...prevState,
        [filterType]: false
      }));
    }
  };

  const handleGenericSearch = (
    _e: ChangeEvent<HTMLInputElement>,
    data: { value: any },
    typeOfSearch: string
  ) => {
    const isSupportedSearchType = ['clinic'].includes(typeOfSearch);

    if (isSupportedSearchType) {
      setFilterDropdownExpand((prevState: Record<string, boolean>) => ({
        ...prevState,
        [typeOfSearch]: true
      }));

      setSearchValue((prevState: Record<string, string | number>) => ({
        ...prevState,
        [typeOfSearch]: data?.value
      }));
    }
  };
  //Base handleSearchedValues method
  const createHandleSearchedValues = useCallback(
    (type: string) => {
      //if(setCheckedSavedFilter) setCheckedSavedFilter('');
      return (list: ICalendarFilterOption[]) => {
        const searchTerm = searchValue[type];
        if (type !== 'programs')
          return searchTerm === ''
            ? list
            : list?.filter((option: ICalendarFilterOption) => {
                return option?.label
                  ?.toLowerCase()
                  .startsWith(searchTerm?.toLowerCase());
              });
        else {
          let datas =
            searchTerm === ''
              ? list.map((data: ICalendarFilterOption) =>
                  programTypeFilter(FilterData, data)
                )
              : list
                  ?.filter((option: ICalendarFilterOption) => {
                    return option?.label
                      ?.toLowerCase()
                      .startsWith(searchTerm?.toLowerCase());
                  })
                  .map((data: ICalendarFilterOption) =>
                    programTypeFilter(FilterData, data)
                  );
          return datas;
        }
      };
    },
    [searchValue, FilterData]
  );

  //Creates a handleSearchedValues for each type of search, which is passed through props to the child components of each type (ex. activeStatusFilter)
  const handleClinicSearchedValues = createHandleSearchedValues('clinic');
  const handleSpecialtySearchedValues = createHandleSearchedValues('specialty');
  const handleStatusSearchedValues = createHandleSearchedValues('status');
  const handleProgramsSearchedValue = createHandleSearchedValues('programs');
  const handleLanguageSearchedValues = createHandleSearchedValues('language');
  const handleCertificationsSearchedValue = createHandleSearchedValues(
    'providerCertifications'
  );

  const clearFilter = useCallback((clearFilter, departmentFilter?) => {
    if (clearFilter)
      clearFilter((prev: ICalendarFilterOption[]) => (prev.length ? [] : prev));
    if (departmentFilter)
      departmentFilter((prev: ICalendarFilterOption[]) =>
        prev.length ? [] : prev
      );
  }, []);
  const waitListMappedFilters = useCallback(
    type => {
      if (type === 'status' && FilterData) {
        const filterStatusData = Object.entries(PROVIDER_STATUS_LIST).map(
          ([_, value]) => ({
            id: value,
            title: value
          })
        );
        FilterData!.status = filterStatusData;
      }
      if (FilterData) {
        const data = waitListFilterHelper(type, FilterData);
        return data;
      }
    },
    [FilterData]
  );
  return (
    <div className="dropdown-wrapper provider-dropdown-wrapper">
      <CheckListWrapper
        visible={filterDropdownExpand.therapyType}
        height="300px"
        style={{ position: 'relative' }}
      >
        <ProviderClinicFilter
          clinicFiltersRef={clinicFiltersRef}
          handleGenericBlur={handleGenericBlur}
          toggleFilterList={toggleFilterList}
          filterDropdownExpand={filterDropdownExpand}
          handleGenericSearch={handleGenericSearch}
          searchValue={searchValue}
          hasClinics={hasClinics}
          isLoading={isLoading}
          FilterData={FilterData?.clinicOptionsList!}
          handleClinicSearchedValues={handleClinicSearchedValues}
          checkedClinics={checkedClinics}
          setCheckedClinics={setCheckedClinics}
          setHasClinics={setHasClinics}
          clearFilter={clearFilter}
          Loader={Loader}
          type={ProviderFilterType.clinic}
        />
      </CheckListWrapper>
      <CheckListWrapper
        visible={filterDropdownExpand.therapyType}
        height="300px"
        style={{ position: 'relative' }}
      >
        <ProviderClinicFilter
          clinicFiltersRef={languageRef}
          handleGenericBlur={handleGenericBlur}
          toggleFilterList={toggleFilterList}
          filterDropdownExpand={filterDropdownExpand}
          handleGenericSearch={handleGenericSearch}
          searchValue={searchValue}
          hasClinics={hasLanguage}
          isLoading={isLoading}
          FilterData={FilterData?.languages!}
          handleClinicSearchedValues={handleLanguageSearchedValues}
          checkedClinics={checkedLanguage}
          setCheckedClinics={setCheckedLanguage!}
          setHasClinics={setHasLanguage}
          clearFilter={clearFilter}
          Loader={Loader}
          type={ProviderFilterType.language}
          placeHolder={FilterPlaceHolder.language}
        />
      </CheckListWrapper>
      <ProviderDepartmentFilter
        filterDropdownExpand={filterDropdownExpand}
        specialtyFiltersRef={specialtyFiltersRef}
        handleGenericBlur={handleGenericBlur}
        toggleSpecialtyList={toggleFilterList}
        handleGenericSearch={handleGenericSearch}
        searchValue={searchValue}
        hasSpecialties={hasSpecialties}
        IsLoading={isLoading}
        Loader={Loader}
        calendarFilterData={FilterData}
        handleSpecialtySearchedValues={handleSpecialtySearchedValues}
        checkedSpecialities={checkedSpecialities}
        checkedDepartments={checkedDepartments}
        setCheckedSpecialities={setCheckedSpecialities}
        setCheckedDepartments={setCheckedDepartments}
        setHasSpecialties={setHasSpecialties}
        clearSpecialty={clearFilter}
      />
      <WaitListCommonFilter
        filterDropdownExpand={filterDropdownExpand}
        filterTypeRefs={statusFiltersRef}
        handleGenericBlur={handleGenericBlur}
        toggleFilterList={toggleFilterList}
        handleGenericSearch={handleGenericSearch}
        hasFilterOptions={hasStatus}
        searchValue={searchValue}
        isLoading={isLoading}
        filterValueMappings={waitListMappedFilters}
        handleSerachedValues={handleStatusSearchedValues}
        checkedFilterType={checkedStatus}
        setFilterType={setCheckedStatus}
        setHasFilterOptions={setHasStatus}
        clearFilter={clearFilter}
        Loader={Loader}
        title={'Status'}
        placeHolder={'Status'}
        blurTitle={'status'}
        handleChangeType={'status'}
      />
      <WaitListCommonFilter
        filterDropdownExpand={filterDropdownExpand}
        filterTypeRefs={programsRef}
        handleGenericBlur={handleGenericBlur}
        toggleFilterList={toggleFilterList}
        handleGenericSearch={handleGenericSearch}
        hasFilterOptions={hasPrograms}
        searchValue={searchValue}
        isLoading={isLoading}
        filterValueMappings={waitListMappedFilters}
        handleSerachedValues={handleProgramsSearchedValue}
        checkedFilterType={checkedPrograms}
        setFilterType={setCheckedPrograms}
        setHasFilterOptions={setHasPrograms}
        clearFilter={clearFilter}
        Loader={Loader}
        title={'Cortica Program'}
        placeHolder={'Cortica Programs'}
        blurTitle={'programs'}
        handleChangeType={'corticaProgram'}
      />
      <WaitListCommonFilter
        filterDropdownExpand={filterDropdownExpand}
        filterTypeRefs={certificationsFiltersRef}
        handleGenericBlur={handleGenericBlur}
        toggleFilterList={toggleFilterList}
        handleGenericSearch={handleGenericSearch}
        hasFilterOptions={hasCertifications}
        searchValue={searchValue}
        isLoading={isLoading}
        filterValueMappings={waitListMappedFilters}
        handleSerachedValues={handleCertificationsSearchedValue}
        checkedFilterType={checkedCertifications}
        setFilterType={setCheckedCertifications}
        setHasFilterOptions={setHasCertifications}
        clearFilter={clearFilter}
        Loader={Loader}
        title={'Certifications'}
        placeHolder={'Certification'}
        blurTitle={'providerCertifications'}
        handleChangeType={'providerCertifications'}
      />
      {ClientSearchWrapper}
      {ProviderSearchWrapper}

      <div>
        <div
          className="Additional_Filter_Layout"
          onClick={() => setIsShowDrawer(true)}
        >
          <img src={FilterListFilled}></img>
          <div className="Additional_Filter_Title">
            <span>
              <u>More Filters</u>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderFilterLayout;
