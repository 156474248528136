import {
  formatClinicName,
  formatAddress,
  formatClinicAbbreviation
} from 'utils/format';
import { NO_PREFERENCE_OPTION } from 'utils/constants/default';
import moment from 'moment-timezone';
import { alphabeticalSort } from 'utils/sort';
import { CLINIC_PROVIDERS } from 'utils/constants/lists';
export const mapToOption = array =>
  array?.map(item => {
    return {
      value: item.id,
      label: item.value
    };
  });

export const mapToDropDownOption = array =>
  array.map(item => ({
    key: item.id,
    value: item.id,
    text: item.value
  }));

export const mapToValuedOption = array =>
  array?.map(({ id, title }) => {
    return {
      value: id,
      label: title
    };
  });

export const mapToLocationsDownOption = array =>
  array.map(item => ({
    value: item.id,
    label: item.title,
    color: item.color,
    borderColor: item.borderColor
  }));
export const mapToOptionClinic = array =>
  array.map(item => {
    return {
      value: item.id,
      label: formatClinicName(item.value)
    };
  });

export const mapToAppointmentOption = array =>
  array.map(({ title, id }) => ({
    value: id,
    label: title
  }));

export const toProviderOption = (
  providersList,
  appTypeTitle,
  clinicsMap,
  clinicPreference = CLINIC_PROVIDERS[0].id,
  smart
) => {
  if (providersList?.length === 0) {
    return [];
  }

  return (clinicPreference === CLINIC_PROVIDERS[0].id && smart // add no preference if home clinic
    ? [NO_PREFERENCE_OPTION, ...providersList]
    : providersList
  ).map(p => {
    const clinicAbbreviation =
      clinicPreference !== CLINIC_PROVIDERS[0].id
        ? clinicsMap.get(p.clinicId)?.clinicAbbreviation
        : null;

    const label = `${p.displayName}${appTypeTitle ? ` - ${appTypeTitle}` : ''}${
      clinicAbbreviation ? `, ${clinicAbbreviation}` : ''
    }`;
    return {
      value: p.id,
      label: p.id === NO_PREFERENCE_OPTION.id ? p.value : label
    };
  });
};

export const mapToRecurringAppointment = (openings, events, weekNum = 1) => {
  const providersEvents = {};
  events.forEach(e => {
    const key = e.therapist && e.therapist.id;
    if (key && providersEvents[key]) {
      providersEvents[key].push({ start: e.start, end: e.end });
    } else {
      providersEvents[key] = [{ start: e.start, end: e.end }];
    }
  });

  return openings.map(o => {
    let openingsAvailableNum = 0;
    let totalRecurringAppointments = 0;

    const providerEvents = providersEvents[o.provider.id] || [];
    const endOfQuarter = moment(o.start).endOf('quarter');

    const start = moment(o.start);
    const end = moment(o.end);

    while (start.isBefore(endOfQuarter) && end.isBefore(endOfQuarter)) {
      if (
        providerEvents &&
        providerEvents.every(
          e =>
            !start.isBetween(e.start, e.end) && !end.isBetween(e.start, e.end)
        )
      ) {
        openingsAvailableNum += 1;
      }
      totalRecurringAppointments += 1;

      start.add(weekNum || 1, 'week');
      end.add(weekNum || 1, 'week');
    }
    return { ...o, openingsAvailableNum, totalRecurringAppointments };
  });
};

export const mapStringArrayToOptions = array =>
  array?.map(string => ({
    value: string,
    label: string
  }));

export const getMergedApptTypes = (appointmentTypes, appointmentABATypes) =>
  appointmentTypes
    .concat(appointmentABATypes)
    .sort((appt1, appt2) => alphabeticalSort(appt1.title, appt2.title));

export const getApptTypesForm = (appointmentTypes, appointmentABATypes) => {
  const apptTypes = getMergedApptTypes(appointmentTypes, appointmentABATypes);
  return {
    apptTypes,
    apptTypesOptions: mapToAppointmentOption(apptTypes)
  };
};

export const mapToAddressOption = array =>
  array.map(item => {
    return {
      value: item.id,
      label: formatAddress(item)
    };
  });

export const mapCityDataToOptions = cityData =>
  cityData.map(({ address }) => {
    return {
      value: address.formattedAddress,
      label: address.formattedAddress
    };
  });

export const mapAppointmentTitlesToOptions = array =>
  array.map(item => {
    return {
      value: item.id.toString(),
      label: item.title
    };
  });

export const mapValueToOptions = array =>
  array.map(({ value }) => ({
    value: value,
    label: value
  }));

export const mapToOptions = (list, uppercaseFlag = false) =>
  list.map(item => ({
    value: item,
    label:
      uppercaseFlag && item.length >= 0
        ? item[0].toUpperCase() + item.slice(1)
        : item
  }));

export const mapToFilterOptions = (list, uppercaseFlag = false) => {
  return list.map(item => ({
    value: item.selectedId,
    label:
      uppercaseFlag && item.selectedId?.length > 0
        ? item.selectedId[0].toUpperCase() + item.selectedId.slice(1)
        : item.selectedId
  }));
};

export const mapBooleanToFilterOptions = isVBC => {
  if (!isVBC || isVBC.length === 0) {
    return false;
  }
  return isVBC[0].selectedId === 'true';
};

export const mapReasonsToOptionsValues = list => {
  list.sort((a, b) => a.value.localeCompare(b.value, 'en'));
  return list.map(item => ({ value: item.id, label: item.value }));
};

export const mapToCheckListOptions = (list, valueFieldName, labelFieldName) => {
  return list?.map(item => ({
    value: item[valueFieldName],
    label: item[labelFieldName]
  }));
};
export const mapTitleToOptions = list =>
  list?.map(item => ({ value: item.id, label: item.title }));

export const mapClinicsToOptionsv1 = list =>
  list.map(item => ({
    value: item.id,
    label: formatClinicAbbreviation(item, 'name', 'abbreviation')
  }));

export const mapProgramsToOptions = list =>
  list.map(item => ({
    value: item.id,
    label: item.programName
  }));

export const mapProviderCertificationsToOptions = list =>
  list.map(item => ({
    value: item.id,
    label: item.certificationName
  }));

export const mapToSpecialityOptions = list =>
  list?.map(item => ({
    value: item?.specialities?.id,
    label: item?.specialities?.abbreviation
  }));

export const mapClinicsHookToOptions = list =>
  list?.map(item => ({
    value: item?.clinics?.id,
    label: formatClinicAbbreviation(item?.clinics, 'name', 'abbreviation')
  }));

export const mapAppointmentTypesToOptions = list =>
  list?.map(item => ({
    value: item.id,
    label: item.title
  }));

export const mapToCalendarSearchOptions = array =>
  array.map(value => ({
    value: value.id,
    label: value.displayName
  }));

export const mapCorticaProgramOptions = data => {
  if (!data?.corticaPrograms) return [];
  else
    return data.corticaPrograms.map(item => ({
      value: item.id,
      label: item.programName,
      disabled: item.externallyControlled
    }));
};

export const mapCertificationOptions = (
  allCertOptions,
  providerCertificationMappings
) => {
  if (!allCertOptions || !providerCertificationMappings) return [];
  else {
    return allCertOptions.map(item => {
      const mappedCert = providerCertificationMappings.find(
        providerCert =>
          item.certificationName ===
          providerCert.certification.certificationName
      );
      return {
        value: item.id,
        certificationName: item.certificationName,
        date: mappedCert
          ? `${moment
              .tz(mappedCert.renewalDate, 'America/Los_Angeles')
              .format('MM/DD/YYYY')}`
          : ''
      };
    });
  }
};

export const mapCorticaProgramOptionsSavedFilters = data => {
  if (data && data.length > 0) {
    return data.map(item => ({
      value: parseInt(item?.programs?.id),
      label: item?.programs?.programName
    }));
  } else {
    return [];
  }
};

export const mapProviderCertificationsOptionsSavedFilters = data => {
  if (data && data.length > 0) {
    return data.map(item => ({
      value: parseInt(item?.providerCertifications?.id),
      label: item?.providerCertifications?.certificationName
    }));
  } else {
    return [];
  }
};

export const mapRoomIdToFullData = room =>
  room
    ? `${room.name}: Seats ${room.seats}${room.hasTV ? ',TV' : ''}${
        room.hasInstruments ? ',Instruments' : ''
      }${room.hasPhone ? ',Phone' : ''}`
    : '';

export const mapRoomsToOptions = list =>
  list.map(item => ({ label: mapRoomIdToFullData(item), value: item.id }));
export const mapFromOption = array => {
  const options = [];
  array.forEach(item => {
    options.push(item.value);
  });
  return options;
};

export const mapCalendarFilters = filters => ({
  clinics: mapFromOption(filters.clinics),
  locations: mapFromOption(filters.locations),
  departments: mapFromOption(filters.departments),
  specialities: mapFromOption(filters.specialities),
  internals: filters.internals
});

export const mapClinicsToOptions = list =>
  list.map(item => ({
    value: item.id,
    label: formatClinicAbbreviation(item, 'name', 'abbreviation')
  }));

export const mapAssistantsToOptions = list =>
  list?.map(item => ({
    value: item.id,
    label: item.firstName + ' ' + item.lastName
  }));
