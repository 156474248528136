import { TableType } from 'model/tableType';

export interface ITableColumn {
  label: string;
  key: string;
  notWrap?: boolean;
  comp?: ITableComp;
}
export interface ITableRow {
  [key: string]: any;
}

export interface ITableComp {
  sort: (a: TableType, b: TableType) => number;
  reverse: (a: TableType, b: TableType) => number;
}
export enum TableSortDirection {
  descending = 'descending',
  ascending = 'ascending'
}

export enum WaitlistSortOrders {
  AVAIL_ASC = 'AVAIL_ASC',
  AVAIL_DESC = 'AVAIL_DESC',
  WAITLIST_ASC = 'WAITLIST_ASC',
  WAITLIST_DESC = 'WAITLIST_DESC'
}

export enum WaitlistSortColumns {
  WAITLIST_DATE = 'waitlist',
  CLIENT_AVAIL_UPDATED_DATE = 'availDate'
}
