import gql from 'graphql-tag';

export const GET_SCHEDULER_FILTERS = gql`
  query($id: String!, $pageName: String!) {
    getAllFiltersWithUserId(id: $id, pageName: $pageName) {
      id
      name
      userId
      isDefault
      filterSelections {
        id
        selectedId
        clinics {
          id
          name
          abbreviation
        }
        specialities {
          id
          title
          abbreviation
        }
        programs {
          id
          programName
        }
        type {
          id
          typeName
        }
      }
    }
  }
`;

export const GET_CALENDAR_FILTERS = gql`
  query {
    calenderFilters {
      clinicOptionsList {
        id
        name
        abbreviation
      }
      eventFilterStatus {
        id
        value
      }
      specialties {
        id
        abbreviation
        department
        title
      }
      corticaProgram {
        id
        programName
      }
      providerCertifications {
        id
        certificationName
      }
      specialtiesDepartment
    }
  }
`;
export const CARE_PLAN_FILTERS = gql`
  query {
    WaitListFilter {
      clinicOptionsList {
        abbreviation
        name
        id
      }
      waitListAvailabilityTime {
        title
      }
      waitListReasons {
        title
      }
      waitListStatus {
        title
      }
      waitListTheraphyTypies {
        title
      }
      waitListServices {
        title
      }
    }
  }
`;

export const GET_FILTERED_PROVIDERS = gql`
  query(
    $clinics: [Int!]
    $programs: [Int!]
    $departments: [String!]
    $specialities: [Int!]
    $providerCertifications: [Int!]
  ) {
    filteredProviders(
      clinics: $clinics
      programs: $programs
      departments: $departments
      specialities: $specialities
      providerCertifications: $providerCertifications
    ) {
      id
      email
      firstName
      lastName
      displayName
      name
      clinic {
        id
        name
        abbreviation
      }
      attendeeType
      speciality {
        color
        id
        abbreviation
        department
      }
      appointmentTypes {
        id
        title
        eventType {
          id
          name
        }
      }
      corticaPrograms {
        id
        programName
      }
      certificationMappings {
        id
        certificationDate
        renewalDate
        certification {
          id
          certificationName
        }
      }
    }
  }
`;

export const GET_FILTER_SELECTION_TYPE = gql`
  query {
    getFilterSelectionType {
      id
      typeName
    }
  }
`;
