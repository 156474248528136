import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { ICalendarFilterOption } from 'model/calendar/filters';
import { mapProviderCertificationsToOptions } from 'utils/mappers/form';
import CalendarFilter from '../../../ui/calendarFilter';
import { ProviderCertification } from 'model/v2/certifications';

interface Props {
  providerCertificationsList: ProviderCertification[];
  handleSearchedValues: (
    list: ICalendarFilterOption[]
  ) => ICalendarFilterOption[];
  checkedProviderCertifications: ICalendarFilterOption[];
  setCheckedProviderCertifications: Dispatch<
    SetStateAction<ICalendarFilterOption[]>
  >;
  setHasData: Dispatch<SetStateAction<boolean>>;
}

const ProviderCertificationFilter = ({
  providerCertificationsList,
  handleSearchedValues,
  checkedProviderCertifications,
  setCheckedProviderCertifications,
  setHasData
}: Props) => {
  const providerCertifications = useMemo(
    () =>
      providerCertificationsList
        ? mapProviderCertificationsToOptions(providerCertificationsList)
        : [],

    [providerCertificationsList]
  );

  const searchedProviderCertifications = useMemo(() => {
    const searchedValues = handleSearchedValues(providerCertifications);

    return searchedValues;
  }, [handleSearchedValues, providerCertifications]);

  useEffect(() => {
    searchedProviderCertifications?.length === 0
      ? setHasData(false)
      : setHasData(true);
  }, [searchedProviderCertifications, setHasData]);

  return (
    <>
      <CalendarFilter
        setCheckedList={setCheckedProviderCertifications}
        checkedList={checkedProviderCertifications}
        listOptions={searchedProviderCertifications}
        length={providerCertifications.length}
        title="Certifications"
      />
    </>
  );
};

export default React.memo(ProviderCertificationFilter);
