import { clean, getApptType } from 'utils/common';
import { prepareEventForSubmission } from 'utils/mappers/request/events';
import {
  GET_CALENDAR_EVENTS,
  GET_FULL_EVENT
} from 'api/graphql/queries/events';
import { views } from 'react-big-calendar/lib/utils/constants';
import moment from 'moment';

export function getSubmissionEvent(
  appointment,
  client,
  apptTypes,
  clinicId,
  eventTypes,
  clinics
) {
  const currentClinic = clinics.find(c => c.id === clinicId);

  return clean(
    prepareEventForSubmission(
      appointment,
      client,
      apptTypes,
      currentClinic,
      eventTypes
    )
  );
}

export const getRefetchQueries = (selectedSlot, id = null) => {
  const refetch = [
    {
      query: GET_CALENDAR_EVENTS,
      variables: {
        startTime: moment(selectedSlot.start)
          .utc(true)
          .startOf('day')
          .toISOString(),
        view: views.DAY
      }
    },
    {
      query: GET_CALENDAR_EVENTS,
      variables: {
        startTime: moment(selectedSlot.start)
          .utc(true)
          .startOf('week')
          .startOf('day')
          .toISOString(),
        view: views.WEEK
      }
    },
    {
      query: GET_CALENDAR_EVENTS,
      variables: {
        startTime: moment(selectedSlot.start)
          .utc(true)
          .startOf('month')
          .startOf('day')
          .toISOString(),
        view: views.MONTH
      }
    }
  ];
  if (id) {
    refetch.push({
      query: GET_FULL_EVENT,
      variables: { id }
    });
  }
  return refetch;
};

export function getConflictsVariables(formData, eventMeta, client, apptTypes) {
  const apptType = getApptType({ title: formData.appType }, apptTypes);

  return {
    userId: formData.selectedSlot.providerId,
    reschedule: !!formData.id,
    recurring: !!formData.id
      ? eventMeta.thisAndFollowingEvents
      : formData.repeat > 0,
    timeZone: formData.currentClinic.timeZone,
    clientID: client.id,
    event: {
      id: formData.id,
      masterID: formData.masterID,
      startDate: formData.selectedSlot.start,
      recurringEvery: formData.repeat || 0,
      endDate: formData.selectedSlot.end,
      recurringUntil: formData.recurringUntil,
      duration: formData.duration,
      appointmentType: apptType.id,
      isClinical: apptType.isClinical,
      authorizedTherapyID: eventMeta.authorizedTherapyID
    }
  };
}
