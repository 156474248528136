import { getEventDayIndex, getPlannerWeekTime } from 'helpers/calendarHelper';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { getTimeSlots } from 'time-slots-generator';
import { EventType } from 'model/calendar/events';
import { calendarGroup } from 'model/calendar/groups';
import {
  clientCalendarPrefDayVar,
  mainCalendarPrefDayVar,
  views
} from 'utils/cache/calendar';
import {
  getApptType,
  getTzOffsetInMinutes,
  getWebClientTzString
} from 'utils/common';
import { modalForList } from 'utils/constants/breadcrumbs';
import { MAX_CALENDAR_TIME, MIN_CALENDAR_TIME } from 'utils/constants/calendar';
import { internalFilters } from 'utils/constants/calendarFilters';
import { LOCATION_DEFAULT_COUNTRY } from 'utils/constants/default';
import {
  CLINIC_PROVIDERS,
  LOCATION_TYPE_LIST,
  PAYMENT_METHODS,
  SCHEDULE_TYPE_LIST
} from 'utils/constants/lists';
import { EVENT_STATUS } from 'utils/constants/status';
import {
  APPOINTMENT_BACKGROUND_COLORS,
  APPOINTMENT_HEADER_COLORS
} from 'utils/constants/theme';
import { formatUsernameOriginal, parseUsername } from 'utils/format';
import { isDisabledMovement } from 'utils/validators/dragEventValidator';
import { v4 } from 'uuid';

function getInitDate(sourceCalendar) {
  const defaultDate = moment()
    .startOf('day')
    .toDate();
  switch (sourceCalendar) {
    case modalForList.mainCalendar:
      return (
        mainCalendarPrefDayVar().toISOString() || defaultDate.toISOString()
      );
    case modalForList.clientCalendar:
      return (
        clientCalendarPrefDayVar().toISOString() || defaultDate.toISOString()
      );
    default:
      return defaultDate.toISOString();
  }
}

export function getOpeningID(opening) {
  return `${opening.providerId}-${opening.start}`;
}

export function getEventInitialValues(
  event,
  apptTypes,
  sourceCalendar,
  client
) {
  const appointmentForm = {
    id: event?.id || null,
    clinicId: event?.clinic?.id || client?.clinicId || null,
    masterID: event?.masterID || null,
    isClinical: event?.appointmentType?.isClinical || null,
    appSubType: event?.appointmentType?.subType || null,
    appType: event?.appointmentType?.id || null,
    duration: event?.duration ? parseInt(event?.duration) : 0,
    scheduleType:
      event?.recurringEvery > 0
        ? SCHEDULE_TYPE_LIST[1].id
        : SCHEDULE_TYPE_LIST[0].id,
    startDate: event?.startTime || null,
    endDate: event?.endTime || null,
    provider: event?.therapist?.id || null,
    selectedDate: getInitDate(sourceCalendar),
    selectedSlot: null,
    selectedRoom: event?.room?.id
      ? {
          id: event.room.id,
          roomName: event.room.roomName,
          email: event.room.email
        }
      : null,
    repeat: event?.recurringEvery || null,
    telehealthLink: event?.telehealthLink,
    notes: event?.notes || '',
    recurringUntil: event?.recurringUntil
      ? moment(event?.recurringUntil)
      : null,
    clinicPreference: event?.clinicPreference || CLINIC_PROVIDERS[0].id,
    paymentMethod: event?.paymentMethod || PAYMENT_METHODS[0].id,
    locationType: event?.locationType || null,
    locationCategory: event?.locationCategory || null,
    timezone: event?.clinic.timezone || null
  };

  let parsedDataObject = {};

  if (
    event &&
    event.appointmentType &&
    Array.isArray(apptTypes) &&
    apptTypes.length > 0
  ) {
    const apptType = getApptType(event.appointmentType, apptTypes);
    const apptSubType = getApptType(
      { id: event.appointmentType.subType },
      apptType?.subTypes || []
    );

    parsedDataObject = {
      ...parsedDataObject,
      appSubType: apptSubType?.title,
      appType: apptType?.title,
      isClinical: apptType?.isClinical
    };
  }

  if (event && event.startTime) {
    const selectedSlot = {
      provider: event.therapist,
      providerId: event.therapist.id,
      start: event.startTime,
      end: event.endTime,
      id: `${event.therapist.id}-${event.startTime}`
    };

    //TODO: check this
    parsedDataObject = {
      ...parsedDataObject,
      selectedSlot,
      selectedDate: moment(selectedSlot.start)
        .startOf('day')
        .toISOString(),
      startDate: selectedSlot.start,
      endDate: selectedSlot.end
    };
  }

  //location parsing
  const location =
    event?.locationStreet && !isEmpty(event?.locationStreet)
      ? getLocationDetails(event?.locationStreet, event?.locationCategory)
      : event?.locationDetails;

  parsedDataObject = {
    ...parsedDataObject,
    locationDetails: {
      addressLine1: location?.addressLine1 || null,
      addressLine2: location?.addressLine2 || null,
      city: location?.city || null,
      state: location?.state || null,
      name: location?.name || null,
      zipCode: location?.zipCode || '',
      country: location?.country || LOCATION_DEFAULT_COUNTRY,
      isOldLocation: location?.isOldLocation
    }
  };

  return {
    ...appointmentForm,
    ...parsedDataObject
  };
}

export const getNewAppointmentDisabledFields = (
  reschedule,
  isEditBehaviorOnly
) => ({
  appSubType: reschedule || isEditBehaviorOnly,
  appType: reschedule || isEditBehaviorOnly,
  clinicId: false,
  duration: isEditBehaviorOnly,
  scheduleType: reschedule || isEditBehaviorOnly,
  startDate: false,
  endDate: false,
  provider: isEditBehaviorOnly,
  selectedDate: isEditBehaviorOnly,
  selectedSlot: isEditBehaviorOnly,
  selectedRoom: isEditBehaviorOnly,
  repeat: reschedule || isEditBehaviorOnly,
  isClinical: reschedule || isEditBehaviorOnly,
  telehealthLink: false,
  notes: false,
  recurringUntil: reschedule || isEditBehaviorOnly,
  clinicPreference: isEditBehaviorOnly,
  paymentMethod: reschedule || isEditBehaviorOnly,
  locationType: false,
  locationCategory: false,
  locationDetails: false,
  reschedule,
  isEditBehaviorOnly
});

export const eventInRange = (event, range, doCheckEventTime) => {
  const momentObject = moment(event.startDate);
  const startHour = momentObject.hour();
  if (doCheckEventTime)
    return (
      momentObject.isSameOrAfter(range.start) &&
      momentObject.isBefore(range.end)
    );
  // Using Calendar Range to filter events
  else
    return (
      startHour >= MIN_CALENDAR_TIME.getHours() &&
      startHour < MAX_CALENDAR_TIME.getHours() &&
      momentObject.isSameOrAfter(range.start) &&
      momentObject.isBefore(range.end)
    ); // Using Calendar Range to filter events
};

export const eventInRangeV2 = (event, range) => {
  const momentObject = moment(event.startDate);
  const startHour = momentObject.hour();
  return (
    startHour >= MIN_CALENDAR_TIME.getHours() &&
    startHour < MAX_CALENDAR_TIME.getHours() &&
    momentObject.isSameOrAfter(range.start) &&
    momentObject.isBefore(range.end)
  ); // Using Calendar Range to filter events
};

export const eventsToCalendarData = (
  events,
  provider = {},
  apptTypes,
  range
) => {
  const finalEvents = [];
  if (Array.isArray(events)) {
    events.forEach(event => {
      // 1 - check if event in range of hours
      if (eventInRange(event, range)) {
        // const apptType = apptTypes.get(`${event?.appointmentType?.id}`) || {}
        const therapist = event?.provider || provider;
        const headerColor = event?.appointmentType?.headerColor;
        const backgroundColor = event?.appointmentType?.backgroundColor;
        const superType = event?.appointmentType?.title;

        // one loop for all filteration
        finalEvents.push({
          ...event,
          start: new Date(event.startDate),
          end: new Date(event.endDate),
          resourceId: therapist.id,
          title: parseUsername(event.client?.name),
          desc: { main: superType, extras: [therapist.name] },
          color: headerColor,
          backgroundColor: backgroundColor,
          headerColor: headerColor,
          superType: superType,
          startTime: moment(new Date(event.startDate)),
          endTime: moment(new Date(event.endDate))
        });
      }
    });
  }

  return finalEvents;
};

export const getSubOptions = (id, apptTypes) => {
  const apptType = getApptType({ id }, apptTypes);
  const subTypes = apptType?.appointmentSubTypes || [];
  return subTypes;
};

export const mapToConflict = (formData, conflicts) => {
  const mappedConflicts = conflicts
    .map(({ startTime, endTime }) => ({
      title: formData.selectedSlot.provider.name,
      startTime,
      endTime,
      locationType: formData.locationType,
      appType: formData.appType,
      category: formData.locationCategory
    }))
    .sort((c1, c2) => moment(c2.start).diff(c1.start));
  return mappedConflicts;
};

export const mapEventToConflict = (event, conflicts) => {
  const mappedConflicts = conflicts
    .map(({ startTime, endTime }) => ({
      title: event.provider.name,
      startTime,
      endTime,
      locationType: event.event.locationType,
      appType: event.appointmentType.title,
      category: event.event.locationCategory
    }))
    .sort((c1, c2) => moment(c2.start).diff(c1.start));
  return mappedConflicts;
};

const getLocationDetails = (locationStreet, locationCategory) => {
  try {
    const locationDetails = locationStreet.split(',');
    const stateAndZip = locationDetails[2]?.split(' ');
    let state = '';
    let zip = '';
    for (let i = 0; i < stateAndZip.length; i++) {
      if (isNaN(stateAndZip[i])) {
        state = state + ' ' + stateAndZip[i];
      }
    }
    if (!isNaN(stateAndZip[stateAndZip.length - 1])) {
      zip = stateAndZip[stateAndZip.length - 1];
    }
    return {
      addressLine1: locationDetails[0].trim(),
      city: locationDetails[1].trim(),
      state: state ? state.trim() : undefined,
      zipCode: zip ? zip.trim() : undefined,
      country: LOCATION_DEFAULT_COUNTRY,
      isOldLocation: true
    };
  } catch (err) {
    if (locationCategory === 'Home')
      return {
        addressLine1: locationStreet,
        city: '',
        state: '',
        zipCode: '',
        country: LOCATION_DEFAULT_COUNTRY,
        isOldLocation: true
      };
    else return undefined;
  }
};

// Param array of appointment types for easy access
export const buildAppointmentDictionary = apptTypes => {
  const appointmentTypesMap = new Map();

  apptTypes.forEach(apptType => {
    appointmentTypesMap.set(`${apptType.id}`, apptType);
  });

  return appointmentTypesMap;
};

export const getApptTypesDictionary = data => {
  const appointmentTypes = data?.appointmentTypes || [];
  const appointmentABATypes = data?.appointmentABATypes || [];
  const adminTypes = (data?.adminAppointmentTypes || []).map(eventType => ({
    title: eventType.value,
    id: eventType.id,
    isClinical: false
  }));
  const allTypes = [...appointmentTypes, ...appointmentABATypes, ...adminTypes];
  const appointmentTypesMap = buildAppointmentDictionary(allTypes);
  return appointmentTypesMap;
};

const setCalenderEventStyle = event => {
  if (event.status === EVENT_STATUS.deleted) {
    event.backgroundColor = APPOINTMENT_BACKGROUND_COLORS.DELETED;
    event.headerColor = APPOINTMENT_HEADER_COLORS.DELETED;
  } else if (event.status === EVENT_STATUS.canceled) {
    event.backgroundColor = APPOINTMENT_BACKGROUND_COLORS.CANCELED;
    event.headerColor = APPOINTMENT_HEADER_COLORS.CANCELED;
  } else if (event.type === EventType.adminEvent) {
    event.backgroundColor = APPOINTMENT_BACKGROUND_COLORS.ADMIN;
    event.headerColor = APPOINTMENT_HEADER_COLORS.ADMIN;
  }
};

export const mapToCalendarEvents = (
  events,
  type,
  view,
  date,
  apptTypesMap,
  staffMap,
  providers = [],
  isPendingFilter
) => {
  const providersLocStatus =
    providers.length > 0
      ? initProvidersFiltersLookUp(providers, isPendingFilter)
      : null;
  const finalEvents = [];

  if (Array.isArray(events)) {
    events.forEach(e => {
      const event = {
        ...e,
        canMove: !isDisabledMovement(e)
      };
      const eventsApptType = getAppointmentTypeData(apptTypesMap, event);

      if (staffMap) {
        const provider = staffMap.get(event.therapist.id);
        event.therapist.name = formatUsernameOriginal(
          provider?.firstName,
          provider?.lastName
        );
      }

      if (event.type === EventType.clientEvent) {
        if (type === calendarGroup.client) {
          const clientEvent = mapToClientEvent(
            event,
            eventsApptType,
            view,
            date
          );
          setCalenderEventStyle(clientEvent);
          finalEvents.push(clientEvent);
        } else {
          if (type === calendarGroup.provider) {
            if (providersLocStatus) {
              const providerStatusObj = providersLocStatus[event.therapist.id];
              if (providerStatusObj !== undefined) {
                if (event?.locationType === LOCATION_TYPE_LIST[0].id) {
                  providerStatusObj[LOCATION_TYPE_LIST[0].id] = true;
                } else if (event?.locationType === LOCATION_TYPE_LIST[1].id) {
                  providerStatusObj[LOCATION_TYPE_LIST[1].id] = true;
                } else if (event?.locationType === LOCATION_TYPE_LIST[2].id) {
                  providerStatusObj[LOCATION_TYPE_LIST[2].id] = true;
                }
              }
              if (isPendingFilter) {
                //if pending is applied , update providers lookup according to pending value
                providerStatusObj[internalFilters.pending] =
                  event.isPendingConfirmation ||
                  providerStatusObj[internalFilters.pending];
              }
            }
            const staffEvent = mapToStaffEvent(
              event,
              eventsApptType,
              view,
              date
            );
            setCalenderEventStyle(staffEvent);
            finalEvents.push(staffEvent);
          }
        }
      } else if (event.type === EventType.adminEvent) {
        const staffEvent = mapToStaffEvent(event, eventsApptType, view, date);
        setCalenderEventStyle(staffEvent);
        finalEvents.push(staffEvent);
      }
    });
  }

  return { finalEvents, providersLocStatus };
};

//if pending not selected providers will not be filtered ,
//else if pending set default to false until a pending event is found
const initProvidersFiltersLookUp = (providers, isPendingFilter) => {
  const initProvidersFiltersObj = {};
  for (const provider of providers) {
    initProvidersFiltersObj[provider.id] = {
      [LOCATION_TYPE_LIST[0].id]: false,
      [LOCATION_TYPE_LIST[1].id]: false,
      [LOCATION_TYPE_LIST[2].id]: false,
      [internalFilters.pending]: !isPendingFilter ? true : false
    };
  }
  return initProvidersFiltersObj;
};

const getAppointmentTypeData = (apptTypes, event) => {
  const apptType =
    apptTypes.get(
      `${event?.appointmentType?.id}-${event?.appointmentType?.isClinical}`
    ) || {};

  const headerColor =
    event?.appointmentType?.headerColor || (apptType && apptType?.headerColor);
  const backgroundColor =
    event?.appointmentType?.backgroundColor ||
    (apptType && apptType?.backgroundColor);
  const superType = apptType && apptType?.title;
  return {
    ...apptType,
    headerColor,
    backgroundColor,
    superType
  };
};

/**{
  ...event,
  calendarID: Unique id for the calendar (clieng:EventID) /(staff:EventID),
  start: Start of event used by react-big-calendar
  end: End of event used by react-big-calendar,
  startTime:  Start of event used by react-calendar-timeline,
  endTime: End of event used by react-calendar-timeline,
  plannerWeekStartTime: Selected Week First day Date + event's original start time
  plannerWeekEndTime: Selected Week First day Date + event's original end time
  resourceId: (client/provider) ID (planner day) , (client:DayIndex/provider:DayIndex) (planner week)
  baseId: (client/provider) ID
}; **/

const mapToClientEvent = (event, eventsApptType, view, date) => {
  let resourceID = event.client?.id;
  let plannerWeekStartTime = '';
  let plannerWeekEndTime = '';

  if (view === views.PLANNER_WEEK) {
    plannerWeekEndTime = getPlannerWeekTime(event.endTime, date);
    plannerWeekStartTime = getPlannerWeekTime(event.startTime, date);
    const eventDay = getEventDayIndex(event.startTime);
    resourceID = `${resourceID}:${eventDay}`;
  }

  return {
    ...event,
    calendarID: `client:${event.id}`,
    start: new Date(event.startTime),
    end: new Date(event.endTime),
    startTime: moment(new Date(event.startTime)),
    endTime: moment(new Date(event.endTime)),
    plannerWeekStartTime,
    plannerWeekEndTime,

    backgroundColor: eventsApptType?.backgroundColor,
    headerColor: eventsApptType?.headerColor,
    superType: eventsApptType?.superType,
    color: eventsApptType?.headerColor,

    groupType: calendarGroup.client,
    title: event.therapist?.name,
    resourceId: resourceID,
    baseId: event.client?.id,
    client: event.client,
    evalSlotLocation: null
  };
};

const mapToStaffEvent = (event, eventsApptType, view, date) => {
  const therapist = event.therapist;
  let resourceID = therapist.id;

  let plannerWeekStartTime = '';
  let plannerWeekEndTime = '';

  if (view === views.PLANNER_WEEK) {
    plannerWeekEndTime = getPlannerWeekTime(event.endTime, date);
    plannerWeekStartTime = getPlannerWeekTime(event.startTime, date);
    const eventDay = getEventDayIndex(event.startTime);
    resourceID = `${resourceID}:${eventDay}`;
  }
  return {
    ...event,
    calendarID: `staff:${event.id}`,
    start: new Date(event.startTime),
    end: new Date(event.endTime),
    startTime: moment(new Date(event.startTime)),
    endTime: moment(new Date(event.endTime)),
    backgroundColor: eventsApptType?.backgroundColor,
    headerColor: eventsApptType?.headerColor,
    superType: eventsApptType?.superType,
    color: eventsApptType?.headerColor,
    plannerWeekStartTime,
    plannerWeekEndTime,
    groupType: calendarGroup.provider,
    title: parseUsername(event.client?.name),
    resourceId: resourceID,
    baseId: therapist.id,
    client: event.client,
    evalSlotLocation: null
  };
};

const updateAvailableEvents = (
  cur,
  result,
  availableHours, // represented in the timezone of the provider/client
  resourceId,
  resourceType,
  view,
  date,
  resourceTz
) => {
  let availableSlots = [
    // [0, 300], // midnight to 5AM
    // [1260, 1440] // 9pm to midnight
  ];

  availableHours.forEach(hourObject => {
    const [startHour, startMin] = hourObject.startTime.split(':');
    const [endHour, endMin] = hourObject.endTime.split(':');
    const start = parseInt(startHour || 0) * 60 + parseInt(startMin || 0);
    const end = parseInt(endHour || 0) * 60 + parseInt(endMin || 0);
    availableSlots.push([start, end]);
  });

  if (resourceTz) {
    const formattedDate = new Date(cur.format('YYYY-MM-DD HH:mm'));
    // adjust to present the unavailable sections in the timezone of the scheduler's web client, similar to how events are offset
    const webClientTzString = getWebClientTzString();
    const resourceMinuteOffset = getTzOffsetInMinutes(
      resourceTz,
      formattedDate
    );
    const webClientMinuteOffset = getTzOffsetInMinutes(
      webClientTzString,
      formattedDate
    );
    const minuteOffsetDelta = webClientMinuteOffset - resourceMinuteOffset;
    availableSlots = availableSlots.map(slot => {
      const [start, end] = slot;
      return [
        start === 0 ? start : start + minuteOffsetDelta,
        end + minuteOffsetDelta
      ];
    });
  }

  // this is a 3rd party library that removes chunks of time from a 24 window.
  // for our use, we are removing times a provider is explicitly available in order to generate unavailable times
  const unAvailableSlots = getTimeSlots(
    availableSlots,
    true,
    'quarter',
    false,
    true
  );
  //Build the time-slot for the start of the day say 12:00 AM
  if (unAvailableSlots[15]) {
    //If there are no Booked slots from the dayAvailablilty of the provider or client at 12:00AM
    unAvailableSlots[0] = '0:00'; //Slots should start from 0:00 Morning say morning 12AM
  }
  delete unAvailableSlots[1440]; //End of the slot day must be 11:59 not 12:00
  const lastTimeSlot = 1425; //Take the last time slot at 11:45 since we are adding 15 minutes extra
  if (unAvailableSlots[lastTimeSlot]) {
    //if 11:45 Pm slot is booked and not present in unAvailable slot
    const [hours, minutes] = unAvailableSlots[lastTimeSlot].split(':');
    const reducedMinutes = parseInt(minutes, 10) - 1; //Remove 1 minute so that when added with 15 minute the final time will be 11:59 not 12 which will fall under next day
    unAvailableSlots[lastTimeSlot] = `${hours}:${reducedMinutes}`; //Since the day ends at 23:59 and unAvailable slots should be considered for the end of day
  }

  for (let key in unAvailableSlots) {
    const [startHour, startMin] = unAvailableSlots[key].split(':');
    const start = moment(cur)
      .hour(startHour)
      .minute(startMin)
      .toDate();
    const end = moment(start)
      .add(15, 'minutes')
      .toDate();

    const plannerWeekEndTime = getPlannerWeekTime(end, date);
    const plannerWeekStartTime = getPlannerWeekTime(start, date);
    let resourceID = resourceId;
    if (view === views.PLANNER_WEEK) {
      const eventDay = getEventDayIndex(start);
      resourceID = `${resourceID}:${eventDay}`;
    }

    const event = {
      id: v4(),
      start: start,
      end: end,
      startTime: moment(start),
      endTime: moment(end),
      type: EventType.unAvailable,
      resourceId: resourceID,
      baseId: resourceId,
      groupType: resourceType,
      title: `unAvailable Event ${resourceId}`,
      plannerWeekStartTime,
      plannerWeekEndTime
    };

    mergeUnAvailableSlots(result, event, date);
  }
};

const setUnAvailableDayHash = (
  cur,
  result,
  resourceId,
  resourceType,
  view,
  date
) => {
  const start = moment(cur).hour(MIN_CALENDAR_TIME.getHours());
  const end = moment(start).hour(MAX_CALENDAR_TIME.getHours());

  const plannerWeekEndTime = getPlannerWeekTime(end, date);
  const plannerWeekStartTime = getPlannerWeekTime(start, date);
  let resourceID = resourceId;
  if (view === views.PLANNER_WEEK) {
    const eventDay = getEventDayIndex(start);
    resourceID = `${resourceID}:${eventDay}`;
  }
  result.push({
    id: v4(),
    start: start.toDate(),
    end: end.toDate(),
    startTime: start,
    endTime: end,
    type: EventType.unAvailable,
    resourceId: resourceID,
    baseId: resourceId,
    groupType: resourceType,
    title: `unAvailable Event ${resourceId}`,
    plannerWeekStartTime,
    plannerWeekEndTime
  });
};
const getClientHoursByDay = (
  cur,
  result,
  dayAvailabilities,
  currentRange,
  resourceId,
  resourceType,
  view,
  date
) => {
  const day = cur.day();
  if (dayAvailabilities.find(it => it.dayOfWeek === day) !== undefined) {
    const hours = dayAvailabilities.filter(it => it.dayOfWeek === day);
    updateAvailableEvents(
      cur,
      result,
      hours,
      resourceId,
      resourceType,
      view,
      date
    );
  } else {
    setUnAvailableDayHash(cur, result, resourceId, resourceType, view, date);
  }
};

const getProviderHoursByDay = (
  cur,
  result,
  workingHours,
  currentRange,
  resourceId,
  resourceType,
  view,
  date,
  resourceTz
) => {
  const momentDayToChar = {
    1: 'mon',
    2: 'tues',
    3: 'wed',
    4: 'thurs',
    5: 'fri',
    6: 'sat',
    0: 'sun'
  };

  const day = momentDayToChar[cur.day()];
  if (workingHours[day]) {
    const hours = workingHours[day] || [];
    updateAvailableEvents(
      cur,
      result,
      hours,
      resourceId,
      resourceType,
      view,
      date,
      resourceTz
    );
  } else {
    setUnAvailableDayHash(cur, result, resourceId, resourceType, view, date);
  }
};

//TODO : Fix start and end times for unavailable events
export const getUnavailableEvents = (
  dayAvailabilities,
  currentRange,
  resourceId,
  resourceType,
  view,
  date,
  isClient = false,
  resourceTzString // e.g. America/Denver
) => {
  if (!dayAvailabilities) return [];

  let result = [];
  const cur = moment(currentRange.start);
  while (cur.isBefore(currentRange.end)) {
    if (isClient) {
      getClientHoursByDay(
        cur,
        result,
        dayAvailabilities,
        currentRange,
        resourceId,
        resourceType,
        view,
        date
      );
    } else {
      getProviderHoursByDay(
        cur,
        result,
        dayAvailabilities,
        currentRange,
        resourceId,
        resourceType,
        view,
        date,
        resourceTzString
      );
    }
    cur.add(1, 'day');
  }
  return result;
};

export const mergeUnAvailableSlots = (events, newEvent, date) => {
  const overlappingEventIndex = events.findIndex(e =>
    moment(e.endTime).isSame(moment(newEvent.startTime))
  );
  if (overlappingEventIndex !== undefined && overlappingEventIndex > -1) {
    const overlappingEvent = events[overlappingEventIndex];
    events.splice(overlappingEventIndex, 1);
    const minStart = overlappingEvent.start;
    const maxEnd = newEvent.end;
    const plannerWeekEndTime = getPlannerWeekTime(maxEnd, date);
    const plannerWeekStartTime = getPlannerWeekTime(minStart, date);
    events.push({
      ...newEvent,
      start: minStart,
      end: maxEnd,
      startTime: moment(minStart),
      endTime: moment(maxEnd),
      plannerWeekEndTime,
      plannerWeekStartTime
    });
  } else {
    events.push(newEvent);
  }
};

export const mapToCalendarDropEvent = (
  event,
  events,
  providers,
  newEvent,
  view,
  calendarDate,
  apptTypesMap,
  clients
) => {
  const currentEvents = events.filter(e => e.id !== event.id);
  if (providers.some(e => e.id === newEvent.therapist.id)) {
    currentEvents.push(
      mapToCalendarEvents(
        [newEvent],
        calendarGroup.provider,
        view,
        calendarDate,
        apptTypesMap
      ).finalEvents[0]
    );
  }
  if (clients?.some(e => e.id === newEvent.client.id)) {
    currentEvents.push(
      mapToCalendarEvents(
        [newEvent],
        calendarGroup.client,
        view,
        calendarDate,
        apptTypesMap
      ).finalEvents[0]
    );
  }
  return currentEvents;
};
