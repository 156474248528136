import gql from 'graphql-tag';
import { clinicFragments } from '../fragments/Clinics';

export const GET_CLINIC_LIST = gql`
  {
    clinics {
      ...ClinicData
    }
  }
  ${clinicFragments.clinicData}
`;

export const GET_CLINICS = gql`
  query($status: String!) {
    clinicsWithProvidersWithRooms(status: $status) {
      ...ClinicData
      rooms {
        ...RoomsData
      }
      providers {
        ...Provider
      }
    }
  }
  ${clinicFragments.clinicData}
  ${clinicFragments.roomsData}
  ${clinicFragments.provider}
`;

export const GET_CLINIC = gql`
  query($id: String!) {
    clinic(id: $id) {
      ...ClinicData
      rooms {
        ...RoomsData
      }
    }
  }
  ${clinicFragments.clinicData}
  ${clinicFragments.roomsData}
`;

export const CLINIC_TYPES = gql`
  {
    clinicTypes {
      id
      label
    }
  }
`;

export const GET_CLINICS_OPTIONS = gql`
  query {
    clinicOptionsList {
      id
      name
      abbreviation
      timezone
      address {
        id
        name
        state
      }
    }
  }
`;
