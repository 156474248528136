import moment from 'moment';
import { getLocationColor } from 'pages/ConfigurationsPage/addAvailabilityTemplate/utils';

import {
  momentLocalizer,
  DateRange,
  View,
  NavigateAction
} from 'react-big-calendar';
import { PlannerView } from 'utils/cache/calendar';
import { CLIENT_LOCATIONS_DATA } from 'utils/constants/lists';

export function getCalendarTitle(date: Date): JSX.Element | string {
  const momentDate = moment(date);
  return (
    <span>
      <strong>{momentDate.format('MMMM')}</strong>
      {momentDate.format('YYYY')}
    </span>
  );
}

/* Handle different range representations supported by react-big-calendar */

export const customizeRange = (date: Date, view: View) => {
  return {
    start: moment(date)
      .startOf(view as moment.unitOfTime.StartOf)
      .startOf('day')
      .toDate(),
    end: moment(date)
      .endOf(view as moment.unitOfTime.StartOf)
      .endOf('day')
      .toDate()
  };
};

export const getRange = (date: Date, interval: View) => {
  const momentObj = moment(date);
  return {
    start: momentObj
      .startOf(interval as moment.unitOfTime.StartOf)
      .add(8, 'h')
      .toDate(), // format() returns the date string and fixes the timezone issue
    end: momentObj
      .endOf(interval as moment.unitOfTime.StartOf)
      .subtract(3, 'h')
      .toDate()
  };
};

export const customEventPropGetter = ({
  type,
  end,
  start,
  color,
  isPendingConfirmation,
  isAvailabilitySlot,
  location,
  locations,
  calendarView,
  isProviderEvent
}: any): { className?: string; style?: Object } => {
  if (!isAvailabilitySlot) {
    const thirtyMin = 1800000;
    const lessThanThirtyMin = end - start < thirtyMin;
    const zIndex = type !== 'appointment' ? (color ? '1' : '0') : '10';
    let eventClass =
      lessThanThirtyMin && type && type !== 'na'
        ? `${type} lessThanThirtyMin`
        : type;
    return {
      className: `${eventClass} event-appointment ${
        isProviderEvent ? 'ProviderEvent' : ''
      }`,
      style: {
        backgroundColor: color,
        zIndex,
        opacity: isPendingConfirmation ? 0.5 : 1
      }
    };
  } else {
    const locationData = [
      ...CLIENT_LOCATIONS_DATA,
      ...(locations?.clientLocations || [])
    ];
    let style = getLocationColor(locationData, location);
    style.backgroundColor = 'rgba(61, 137, 182, 0.2)';
    style.border = '1px solid rgba(61, 137, 182, 0.2)';
    return {
      className: `availability-slot-${calendarView}`,
      style: {
        ...style
        // opacity: 0.5
      }
    };
  }
};

export const isTodayInRange = (range: DateRange): boolean => {
  let isBetweenDate = moment().isBetween(
    moment(range?.start),
    moment(range?.end),
    undefined,
    '[]'
  );
  return isBetweenDate;
};

export const getNavigationDate = (
  navigationAction: NavigateAction,
  date: Date,
  view: PlannerView
) => {
  if (navigationAction === 'NEXT') {
    if (view === PlannerView.plannerDay) {
      return moment(date)
        .add(1, 'day')
        .toDate();
    } else {
      const range = customizeRange(
        moment(date)
          .add(1, 'weeks')
          .toDate(),
        'week'
      );
      return range?.start;
    }
  } else if (navigationAction === 'PREV') {
    if (view === PlannerView.plannerDay) {
      return moment(date)
        .subtract(1, 'day')
        .toDate();
    } else {
      const range = customizeRange(
        moment(date)
          .subtract(1, 'weeks')
          .toDate(),
        'week'
      );
      return range?.start;
    }
  } else return moment().toDate();
};

export const localizer = momentLocalizer(moment);
