import { GET_ATTENDEES } from 'api/graphql/v2/queries/Attendees';
import { SearchWrapper } from 'components/calendar/calendarFiltersAndSearch/style';
import { useCallback, useRef, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { AsyncTypeahead, AsyncTypeaheadProps } from 'react-bootstrap-typeahead';
import { alphabeticalSort } from 'utils/sort';
import Loader from '../../../views/components/ui/content/Loader';
import searchIcon from 'assets/img/bars-search-search-bar.svg';

interface OptionType {
  displayName: string;
}
interface IProps {
  providerSearch: string;
  onSearchChange: (value: any) => void;
  setList: React.Dispatch<React.SetStateAction<OptionType[]>>;
  list: OptionType[];
  setOnKeyDown?: React.Dispatch<React.SetStateAction<boolean>>;
  setEnteredProviders?: React.Dispatch<React.SetStateAction<OptionType[]>>;
}

const AsyncTypeaheadWithOptionType = AsyncTypeahead as new (
  props: AsyncTypeaheadProps<OptionType>
) => AsyncTypeahead<OptionType>;

const ProviderSearch = ({
  providerSearch,
  onSearchChange,
  setList,
  list,
  setOnKeyDown,
  setEnteredProviders
}: IProps) => {
  const selectedProviderRef = useRef(null);
  const [menuShown, setMenuShown] = useState(false);
  const [providerLoading, setProviderLoading] = useState(false);

  const [doRequest, { data: attendeesList }] = useLazyQuery(GET_ATTENDEES, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const nonDisplayedAttendees = attendeesList?.attendees?.sort(
        (a1: any, a2: any) => alphabeticalSort(a1.displayName, a2.displayName)
      );
      setList(nonDisplayedAttendees);
      setProviderLoading(false);
    }
  });

  const handleSearch = useCallback(
    query => {
      setProviderLoading(true);
      if (providerSearch && query !== providerSearch) {
        onSearchChange([]);
      }
      if (query.length !== 0) {
        onSearchChange([{ id: undefined, name: query }]);
        doRequest({
          variables: {
            name: query?.trim(),
            isClient: false,
            isStaffPage: true
          }
        });
      }
    },
    [doRequest]
  );

  const handleInputChange = useCallback(input => {
    if (input.length === 0) onSearchChange([]);

    input.length < 3 ? setMenuShown(false) : setMenuShown(true);
    onSearchChange([{ id: undefined, name: input }]);
    setProviderLoading(true);
    setList([]);
  }, []);

  const handleKeyDown = (e: Event) => {
    const event = e as KeyboardEvent;
    if (event.key === 'Enter') {
      setOnKeyDown?.(true);
      setEnteredProviders?.(list);
      // setClientMenuShow(false);
    }
  };

  return (
    <>
      <SearchWrapper
        isSearchListOpen={menuShown}
        view={'day'}
        isStaffPage={true}
      >
        <div className="client_search_wrap provider_search_wrap">
          <AsyncTypeaheadWithOptionType
            defaultInputValue={providerSearch}
            className="client-Provider_wrapper staff_wrapper waitlist_Wrapper"
            filterBy={() => true}
            ref={selectedProviderRef}
            id="search-attendees"
            labelKey={'displayName'}
            isLoading={providerLoading}
            minLength={3}
            onSearch={handleSearch}
            options={list}
            placeholder={'Search Provider'}
            onKeyDown={handleKeyDown}
            onChange={data => {
              onSearchChange(data);
            }}
            delay={0}
            renderMenuItemChildren={(option: OptionType) => (
              <p className="client-Provider_option">{option.displayName}</p>
            )}
            emptyLabel={<p className="client-Provider_results">No results</p>}
            promptText={
              <p className="client-Provider_search">Type to search...</p>
            }
            searchText={
              <p className="client-Provider_loader">
                <Loader />
              </p>
            }
            onInputChange={handleInputChange}
            useCache={false} //to remove any cached values that may be added to calendar already
          />
          <img src={searchIcon} className="search-icon-waitList" alt="" />
        </div>
      </SearchWrapper>
    </>
  );
};
export default ProviderSearch;
