import moment from 'antd/node_modules/moment';
import {
  ClientAvailabilityInput,
  ClientAvailabilityOutput,
  ClientDayAvailabilityOutput
} from 'model/v2';
import { ClientAvailabilityEvent } from 'model/v2/availabilityTemplate';
import { getLocationColor } from 'pages/ConfigurationsPage/addAvailabilityTemplate/utils';
import { CLIENT_LOCATIONS_DATA } from 'utils/constants/lists';
export const mapClientEventsToClientAvailabilityInput = (
  clientId: number,
  events: ClientAvailabilityEvent[]
): ClientAvailabilityInput => {
  return {
    clientId: clientId,
    availabilities: events.map(event => {
      return {
        id: event.id,
        dayOfWeek: event.start.getDay(),
        startTime: moment(event.start).format('HH:mm'),
        endTime: moment(event.end).format('HH:mm'),
        locationInput: event.location
      };
    })
  };
};

export const compareInitalandUpdatedSlots = (
  initialEvents: any,
  TotalEvents: any
) => {
  let updatedEvents;
  if (TotalEvents.length >= initialEvents.length) {
    updatedEvents = TotalEvents.filter((data: any) => {
      return !initialEvents.some((inidInitial: any) => {
        return data.id
          ? moment(data.start).isSame(inidInitial.start) &&
              moment(data.end).isSame(inidInitial.end) &&
              data.location === inidInitial.location
          : false;
      });
    });
  } else {
    updatedEvents = initialEvents;
  }
  return updatedEvents.length;
};

export const mapClientAvailabilityOutputToEvents = (
  events: ClientDayAvailabilityOutput[] | undefined
): ClientAvailabilityEvent[] => {
  return (
    events?.map(data => {
      return {
        id: data?.id,
        title: '',
        start: moment()
          .day(data.dayOfWeek)
          .set({
            hour: Number(data.startTime.split(':')[0]),
            minute: Number(data.startTime.split(':')[1]),
            second: 0,
            millisecond: 0
          })
          .toDate(),
        end: moment()
          .day(data.dayOfWeek)
          .set({
            hour: Number(data.endTime.split(':')[0]),
            minute: Number(data.endTime.split(':')[1]),
            second: 0,
            millisecond: 0
          })
          .toDate(),
        location: data.location
      };
    }) || []
  );
};

export function mapClientAvailabilityOutputToClientProfilePage(
  this: any,
  Availability: ClientAvailabilityOutput[]
) {
  this.Availabilty = Availability.map((dayAvailablity: any) => {
    const start = moment()
      .day(dayAvailablity.dayOfWeek)
      .set({
        hour: Number(dayAvailablity.startTime.split(':')[0]),
        minute: Number(dayAvailablity.startTime.split(':')[1]),
        second: 0,
        millisecond: 0
      })
      .toDate();
    const end = moment()
      .day(dayAvailablity.dayOfWeek)
      .set({
        hour: Number(dayAvailablity.endTime.split(':')[0]),
        minute: Number(dayAvailablity.endTime.split(':')[1]),
        second: 0,
        millisecond: 0
      })
      .toDate();
    return {
      id: dayAvailablity.id,
      color: 'red',
      duration: 120,
      end: end,
      endDate: end.toISOString(),
      dayOfWeek: dayAvailablity.dayOfWeek,
      start: start,
      startDate: start.toISOString(),
      location: dayAvailablity.location
    };
  });
}

export function generateAvailabilitySchedule(
  startDate: string,
  endDate: string
) {
  const schedule = [];
  const currentDay = moment(startDate);
  const lastDay = moment(endDate);

  while (currentDay.isSameOrBefore(lastDay)) {
    const dayOfWeek = currentDay.day();

    schedule.push({
      date: currentDay.format('YYYY-MM-DD'),
      dayOfWeek
    });

    currentDay.add(1, 'day');
  }

  return schedule;
}

export const formAvaibilityOnCalendarView = (
  TimeSlots: { date: string; dayOfWeek: number }[]
) => (Availability: any[]) => (
  locations: Record<
    string,
    {
      id: number;
      title: string;
      duration: number;
      end: any;
      endDate: string;
      location: number;
      start: any;
      startdate: string;
    }[]
  >
) => (calendarView: string) => {
  let summedAvailabilitySlot: any = [];
  for (let IndTimeSlot of TimeSlots || []) {
    let dayOfWeekAvailability = Availability?.filter((IndAvail: any) => {
      return IndAvail.dayOfWeek === IndTimeSlot.dayOfWeek;
    });
    if (dayOfWeekAvailability) {
      for (let IndDayAvail of dayOfWeekAvailability) {
        const originalstartDateString = IndDayAvail.start;
        const originalEndDateString = moment(IndDayAvail.end);
        const targetStartDateString = IndTimeSlot.date;
        const originalDateObject = moment(originalstartDateString);
        const starthour = originalDateObject.hour();
        const startminute = originalDateObject.minute();
        const startsecond = originalDateObject.second();
        const endhour = originalEndDateString.hour();
        const endMinute = originalEndDateString.minute();
        const endsecond = originalDateObject.second();
        const updatedStartDateObject = moment(targetStartDateString).set({
          hour: starthour,
          minute: startminute,
          second: startsecond
        });
        const updatedEndDateObject = moment(targetStartDateString).set({
          hour: endhour,
          minute: endMinute,
          second: endsecond
        });
        const locationData = [
          ...CLIENT_LOCATIONS_DATA,
          ...(locations?.clientLocations || [])
        ];
        let style = getLocationColor(locationData, IndDayAvail.location);
        style.backgroundColor = 'rgba(61, 137, 182, 0.2)';
        style.border = 'solid 1px rgba(61, 137, 182, 0.2)';
        summedAvailabilitySlot.push({
          ...IndDayAvail,
          start: updatedStartDateObject.toDate(),
          end: updatedEndDateObject.toDate(),
          startDate: updatedStartDateObject.toDate().toISOString(),
          endDate: updatedEndDateObject.toDate().toISOString(),
          isAvailabilitySlot: true,
          locations: locations,
          color: style.backgroundColor,
          calendarView: calendarView
        });
      }
    }
  }
  return summedAvailabilitySlot;
};
