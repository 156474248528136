import gql from 'graphql-tag';

export const GET_ALL_CERTIFICATIONS = gql`
  query GetAllCertifications {
    providerCertifications {
      id
      certificationName
    }
  }
`;
