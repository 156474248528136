import gql from 'graphql-tag';
import { TIMEZONE } from 'utils/constants/default';

export const GET_OPENINGS = gql`
  query(
    $providerId: Int
    $clinicId: Int
    $appointmentTypeId: Int
    $appointmentSubTypeId: Int
    $startDate: DateTime!
    $endDate: DateTime!
    $scope: Int
    $clientId: Int
    $locationType: String!
    $reschedule: Boolean
    $thisAndFollowing: Boolean
    $rescheduledEvent: IRescheduledEvent
  ) {
    openings(
      providerId: $providerId
      clinicId: $clinicId
      appointmentTypeId: $appointmentTypeId
      appointmentSubTypeId: $appointmentSubTypeId
      startDate: $startDate
      endDate: $endDate
      scope: $scope
      clientId: $clientId
      timezone: "${TIMEZONE}"
      locationType: $locationType
      reschedule: $reschedule
      thisAndFollowing: $thisAndFollowing
      rescheduledEvent: $rescheduledEvent
    ) {
      providerId
      openings {
        id
        startDate
        endDate
      }
    }
  }
`;

export const GET_OPENING_CONFLICTS = gql`
  query(
    $providerId: Int
    $startDate: DateTime!
    $endDate: DateTime!
    $recurringUntil: DateTime
    $recurringEvery: Int
    $masterId: Int
    $reschedule: Boolean
    $recurring: Boolean!
    $timezone: String!
  ) {
    getEventConflicts(
      providerId: $providerId
      startDate: $startDate
      endDate: $endDate
      recurringUntil: $recurringUntil
      timezone: $timezone
      recurringEvery: $recurringEvery
      masterId: $masterId
      reschedule: $reschedule
      recurring: $recurring
    ) {
      eventsCount
      conflicts {
        startDate
        endDate
        conflictAppointmentTitle
        locationType
        locationCategory
      }
    }
  }
`;
