import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import Loader from '../../../ui/content/Loader';
import { CheckListWrapper } from 'components/calendar/filters/style';
import MainFilter from 'views/components/waitlistPage/components/filters/mainFilter';
import { waitListFilterHelper } from 'helpers/filterHekper';
import WaitListCommonFilter from 'views/components/waitlistPage/components/filters/waitListCommonFilter';
import FilterListFilled from 'assets/img/FilterListFilled.png';
import {
  ICalendarFilterOption,
  waitListFilterList
} from 'model/calendar/filters';
import { Radio } from 'antd';
export const searchValueContext = React.createContext('');

interface Props {
  isLoading: boolean;
  carePlanFiltersData: waitListFilterList;
  checkedClinics: ICalendarFilterOption[];
  setCheckedClinics: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  checkedServiceType: ICalendarFilterOption[];
  setServiceType: React.Dispatch<React.SetStateAction<ICalendarFilterOption[]>>;
  checkedTherapyType: ICalendarFilterOption[];
  setTherapyType: React.Dispatch<React.SetStateAction<ICalendarFilterOption[]>>;
  checkedStatus: ICalendarFilterOption[];
  setCheckedStatus: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  checkedSmartSchedulingStatus: ICalendarFilterOption[];
  setSmartSchedulingStatus: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  checkedWaitListReasons: ICalendarFilterOption[];
  setWaitListReasons: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  checkedClientAvailability: ICalendarFilterOption[];
  setClientAvailability: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  checkedInsuranceCategories: ICalendarFilterOption[];
  setInsuranceCategories: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  checkedInsurancePayors: ICalendarFilterOption[];
  setInsurancePayors: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  checkedInsurancePlans: ICalendarFilterOption[];
  setInsurancePlans: React.Dispatch<
    React.SetStateAction<ICalendarFilterOption[]>
  >;
  isVBC: boolean;
  setIsVBC: React.Dispatch<React.SetStateAction<boolean>>;
  setIsShowDrawer: React.Dispatch<React.SetStateAction<Boolean>>;
}
const FilterLayout = ({
  isLoading,
  carePlanFiltersData,
  checkedClinics,
  setCheckedClinics,
  checkedServiceType,
  setServiceType,
  checkedTherapyType,
  setTherapyType,
  checkedStatus,
  setCheckedStatus,
  checkedSmartSchedulingStatus,
  setSmartSchedulingStatus,
  checkedWaitListReasons,
  setWaitListReasons,
  checkedClientAvailability,
  setClientAvailability,
  checkedInsuranceCategories,
  setInsuranceCategories,
  checkedInsurancePayors,
  setInsurancePayors,
  checkedInsurancePlans,
  setInsurancePlans,
  isVBC,
  setIsVBC,
  setIsShowDrawer
}: Props) => {
  const categoriesDisabled = !isVBC;
  const payorsDisabled = !isVBC || checkedInsuranceCategories.length < 1;
  const plansDisabled =
    !isVBC ||
    checkedInsuranceCategories.length < 1 ||
    checkedInsurancePayors.length < 1;

  const clinicFiltersRef = useRef(null);
  const serviceTypeFiltersRef = useRef(null);
  const therapyTypeFiltersRef = useRef(null);
  const statusFiltersRef = useRef(null);
  const smartSchedulingStatusRef = useRef(null);
  const waitListReasonFilterRef = useRef(null);
  const insuranceCategoriesFilterRef = useRef(null);
  const insurancePayorFilterRef = useRef(null);
  const insurancePlanFilterRef = useRef(null);
  const clientAvailabilityFilterRef = useRef(null);

  const [hasClinics, setHasClinics] = useState(true);
  const [hasServices, setHasServices] = useState(true);
  const [hasTherapyTypes, setHasTherapyTypes] = useState(true);
  const [hasStatus, setHasStatus] = useState(true);
  const [hasSmartSchedulingStatus, setHasSmartSchedulingStatus] = useState(
    true
  );
  const [hasWaitListReasons, setHasWaitListReasons] = useState(true);
  const [hasClientAvailability, setHasClientAvailability] = useState(true);
  const [hasInsuranceCategories, setHasInsuranceCategories] = useState(true);
  const [hasInsurancePayors, setHasInsurancePayors] = useState(true);
  const [hasInsurancePlans, setHasInsurancePlans] = useState(true);

  const filterRefs: any = {
    clinic: clinicFiltersRef,
    serviceType: serviceTypeFiltersRef,
    therapyType: therapyTypeFiltersRef,
    status: statusFiltersRef,
    clientAvailability: clientAvailabilityFilterRef,
    smartSchedulingStatus: smartSchedulingStatusRef,
    waitListReason: waitListReasonFilterRef,
    insuranceCategory: insuranceCategoriesFilterRef,
    insurancePayor: insurancePayorFilterRef,
    insurancePlan: insurancePlanFilterRef
  };

  const [searchValue, setSearchValue] = useState<Record<string, string>>({
    clinic: '',
    serviceType: '',
    therapyType: '',
    status: '',
    waitListReason: '',
    clientAvailability: '',
    smartSchedulingStatus: '',
    insuranceCategory: '',
    insurancePayor: '',
    insurancePlan: ''
  });

  const [filterDropdownExpand, setFilterDropdownExpand] = useState<
    Record<string, boolean>
  >({
    clinic: false,
    serviceType: false,
    therapyType: false,
    status: false,
    waitListReason: false,
    clientAvailability: false,
    smartSchedulingStatus: false,
    insuranceCategory: false,
    insurancePayor: false,
    insurancePlan: false
  });

  const toggleFilterList = (type: string) => {
    setFilterDropdownExpand({
      ...filterDropdownExpand,
      [type]: !filterDropdownExpand[type]
    });
  };

  const handleGenericBlur = (
    e: React.FocusEvent<HTMLDivElement>,
    filterType: string
  ) => {
    const currentRef = filterRefs[filterType];
    if (
      currentRef.current &&
      (e.relatedTarget === currentRef.current ||
        currentRef.current.contains(e.relatedTarget))
    ) {
      return; // Do nothing if the click was inside the dropdown
    }

    if (filterDropdownExpand[filterType]) {
      setFilterDropdownExpand((prevState: Record<string, boolean>) => ({
        ...prevState,
        [filterType]: false
      }));
    }
  };

  const handleGenericSearch = (
    _e: ChangeEvent<HTMLInputElement>,
    data: { value: any },
    typeOfSearch: string
  ) => {
    const isSupportedSearchType = ['clinic'].includes(typeOfSearch);

    if (isSupportedSearchType) {
      setFilterDropdownExpand((prevState: Record<string, boolean>) => ({
        ...prevState,
        [typeOfSearch]: true
      }));

      setSearchValue((prevState: Record<string, string | number>) => ({
        ...prevState,
        [typeOfSearch]: data?.value
      }));
    }
  };
  //Base handleSearchedValues method
  const createHandleSearchedValues = useCallback(
    (type: string) => {
      //if(setCheckedSavedFilter) setCheckedSavedFilter('');
      return (list: ICalendarFilterOption[]) => {
        const searchTerm = searchValue[type];
        return searchTerm === ''
          ? list
          : list?.filter((option: ICalendarFilterOption) => {
              return option.label
                ?.toLowerCase()
                .startsWith(searchTerm?.toLowerCase());
            });
      };
    },
    [searchValue]
  );

  const handleClinicSearchedValues = createHandleSearchedValues('clinic');
  const handleServiceTypeSearchedValues = createHandleSearchedValues(
    'serviceType'
  );
  const handleTherapyTypeSearchedValues = createHandleSearchedValues(
    'therapyType'
  );
  const handleStatusSearchedValues = createHandleSearchedValues('status');
  const handleSmartSchedulingStatus = createHandleSearchedValues(
    'smartSchedulingStatus'
  );
  const handlewaitListReasonSearchedValues = createHandleSearchedValues(
    'waitListReason'
  );
  const handleClientAvailabilityValues = createHandleSearchedValues(
    'clientAvailability'
  );
  const clearFilter = useCallback(clearFilter => {
    if (clearFilter)
      clearFilter((prev: ICalendarFilterOption[]) => (prev.length ? [] : prev));
  }, []);
  const waitListMappedFilters = useCallback(
    type => {
      if (carePlanFiltersData) {
        return waitListFilterHelper(type, carePlanFiltersData?.WaitListFilter);
      }
    },
    [carePlanFiltersData]
  );
  let checkedClinicsIds = checkedClinics.map(
    (clinic: { label: string; value: string }) => clinic.value
  );
  let checkedServiceTypeLabel = checkedServiceType.map(
    (status: { label: string }) => status.label
  );
  let checkedTherapyTypeLabel = checkedTherapyType?.map(
    (status: { label: string }) => status.label
  );

  console.log(
    checkedClinicsIds,
    checkedServiceTypeLabel,
    checkedTherapyTypeLabel
  );

  return (
    <div>
      <div className="dropdown-wrapper">
        <CheckListWrapper
          visible={filterDropdownExpand.therapyType}
          height="300px"
          style={{ position: 'relative' }}
        >
          <MainFilter
            clinicFiltersRef={clinicFiltersRef}
            handleGenericBlur={handleGenericBlur}
            toggleFilterList={toggleFilterList}
            filterDropdownExpand={filterDropdownExpand}
            handleGenericSearch={handleGenericSearch}
            searchValue={searchValue}
            hasClinics={hasClinics}
            isLoading={isLoading}
            FilterData={carePlanFiltersData?.WaitListFilter?.clinicOptionsList}
            handleClinicSearchedValues={handleClinicSearchedValues}
            checkedClinics={checkedClinics}
            setCheckedClinics={setCheckedClinics}
            setHasClinics={setHasClinics}
            clearFilter={clearFilter}
            Loader={Loader}
            type={'clinic'}
          />
        </CheckListWrapper>
        <WaitListCommonFilter
          filterDropdownExpand={filterDropdownExpand}
          filterTypeRefs={serviceTypeFiltersRef}
          handleGenericBlur={handleGenericBlur}
          toggleFilterList={toggleFilterList}
          handleGenericSearch={handleGenericSearch}
          hasFilterOptions={hasServices}
          searchValue={searchValue}
          isLoading={isLoading}
          filterValueMappings={waitListMappedFilters}
          handleSerachedValues={handleServiceTypeSearchedValues}
          checkedFilterType={checkedServiceType}
          setFilterType={setServiceType}
          setHasFilterOptions={setHasServices}
          clearFilter={clearFilter}
          Loader={Loader}
          title={'Service Type'}
          placeHolder={'Service'}
          blurTitle={'serviceType'}
          handleChangeType={'waitListServices'}
        />
        <WaitListCommonFilter
          filterDropdownExpand={filterDropdownExpand}
          filterTypeRefs={therapyTypeFiltersRef}
          handleGenericBlur={handleGenericBlur}
          toggleFilterList={toggleFilterList}
          handleGenericSearch={handleGenericSearch}
          hasFilterOptions={hasTherapyTypes}
          searchValue={searchValue}
          isLoading={isLoading}
          filterValueMappings={waitListMappedFilters}
          handleSerachedValues={handleTherapyTypeSearchedValues}
          checkedFilterType={checkedTherapyType}
          setFilterType={setTherapyType}
          setHasFilterOptions={setHasTherapyTypes}
          clearFilter={clearFilter}
          Loader={Loader}
          title={'Therapy Type'}
          placeHolder={'Type'}
          blurTitle={'therapyType'}
          handleChangeType={'waitListTheraphyTypies'}
        />
        <WaitListCommonFilter
          filterDropdownExpand={filterDropdownExpand}
          filterTypeRefs={statusFiltersRef}
          handleGenericBlur={handleGenericBlur}
          toggleFilterList={toggleFilterList}
          handleGenericSearch={handleGenericSearch}
          hasFilterOptions={hasStatus}
          searchValue={searchValue}
          isLoading={isLoading}
          filterValueMappings={waitListMappedFilters}
          handleSerachedValues={handleStatusSearchedValues}
          checkedFilterType={checkedStatus}
          setFilterType={setCheckedStatus}
          setHasFilterOptions={setHasStatus}
          clearFilter={clearFilter}
          Loader={Loader}
          title={'Care Plan Status'}
          placeHolder={'Care Plan Status'}
          blurTitle={'status'}
          handleChangeType={'waitListStatus'}
        />
        <WaitListCommonFilter
          filterDropdownExpand={filterDropdownExpand}
          filterTypeRefs={smartSchedulingStatusRef}
          handleGenericBlur={handleGenericBlur}
          toggleFilterList={toggleFilterList}
          handleGenericSearch={handleGenericSearch}
          hasFilterOptions={hasSmartSchedulingStatus}
          searchValue={searchValue}
          isLoading={isLoading}
          filterValueMappings={waitListMappedFilters}
          handleSerachedValues={handleSmartSchedulingStatus}
          checkedFilterType={checkedSmartSchedulingStatus}
          setFilterType={setSmartSchedulingStatus}
          setHasFilterOptions={setHasSmartSchedulingStatus}
          clearFilter={clearFilter}
          Loader={Loader}
          title={'Smart Scheduling'}
          placeHolder={'Smart Scheduling'}
          blurTitle={'smartSchedulingStatus'}
          handleChangeType={'smartSchedulingStatus'}
        />
        <WaitListCommonFilter
          filterDropdownExpand={filterDropdownExpand}
          filterTypeRefs={waitListReasonFilterRef}
          handleGenericBlur={handleGenericBlur}
          toggleFilterList={toggleFilterList}
          handleGenericSearch={handleGenericSearch}
          hasFilterOptions={hasWaitListReasons}
          searchValue={searchValue}
          isLoading={isLoading}
          filterValueMappings={waitListMappedFilters}
          handleSerachedValues={handlewaitListReasonSearchedValues}
          checkedFilterType={checkedWaitListReasons}
          setFilterType={setWaitListReasons}
          setHasFilterOptions={setHasWaitListReasons}
          clearFilter={clearFilter}
          Loader={Loader}
          title={'Reason'}
          placeHolder={'Waitlist Reason'}
          blurTitle={'waitListReason'}
          handleChangeType={'waitListReasons'}
        />

        <WaitListCommonFilter
          filterDropdownExpand={filterDropdownExpand}
          filterTypeRefs={clientAvailabilityFilterRef}
          handleGenericBlur={handleGenericBlur}
          toggleFilterList={toggleFilterList}
          handleGenericSearch={handleGenericSearch}
          hasFilterOptions={hasClientAvailability}
          searchValue={searchValue}
          isLoading={isLoading}
          filterValueMappings={waitListMappedFilters}
          handleSerachedValues={handleClientAvailabilityValues}
          checkedFilterType={checkedClientAvailability}
          setFilterType={setClientAvailability}
          setHasFilterOptions={setHasClientAvailability}
          clearFilter={clearFilter}
          Loader={Loader}
          title={'Availability'}
          placeHolder={'Availability'}
          blurTitle={'clientAvailability'}
          handleChangeType={'waitListAvailabilityTime'}
        />
        <div>
          <div
            className="Additional_Filter_Layout"
            onClick={() => setIsShowDrawer(true)}
          >
            <img src={FilterListFilled}></img>
            <div className="Additional_Filter_Title">
              <span>
                <u>More Filters</u>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="dropdown-wrapper"
        style={{
          justifyContent: 'start',
          gap: '6px',
          display: 'flex',
          alignItems: 'flex-end'
        }}
      >
        <div
          style={{
            marginLeft: '5px',
            marginTop: '15px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexWrap: 'nowrap', // Prevent stacking
            position: 'relative', // Keep dropdowns and VBC confined
            overflow: 'hidden' // Prevent elements from overflowing
          }}
        >
          <span style={{ marginRight: '8px', fontWeight: 'bold' }}>
            VBC Only:
          </span>
          <Radio.Group
            value={isVBC ? 'Yes' : 'No'}
            buttonStyle="solid"
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              borderRadius: '8px',
              border: '1px solid #d9d9d9',
              overflow: 'hidden', // Prevent overflow for the toggle switch
              height: '32px' // Set the height for the entire group
            }}
          >
            <Radio.Button
              value="No"
              style={{
                flex: 1,
                textAlign: 'center',
                backgroundColor: isVBC ? '#fff' : '#6f4bf1',
                color: isVBC ? '#000' : '#fff',
                border: 'none',
                zIndex: 0,
                position: 'relative'
              }}
              onClick={() => {
                setIsVBC(false);
              }}
            >
              No
            </Radio.Button>
            <Radio.Button
              value="Yes"
              style={{
                flex: 1,
                textAlign: 'center',
                backgroundColor: isVBC ? '#6f4bf1' : '#fff',
                color: isVBC ? '#fff' : '#000',
                border: 'none',
                zIndex: 0,
                position: 'relative'
              }}
              onClick={() => {
                setIsVBC(true);
              }}
            >
              Yes
            </Radio.Button>
          </Radio.Group>
        </div>
        <WaitListCommonFilter
          filterDropdownExpand={filterDropdownExpand}
          filterTypeRefs={insuranceCategoriesFilterRef}
          handleGenericBlur={handleGenericBlur}
          toggleFilterList={toggleFilterList}
          handleGenericSearch={handleGenericSearch}
          hasFilterOptions={hasInsuranceCategories}
          searchValue={searchValue}
          isLoading={isLoading}
          filterValueMappings={waitListMappedFilters}
          handleSerachedValues={createHandleSearchedValues('insuranceCategory')}
          checkedFilterType={checkedInsuranceCategories}
          setFilterType={setInsuranceCategories}
          setHasFilterOptions={setHasInsuranceCategories}
          clearFilter={clearFilter}
          Loader={Loader}
          title={'Category'}
          placeHolder={'Category'}
          blurTitle={'insuranceCategory'}
          handleChangeType={'insuranceCategories'}
          disabled={categoriesDisabled}
        />
        <WaitListCommonFilter
          filterDropdownExpand={filterDropdownExpand}
          filterTypeRefs={insurancePayorFilterRef}
          handleGenericBlur={handleGenericBlur}
          toggleFilterList={toggleFilterList}
          handleGenericSearch={handleGenericSearch}
          hasFilterOptions={hasInsurancePayors}
          searchValue={searchValue}
          isLoading={isLoading}
          filterValueMappings={waitListMappedFilters}
          handleSerachedValues={createHandleSearchedValues('insurancePayor')}
          checkedFilterType={checkedInsurancePayors}
          setFilterType={setInsurancePayors}
          setHasFilterOptions={setHasInsurancePayors}
          clearFilter={clearFilter}
          Loader={Loader}
          title={'Payer'}
          placeHolder={'Payer'}
          blurTitle={'insurancePayor'}
          handleChangeType={'insurancePayors'}
          disabled={payorsDisabled}
        />

        <WaitListCommonFilter
          filterDropdownExpand={filterDropdownExpand}
          filterTypeRefs={insurancePlanFilterRef}
          handleGenericBlur={handleGenericBlur}
          toggleFilterList={toggleFilterList}
          handleGenericSearch={handleGenericSearch}
          hasFilterOptions={hasInsurancePlans}
          searchValue={searchValue}
          isLoading={isLoading}
          filterValueMappings={waitListMappedFilters}
          handleSerachedValues={createHandleSearchedValues('insurancePlan')}
          checkedFilterType={checkedInsurancePlans}
          setFilterType={setInsurancePlans}
          setHasFilterOptions={setHasInsurancePlans}
          clearFilter={clearFilter}
          Loader={Loader}
          title={'Plan'}
          placeHolder={'Plan'}
          blurTitle={'insurancePlan'}
          handleChangeType={'insurancePlans'}
          disabled={plansDisabled}
        />
      </div>
    </div>
  );
};

export default FilterLayout;
