import React, { useState } from 'react';
import Breadcrumbs from 'views/components/ui/profile/Breadcrumbs';
import Header from './header';
import Content from 'views/components/ui/content';
import ClientTabs from './tabs/index.js';
import { useQuery } from '@apollo/react-hooks';
import {
  GET_CLIENT_AVAILABILITYV2,
  GET_CLIENT_LATEST_AVAILABILITY,
  GET_CLIENT_PROFILE
} from 'api/graphql/v2/queries/Clients';
import { GET_AVAILABILITY_AUDIT } from 'api/graphql/v2/mutations/ClientAvailability';
import moment from 'moment';

const ClientProfile = ({ match, accountID }) => {
  const id = match.params.id;
  const [clientAvailabilityCreatedAt, setCreatedAt] = useState();
  const [dayAvailabilities, setDayAvailabilities] = useState();
  const { loading: loadingClientData, error, data: clientData } = useQuery(
    GET_CLIENT_PROFILE,
    {
      variables: {
        id: Number(id),
        startDate: '',
        endDate: '',
        clientIds: [parseInt(id)]
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  useQuery(GET_CLIENT_AVAILABILITYV2, {
    fetchPolicy: 'no-cache',
    variables: { id: Number(id) },
    onCompleted: data => {
      if (data?.clientDayAvailabilities.length > 0) {
        const mostRecent = data?.clientDayAvailabilities?.reduce(
          (latest, current) => {
            return new Date(current.updatedAt) > new Date(latest.updatedAt)
              ? current
              : latest;
          }
        );
        setDayAvailabilities(mostRecent);
      } else {
        setDayAvailabilities([]);
      }
    }
  });

  useQuery(GET_CLIENT_LATEST_AVAILABILITY, {
    fetchPolicy: 'no-cache',
    variables: { id: Number(id) },
    onCompleted: async data => {
      setCreatedAt(data);
    }
  });
  return (
    <Content loading={loadingClientData} error={error} data={clientData}>
      {() => (
        <>
          <Breadcrumbs userType="client" user={clientData.clientProfile} />
          <Header
            client={clientData?.clientProfile}
            clientWaitlist={[]} //data?.clientWaitlist //to be replaced with clientData.waitList
            appointmentTypes={clientData?.allAppointmentTypes}
          />
          <ClientTabs
            client={clientData?.clientProfile}
            //determine if used in calendar
            calendarOptions={{
              blockedDates:
                clientData?.clientAvailability?.length > 0
                  ? clientData.clientAvailability[0].blockedDates
                  : [],
              availabilityLastUpdated: clientAvailabilityCreatedAt
                ?.getLatestAvailabilityAudit?.updatedAt
                ? clientAvailabilityCreatedAt?.getLatestAvailabilityAudit
                    ?.updatedAt
                : dayAvailabilities?.updatedAt
                ? dayAvailabilities?.updatedAt
                : clientData?.clientProfile?.availabilityLastModificationDate
                ? clientData?.clientProfile?.availabilityLastModificationDate
                : ' No Availability Present'
            }}
            accountID={accountID}
          />
        </>
      )}
    </Content>
  );
};
export default ClientProfile;
