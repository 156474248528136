import moment from 'moment';
import { appointmentTypeCheck } from 'helpers/clientWaitlistHelper';
import { waitListSSStatus } from 'utils/constants/tables/waitlist';
import ThreeDot from 'assets/img/DotsThreeVertical.png';
import { FilterPageNameEnum } from 'components/calendar/calendarToolbar/FilterTagList/form/utils';

export const mapCarePlanToTable = carePlanData => {
  function getLatestObjectByUpdatedAt(dayAvailabilities, availabilityAudits) {
    const combined = [...dayAvailabilities, ...availabilityAudits];
    if (combined.length === 0) return null;
    return combined.reduce((latest, current) => {
      return new Date(current?.updatedAt) > new Date(latest?.updatedAt)
        ? current
        : latest;
    });
  }
  if (carePlanData) {
    let { getCarePlansForWaitList } = carePlanData;
    return getCarePlansForWaitList?.CarePlan.map(data => {
      let [client] = data.client;
      let [AvailabilityAudit] = client?.availabilityAudit
        ? client?.availabilityAudit
        : [];
      let [clientDayAvailabilities] = client?.dayAvailabilities
        ? client?.dayAvailabilities
        : [];
      const mostRecentDayAvailability = getLatestObjectByUpdatedAt(
        clientDayAvailabilities ? [clientDayAvailabilities] : [],
        AvailabilityAudit ? [AvailabilityAudit] : []
      );
      let [salesForceMappedData] = data.SalesForceMappedTherapy;
      data.eval_type = data.eval_type__c;
      let ifDTAppt = appointmentTypeCheck(
        data,
        undefined,
        salesForceMappedData
      );
      let SalesForceMappedAxonTheray = data.SalesForceMappedTherapy;
      return {
        clientClinic: client?.clinic,
        clientInsurancePlan: client?.insurancePlan,
        salesforceId: data?.patient_account__c,
        waitListDate: data.createdDate
          ? moment(data.createdDate).format('MM/DD/YYYY')
          : 'N/A',
        waitListReason: data.waitlisted_reason__c || 'N/A',
        cpStatus: data.status__c,
        cpName: data.name,
        rss: waitListSSStatus[data.SmartScheduling_status],
        SmartScheduling_status: data.SmartScheduling_status,
        bookingDetails: `${
          ifDTAppt ? data.DTReceivingUnits : data.axonReceivingHours
        }${' '} - ${data.recommended__c}${' '}-${' '}${data.requested__c}`,
        service: data.eval_type__c,
        receiving:
          (ifDTAppt ? data.DTReceivingUnits : data.axonReceivingHours) || 0,
        waitListRequested: data.requested__c,
        DTReceivingUnits: data.DTReceivingUnits,
        axonReceivingHours: data.axonReceivingHours,
        recommended: data.recommended__c || 0,
        requested: data.requested__c,
        ifDTAppt: ifDTAppt,
        type: data.therapy_type__c,
        notes: data.axonNotes || 'N/A',
        notesCount: data.noteCount || 0,
        name: `${client?.firstName} ${client?.lastName} (${client?.clinic?.abbreviation})`,
        availUpdated: mostRecentDayAvailability?.updatedAt
          ? moment(mostRecentDayAvailability.updatedAt).format('MM/DD/YYYY')
          : 'No availability Present',
        client_Dob: moment(client?.profile?.dob).format('MM/DD/YYYY'),
        client_mail: client?.profile?.personalEmail,
        client_phone: client?.profile?.cellPhone,
        client_clinic: client?.clinic?.name,
        insurancePayor: data?.insurancePayor,
        client_dayAvailabilities:
          client?.dayAvailabilities?.sort((a, b) => {
            const startTimeA = a.startTime.split(':').map(num => parseInt(num));
            const startTimeB = b.startTime.split(':').map(num => parseInt(num));

            if (startTimeA[0] !== startTimeB[0]) {
              return startTimeA[0] - startTimeB[0];
            } else {
              return startTimeA[1] - startTimeB[1];
            }
          }) || [],
        client_carePlans: data?.clientCarePlans,
        actions: (
          <div>
            <img src={ThreeDot} />
          </div>
        ),
        clientId: client?.id,
        eval_type: data?.eval_type__c,
        carePlanId: data.id,
        AxonType: SalesForceMappedAxonTheray.map(apptType => {
          return {
            subTypeId: apptType?.id,
            subTypeTitle: apptType?.subTypeTitle,
            subTypesalesforceTherapyTitle:
              apptType?.subTypesalesforceTherapyTitle,
            parentId: apptType?.parentTypeId,
            parentTypeTitle: apptType?.parentTypeTitle,
            parentTypeEventId: apptType?.parentTypeEventId
          };
        }),
        clinic_timezone: client?.clinic?.timezone
      };
    });
  }
};

export const checkIfFilterSelected = (
  start,
  end,
  selectedDay,
  selectedLocation
) => {
  let selectedStartDate = moment(start);
  let selectedEndDate = moment(end);
  let dateDiff = selectedStartDate.diff(selectedEndDate);
  return (
    dateDiff !== 0 || selectedDay?.length > 0 || selectedLocation?.length > 0
  );
};

export const table_Skeletal_Loader = (Skeleton, pageName, tableData) => {
  let skeletalData = tableData ? [...tableData] : [];

  for (var i = 0; i < 4; i++) {
    if (FilterPageNameEnum.waitList === pageName) {
      skeletalData.push({
        isLoaderView: true,
        waitListDate: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        waitListReason: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        cpStatus: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        rss: <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />,
        guraranteedShiftBiRss: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        bookingDetails: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        recommended: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        receiving: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        waitListRequested: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        service: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        type: <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />,
        notes: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        name: <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />,
        availUpdated: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        client_Dob: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        client_mail: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        client_phone: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        client_clinic: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        client_dayAvailabilities: [],
        client_carePlans: [],
        actions: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        )
      });
    } else if (FilterPageNameEnum.provider === pageName) {
      skeletalData.push({
        isLoaderView: true,
        status: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        languages: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        programType: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        certifications: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        email: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        actions: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        phoneNumber: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        zipCode: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        gender: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        transportation: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        petAllergies: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        notes: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        name: <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />,
        availUpdated: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        client_Dob: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        client_mail: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        client_phone: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        client_clinic: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        client_dayAvailabilities: [],
        client_carePlans: [],
        clients: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        isSkeletalLoad: true
      });
    } else if (FilterPageNameEnum.client === pageName) {
      skeletalData.push({
        isLoaderView: true,
        name: <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />,
        dob: <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />,
        status: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        email: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        phone: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        gender: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        petAllergies: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        languages: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        availability: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        notes: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        program: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        restrictions: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        providers: (
          <Skeleton active={true} paragraph={{ rows: 0, width: '100%' }} />
        ),
        isSkeletalLoad: true
      });
    }
  }
  return skeletalData;
};
