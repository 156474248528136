import React, { useCallback } from 'react';
import { Row } from 'antd';
import { Field, FastField } from 'formik';
import { MEDICAL_STAGE_LIST } from 'utils/constants/lists';
import { mapToOption } from 'utils/mappers/form';
import { FormItem, Select, TextArea } from 'views/containers/form';
import { ClientactiveStatus } from 'utils/constants/lists';
import { Checkbox } from 'antd';
import { Select as CustomizedSelect } from 'antd';
import MultiSelectDropDown from 'pages/provider/input/multiSelectDropDown';
import {
  CLIENT_PROVIDER_HEADER,
  CLIENT_LANGUAGES_HEADER
} from 'utils/constants/clientList';
import { CLIENT_EDIT_TYPE } from 'model/v2';
import searchIcon from 'assets/img/bars-search-search-bar.svg';
import { updateFinalResults as updatededClientOption } from 'utils/mappers/response/clients';

import { OptionType } from 'antd/lib/select';

const medicalStageOptions = mapToOption(MEDICAL_STAGE_LIST);

export const ClientProfileForm = React.memo(
  ({
    providerAllergies,
    disabledFormFields,
    corticaPrograms,
    setFieldValue,
    values,
    languages
  }) => {
    const { Option } = Select;

    const handleClientFieldSelect = useCallback(
      (fieldType, optionType, options) => {
        return function(value, selectedValues) {
          let mapSelectClientFieldFunc = updatededClientOption({
            selectedValues,
            editType: fieldType,
            calendarFilterData: { languages },
            optionType,
            value,
            selectedOptions: options
          });
          const mappedTabbleData = mapSelectClientFieldFunc.next().value;
          setFieldValue(fieldType, mappedTabbleData);
        };
      },
      [languages]
    );

    return (
      <>
        <div className="form-title">
          Profile
          <span className="optionalSpan">(optional)</span>
        </div>
        <Row type="flex" justify="start" align="top" gutter={16}>
          <FormItem optional label="Status" xs={16} sm={5}>
            <Field
              name="isActive"
              render={fieldProps => (
                <Select
                  options={ClientactiveStatus}
                  placeholder="Select Status"
                  {...fieldProps}
                />
              )}
            />
          </FormItem>
          <FormItem optional label="Phenotype (Overall)" xs={16} sm={5}>
            <Field
              name="profile.phenoType"
              render={fieldProps => (
                <Select
                  options={medicalStageOptions}
                  placeholder="Select"
                  {...fieldProps}
                  disabled={disabledFormFields.phenoType}
                />
              )}
            />
          </FormItem>
          <FormItem optional label="Language" xs={16} sm={5}>
            <MultiSelectDropDown
              placeholder={
                <div className="placerHolder-cont">
                  <div>
                    <span style={{ marginRight: '14px' }}>Search Provider</span>
                  </div>
                  <div className="search-icon-container">
                    <img
                      src={searchIcon}
                      className="search-icon-waitList"
                      alt=""
                    />
                  </div>
                </div>
              }
              maxTagCount={1}
              dropdownClassName={'custom-dropdown-select'}
              optionLabelProp="label"
              header={
                <div className="Provider-select-header">
                  {CLIENT_LANGUAGES_HEADER}
                </div>
              }
              optionLabelProp={'label'}
              maxTagCount={1}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              className={'provider-select-languages'}
              overAllOptions={languages}
              handleChange={handleClientFieldSelect(
                CLIENT_EDIT_TYPE.languages,
                'name'
              )}
              selectedOptions={values?.languages}
              selectId={1}
              selectType={CLIENT_EDIT_TYPE.languages}
              displayContent={'name'}
              optionMapType={'name'}
            ></MultiSelectDropDown>
          </FormItem>

          <FormItem optional label="Restricted Providers" xs={16} sm={5}>
            <MultiSelectDropDown
              selectId={0}
              overAllOptions={[]}
              filterOption={false}
              selectedOptions={values?.restrictedProviders}
              handleChange={handleClientFieldSelect(
                CLIENT_EDIT_TYPE.restrictedProviders,
                'id',
                values?.restrictedProviders
              )}
              optionLabelProp={'label'}
              maxTagCount={1}
              dropdownClassName={'custom-dropdown-select'}
              placeholder={
                <div className="placerHolder-cont">
                  <div>
                    <span style={{ marginRight: '14px' }}>
                      Search Languages
                    </span>
                  </div>
                  <div className="search-icon-container">
                    <img
                      src={searchIcon}
                      className="search-icon-waitList"
                      alt=""
                    />
                  </div>
                </div>
              }
              className={'provider-select-languages  provider-select-options'}
              header={
                <div className="Provider-select-header">
                  {CLIENT_PROVIDER_HEADER}
                </div>
              }
              selectType={CLIENT_EDIT_TYPE.restrictedProviders}
              displayContent={'displayName'}
              optionMapType={'id'}
            ></MultiSelectDropDown>
          </FormItem>
          <FormItem optional label="Allergies" xs={16} sm={5}>
            <Field
              name="petAllergies"
              render={fieldProps => (
                <Select
                  options={providerAllergies}
                  placeholder="Select"
                  {...fieldProps}
                  mode="multiple"
                />
              )}
            />
          </FormItem>
          <FormItem
            optional
            label="Cortica Programs"
            labelAlign="left"
            xs={16}
            sm={5}
          >
            <Field
              name="corticaPrograms"
              render={fieldProps => (
                <Select
                  options={corticaPrograms || []}
                  placeholder="Select Cortica Programs"
                  {...fieldProps}
                  mode="multiple"
                />
              )}
            />
          </FormItem>
          <FormItem optional label="Behavior" xs={16} sm={5}>
            <Field
              name="profile.behavior"
              render={fieldProps => (
                <Select
                  options={medicalStageOptions}
                  placeholder="Select"
                  {...fieldProps}
                  disabled={disabledFormFields.behavior}
                />
              )}
            />
          </FormItem>
          <FormItem optional label="Cognition" xs={16} sm={5}>
            <Field
              name="profile.cognition"
              render={fieldProps => (
                <Select
                  options={medicalStageOptions}
                  placeholder="Select"
                  {...fieldProps}
                  disabled={disabledFormFields.cognition}
                />
              )}
            />
          </FormItem>
          <FormItem optional label="Communication" xs={16} sm={5}>
            <Field
              name="profile.communication"
              render={fieldProps => (
                <Select
                  options={medicalStageOptions}
                  placeholder="Select"
                  {...fieldProps}
                  disabled={disabledFormFields.communication}
                />
              )}
            />
          </FormItem>
          <FormItem optional label="Sensorimotor" xs={16} sm={5}>
            <Field
              name="profile.sensorimotor"
              render={fieldProps => (
                <Select
                  options={medicalStageOptions}
                  placeholder="Select"
                  {...fieldProps}
                  disabled={disabledFormFields.sensorimotor}
                />
              )}
            />
          </FormItem>
        </Row>
        <Row type="flex" justify="start" align="top" gutter={16}>
          <FormItem optional label="Notes" xs={16} sm={12}>
            <FastField
              name="profile.note.note"
              render={({ field }) => (
                <TextArea
                  name="profile.note.note"
                  value={field.value}
                  placeholder="Notes"
                  onChange={field.onChange}
                  disabled={disabledFormFields.notes}
                />
              )}
            />
          </FormItem>
        </Row>
      </>
    );
  }
);
