import { yupResolver } from '@hookform/resolvers/yup';
import {
  ICalendarFilterOption,
  ICalendarResource,
  IRecurringReqData
} from 'model/calendar/filters';
import {
  IFullAppointmentType,
  ISmartScheduleOpenings,
  ISmartResultListForm,
  IClient,
  ISmartResultCard,
  ISmartScheduleConsolidatedResults,
  ICarePlanFieldsForSmartScheduling,
  IAddress,
  IEvent
} from 'model/v2';
import { useRemoveProviderFromCalendar } from 'pages/MainCalendarPage/FormPhantomEvents/CustomPhantomHooks';
import SmartPhantomEvents from 'pages/MainCalendarPage/FormPhantomEvents/SmartPhantomEvents';
import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getDay } from 'utils/format';
import { getDefaultResultsList } from 'utils/mappers/smartSchedule';
import { SMART_SCHEDULE_SELECT_RESULT } from 'utils/validators/smartSchedule';
import SmartFormFooter from '../Footer/Smart/SmartFormFooter';
import { ResultListWrapper } from './Style';
import RecurringSSFilterInput from './RecurringSSFilterInput';
import RecurringSSPostSearchRangeFilters from './SmartScheduleRangeResults/RecurringSSPostSearchRangeFilters';
import { isCheckedIndex } from 'utils/validators/smartSchedule';
import Content from 'views/components/ui/content';
import { GET_CLIENT_ADDRESSES } from 'api/graphql/v2/queries/Clients';
import { useLazyQuery, useQuery } from 'react-apollo';
import CarePlanInformation from './CarePlanInformation';
import moment from 'antd/node_modules/moment';
import {
  DTA_SPECIALITIES,
  GUARANTEED_SHIFT_BI_SPECIALITIES
} from 'utils/constants/appointmentsTypes';
import { Accordion, AccordionSummary } from '@material-ui/core';
import { GET_UNIQUE_CLIENTS_DATA } from 'api/graphql/v2/queries/Providers';
import { useLocation } from 'react-router';
import RecurringResultProviderGrouping from './RecurringResultProviderGrouping';
import { GET_UNAVAILABLE_PTO_EVENTS } from 'api/graphql/v2/queries/Events';

interface ListSection {
  data: [string, ISmartScheduleConsolidatedResults][];
  header: string;
}
interface IProps {
  appointmentTypeId: number;
  smartResults: ISmartScheduleOpenings;
  setSmartResults: Dispatch<React.SetStateAction<ISmartScheduleOpenings>>;
  action: string;
  providersMap: Map<number, ICalendarResource>;
  apptTypes: IFullAppointmentType[];
  client: IClient;
  paymentMethod: string;
  setVisible: (val: boolean) => void;
  visibleResults: boolean;
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<React.SetStateAction<ICalendarResource[]>>;
  isAba: boolean;
  appointmentSubTypeId?: number;
  hasSingleDT: any;
  adminTypes?: IFullAppointmentType[];
  reqData: IRecurringReqData;
  setReqData: Dispatch<React.SetStateAction<IRecurringReqData>>;
  setOpeningCardsStatus: Dispatch<React.SetStateAction<boolean>>;
  openingCardsStatus: boolean;
  smartLoading: boolean;
  sessionDuration: number;
  carePlanInfo: ICarePlanFieldsForSmartScheduling;
  isWaitlistDataLoading: boolean;
}
const RecurringSSResultList: React.FC<IProps> = ({
  appointmentTypeId,
  smartResults,
  action,
  providersMap,
  apptTypes,
  client,
  paymentMethod,
  setVisible,
  visibleResults,
  paginationArray,
  setPaginationArray,
  isAba,
  appointmentSubTypeId,
  adminTypes,
  reqData,
  setReqData,
  hasSingleDT,
  setOpeningCardsStatus,
  openingCardsStatus,
  smartLoading,
  sessionDuration,
  carePlanInfo,
  isWaitlistDataLoading
}: IProps) => {
  const [selectedCards, setSelectedCards] = useState(() => new Set<number>());
  const [openingCards, setOpeningCards] = useState<ISmartResultCard[]>([]);
  const [selectedValue, setSelectedValue] = useState<number>(0);
  const [providerUniqueClientCount, setProviderUniqueClientCount] = useState<
    Record<number, number>
  >({});
  const [unavailablePTOEvents, setUnavailablePTOEvents] = useState<
    Record<number, IEvent[]>
  >({});

  const [statistics, setStatistics] = useState({
    careplan: 0,
    clientBooked: 0,
    providerBooked: 0
  });

  const [providersExpanded, setProvidersExpanded] = useState<string[]>([]);

  const providerSSResults: Map<
    string,
    ISmartScheduleConsolidatedResults
  > = useMemo(() => {
    const providerMap = new Map<string, ISmartResultCard[]>();
    openingCards.map(card => {
      const providerName = card.provider?.name!;
      if (!providerMap.has(providerName)) {
        providerMap.set(providerName, [card]);
      } else {
        providerMap.get(providerName)!.push(card);
      }
    });
    const consolidatedResults = new Map<
      string,
      ISmartScheduleConsolidatedResults
    >();
    for (const [providerName, smartResultCards] of providerMap) {
      const availableDays: number[] = [];
      let totalAvailableDuration = '0 sessions';
      if (isAba) {
        const totalHours =
          smartResultCards[0].maxBookableMinutes! > 0
            ? parseFloat(
                (smartResultCards[0].maxBookableMinutes! / 60).toFixed(2)
              )
            : 0;
        totalAvailableDuration = `${totalHours | 0} hours`;
      } else {
        const totalSessions =
          smartResultCards[0].maxBookableMinutes! > 0
            ? Math.floor(
                smartResultCards[0].maxBookableMinutes! / sessionDuration
              )
            : 0;
        totalAvailableDuration = `${totalSessions | 0} sessions`;
      }
      smartResultCards.forEach(card => {
        if (!availableDays.includes(card.dayOfWeek))
          availableDays.push(card.dayOfWeek);
      });
      consolidatedResults.set(providerName, {
        openingCards: smartResultCards,
        totalAvailableDuration: totalAvailableDuration,
        availableDays: availableDays.length
      });
    }
    return consolidatedResults;
  }, [openingCards, isAba, sessionDuration]);

  const providersSortedByAvailability = useMemo(() => {
    const providersSortedByAvail = Array.from(providerSSResults!).sort(
      (a, b) => {
        const cardAData = a[1];
        const cardBData = b[1];
        if (cardAData.availableDays && cardBData.availableDays) {
          if (cardAData.availableDays > cardBData.availableDays) return -1;
          else if (cardBData.availableDays > cardAData.availableDays) return 1;
          else {
            if (
              cardAData.totalAvailableDuration &&
              cardBData.totalAvailableDuration
            ) {
              const cardATotalUnits = Number(
                cardAData.totalAvailableDuration.split(' ')[0]
              );
              const cardBTotalUnits = Number(
                cardBData.totalAvailableDuration.split(' ')[0]
              );
              if (cardATotalUnits > cardBTotalUnits) return -1;
              else if (cardBTotalUnits > cardATotalUnits) return 1;
            }
          }
        }
        return 0;
      }
    );
    return providersSortedByAvail;
  }, [providerSSResults]);

  const smartListSections = useMemo(() => {
    const sections: ListSection[] = [];
    if (isAba) {
      const guaranteedHourSection: ListSection = {
        data: [],
        header: 'Guaranteed BIs'
      };
      const otherSection: ListSection = {
        data: [],
        header: 'BIs'
      };
      providersSortedByAvailability.forEach(provider => {
        const cardData = provider[1];
        const providerSpecialtyId =
          cardData.openingCards[0].provider.speciality?.id;
        if (
          providerSpecialtyId &&
          GUARANTEED_SHIFT_BI_SPECIALITIES[
            process.env.REACT_APP_STAGE!
          ].includes(providerSpecialtyId)
        ) {
          guaranteedHourSection.data.push(provider);
        } else {
          otherSection.data.push(provider);
        }
      });
      sections.push(guaranteedHourSection);
      sections.push(otherSection);
    } else {
      // for non-ABA we just want a single section ordered by avails
      sections.push({
        data: providersSortedByAvailability,
        header: 'All Providers'
      });
    }
    return sections;
  }, [isAba, providersSortedByAvailability]);

  const defaultResults: ISmartResultListForm = useMemo(() => {
    const sortedOpeningCards = smartListSections.flatMap(section => {
      const sectionCards = section.data.flatMap(card => {
        const { openingCards } = card[1];
        return openingCards;
      });
      return sectionCards;
    });
    const defaultResults = getDefaultResultsList(
      sortedOpeningCards,
      client?.id,
      paymentMethod
    );
    return defaultResults;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client?.id, openingCards, paymentMethod, visibleResults]);

  const methods = useForm<ISmartResultListForm>({
    defaultValues: defaultResults,
    resolver: yupResolver(SMART_SCHEDULE_SELECT_RESULT()),
    mode: 'all',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange'
  });

  const uniqueProviders: number[] = useMemo(() => {
    const providersSet = new Set<number>();
    smartResults.openingCards.map(card => {
      if (!providersSet.has(card.provider.id!)) {
        providersSet.add(card.provider.id!);
      }
    });
    return Array.from(providersSet);
  }, [smartResults.openingCards]);

  const dtaSpecialities: number[] = useMemo(() => {
    const specialitiesSet = new Set<number>();
    openingCards.map(card => {
      if (
        !specialitiesSet.has(card.provider.speciality!.id!) &&
        DTA_SPECIALITIES[process.env.REACT_APP_STAGE!].includes(
          card.provider.speciality!.id!
        )
      ) {
        specialitiesSet.add(card.provider.speciality!.id!);
      }
    });
    return Array.from(specialitiesSet);
  }, [openingCards]);

  const { reset } = methods;

  const calculateStatistics = useCallback(
    (currentSelectedCards: Set<number>) => {
      const selectedResults: any[] = [];
      const formCards = methods.getValues()?.results;
      currentSelectedCards.forEach(it => {
        selectedResults.push(formCards[it]);
      });

      let careplan;
      if (isAba) {
        careplan = selectedResults.reduce(
          (sum, it) => sum + ((it?.endTimeForm - it?.startTimeForm) / 60 || 0),
          0
        );
      } else {
        careplan = selectedResults.length;
      }
      let copy = { ...statistics };

      const clientBooked = new Set(
        selectedResults.map(it => getDay(it.startDate))
      ).size;
      const providerBooked = new Set(
        selectedResults.map(it => {
          return it?.provider?.id;
        })
      ).size;
      copy = {
        ...copy,
        careplan: Number(careplan.toFixed(2)),
        clientBooked,
        providerBooked
      };

      setStatistics(copy);
    },
    [isAba, methods, statistics]
  );

  React.useEffect(() => {
    if (openingCardsStatus && Array.from(selectedCards).length > 0) return;
    reset(defaultResults);
    const set = new Set<number>();
    openingCards.forEach((_, index) => {
      if (index < reqData.weeklySessions) {
        methods.setValue(isCheckedIndex(index), true);
        set.add(index);
      } else {
        methods.setValue(isCheckedIndex(index), false);
      }
    });
    setSelectedCards(set);
    calculateStatistics(set);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultResults, openingCards, reqData]);

  const { removeAllProvidersFromCalendar } = useRemoveProviderFromCalendar();

  const updateSelectedCards = useCallback(
    (idx: number, isChecked: boolean) => {
      let currentSelectedCards = new Set(selectedCards);
      setPaginationArray([]);
      removeAllProvidersFromCalendar();
      if (isChecked) {
        currentSelectedCards.add(idx);
      } else {
        currentSelectedCards.delete(idx);
      }
      setSelectedCards(currentSelectedCards);
      calculateStatistics(currentSelectedCards);
    },
    [
      calculateStatistics,
      removeAllProvidersFromCalendar,
      selectedCards,
      setPaginationArray
    ]
  );

  const formVals = methods.watch();

  useEffect(() => {
    if (isAba) {
      let totalMins = 0;
      Array.from(selectedCards).forEach(index => {
        const startTime = moment(formVals.results[index].startTime);
        const endTime = moment(formVals.results[index].endTime);
        const diffInMins = endTime.diff(startTime, 'minutes');
        totalMins = totalMins + diffInMins;
      });
      const totalHours =
        totalMins > 0 ? parseFloat((totalMins / 60).toFixed(2)) : 0;
      setSelectedValue(totalHours);
    } else {
      setSelectedValue(Array.from(selectedCards).length);
    }
  }, [setSelectedValue, selectedCards, isAba, formVals]);

  const clientId = client.id;

  const {
    data: addressesData
  }: {
    data:
      | {
          clientAddresses: IAddress[];
        }
      | undefined;
  } = useQuery(GET_CLIENT_ADDRESSES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      clientId
    },
    skip: !clientId
  });

  const [
    getPatientCountData,
    { data: patientsCountData, loading: isPatientsCountDataLoading }
  ] = useLazyQuery(GET_UNIQUE_CLIENTS_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      const newItems = patientsCountData.getUniqueClientsData.reduce(
        (
          acc: Record<number, number>,
          val: { providerId: number; clientCount: number }
        ) => {
          acc[val.providerId] = val.clientCount;
          return acc;
        },
        {}
      );
      setProviderUniqueClientCount(newItems);
    }
  });

  useEffect(() => {
    if (dtaSpecialities!.length) {
      getPatientCountData({
        variables: {
          specialityId: dtaSpecialities
        }
      });
    }
  }, [dtaSpecialities]);

  const [
    getUnavailableAndPTOEvents,
    { data: unavailableEvents, loading: isUnavailableAndPTOEventsLoading }
  ] = useLazyQuery(GET_UNAVAILABLE_PTO_EVENTS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      const newItems = unavailableEvents.getUnavailableAndPTOEvents.reduce(
        (acc: Record<number, IEvent[]>, val: IEvent) => {
          const providerId = val.provider?.id!;
          if (!acc[providerId]) {
            acc[providerId] = [];
          }
          acc[providerId].push(val);
          return acc;
        },
        {}
      );

      setUnavailablePTOEvents(newItems);
    }
  });
  useEffect(() => {
    if (uniqueProviders!.length) {
      getUnavailableAndPTOEvents({
        variables: {
          providerIds: uniqueProviders,
          endDate: reqData.data?.endDate!
        }
      });
    }
  }, [uniqueProviders]);

  useEffect(() => {
    const providersWithClientCount: Record<number, number> = {};
    if (patientsCountData) {
      patientsCountData.getUniqueClientsData?.forEach(
        (val: { providerId: number; clientCount: number }) => {
          providersWithClientCount[val.providerId] = val.clientCount;
        }
      );
    }
  }, [patientsCountData]);

  const rangeBasedResults = !!smartResults.openingCards[0]?.timeRanges;
  const [accordianFirstLoad, setAccordianFirstLoad] = useState(true);
  useEffect(() => {
    if (providersExpanded.length > 0) return;
    const firstSectionWithProviders = smartListSections.find(
      section => section.data.length
    );
    const firstProviderName = firstSectionWithProviders?.data?.[0]?.[0];
    if (firstProviderName && accordianFirstLoad) {
      setAccordianFirstLoad(false);
      setProvidersExpanded([firstProviderName]);
    }
  }, [
    smartListSections,
    setProvidersExpanded,
    providersExpanded.length,
    accordianFirstLoad
  ]);

  const toggleProvider = useCallback(
    (newProvider: string) => {
      if (providersExpanded.includes(newProvider)) {
        setProvidersExpanded(prev => prev.filter(p => p !== newProvider));
      } else {
        setProvidersExpanded(prev => [...prev, newProvider]);
      }
    },
    [setProvidersExpanded, providersExpanded]
  );

  let index = 0;

  const location = useLocation();

  const params = useMemo(() => new URLSearchParams(location?.search), [
    location
  ]);

  // Memoized filteredSpecialities to avoid recalculating on each render
  const filteredSpecialities: ICalendarFilterOption[] = useMemo(() => {
    if (params.get('guaranteedShiftBiRss') !== '3') return [];
    let openingCards: ISmartResultCard[] = JSON.parse(
      JSON.stringify(smartResults.openingCards)
    );
    const specialityData = openingCards
      .filter(card =>
        GUARANTEED_SHIFT_BI_SPECIALITIES[process.env.REACT_APP_STAGE!].includes(
          card.provider.speciality?.id!
        )
      )
      .map(result => result.provider.speciality);

    const uniqueAbbreviations = Array.from(
      new Set(specialityData.map(speciality => speciality?.abbreviation))
    );

    return uniqueAbbreviations.map(abbreviation => {
      const speciality = specialityData.find(
        speciality => speciality?.abbreviation === abbreviation
      );

      return {
        label: speciality?.abbreviation!,
        value: speciality?.abbreviation!.toString()!
      };
    });
  }, [params, smartResults]);

  const makeCollapsibleProviderSection = (
    providerName: string,
    providerResults: ISmartScheduleConsolidatedResults,
    selectionIndex: number
  ) => {
    return (
      <RecurringResultProviderGrouping
        providerName={providerName}
        providerResults={providerResults}
        loading={isPatientsCountDataLoading}
        expanded={providersExpanded.includes(providerName)}
        handleExpandToggle={toggleProvider}
        uniqueClientsCount={
          providerUniqueClientCount![
            providerResults.openingCards[0].provider?.id!
          ] || 0
        }
        clientTimezone={smartResults.clientTimezone!}
        appointmentTypeTitle={
          apptTypes.find(it => it.id === appointmentTypeId)?.title || ''
        }
        clientAddresses={addressesData?.clientAddresses || []}
        index={selectionIndex}
        updateSelectedCards={updateSelectedCards}
        setOpeningCards={setOpeningCards}
        providersMap={providersMap}
      />
    );
  };

  return (
    <ResultListWrapper>
      <FormProvider {...methods}>
        <div className="filter-cls">
          <div className="flex-grow">
            {rangeBasedResults ? (
              <RecurringSSPostSearchRangeFilters
                smartResults={smartResults}
                setOpeningCards={setOpeningCards}
                isAba={isAba}
                reqData={reqData}
                setReqData={setReqData}
                setOpeningCardsStatus={setOpeningCardsStatus}
                setSelectedCards={setSelectedCards}
                smartLoading={smartLoading || isUnavailableAndPTOEventsLoading}
                clientTimezone={smartResults.clientTimezone!}
                openingCards={openingCards}
                filteredSpecialities={filteredSpecialities}
                setPaginationArray={setPaginationArray}
                unavailablePTOEvents={unavailablePTOEvents}
              />
            ) : (
              <RecurringSSFilterInput
                smartResults={smartResults}
                setOpeningCards={setOpeningCards}
                isAba={isAba}
                reqData={reqData}
                setReqData={setReqData}
                setOpeningCardsStatus={setOpeningCardsStatus}
                setSelectedCards={setSelectedCards}
                smartLoading={smartLoading || isUnavailableAndPTOEventsLoading}
                openingCards={openingCards}
                setPaginationArray={setPaginationArray}
                unavailablePTOEvents={unavailablePTOEvents}
              />
            )}
            <Content
              loading={smartLoading || isUnavailableAndPTOEventsLoading}
              data={smartResults}
            >
              {() => (
                <>
                  <CarePlanInformation
                    carePlanInfo={carePlanInfo!}
                    isAba={isAba}
                    isWaitlistDataLoading={isWaitlistDataLoading}
                    selectedValue={selectedValue}
                  ></CarePlanInformation>
                  <SmartPhantomEvents
                    providersMap={providersMap}
                    apptTypes={apptTypes}
                    client={client}
                    setPaginationArray={setPaginationArray}
                    paginationArray={paginationArray}
                    visibleResults={visibleResults}
                    hasSingleDT={hasSingleDT}
                    adminTypes={adminTypes!}
                    openingCardsStatus={openingCardsStatus}
                    openingCards={openingCards}
                    selectedCards={selectedCards}
                  />
                  {openingCards.length &&
                    smartListSections.length &&
                    smartListSections.map(section => {
                      return (
                        <Accordion
                          defaultExpanded={!!section.data.length}
                          square
                          TransitionProps={{ timeout: 50 }}
                        >
                          <AccordionSummary style={{ fontWeight: 'bold' }}>
                            {`${section.header} (${section.data.length})`}
                          </AccordionSummary>
                          <div>
                            {section.data.map(providerData => {
                              const [
                                providerName,
                                providerResults
                              ] = providerData;

                              const view = makeCollapsibleProviderSection(
                                providerName,
                                providerResults,
                                index
                              );
                              index += providerResults.openingCards.length; // this ensures index always increases enough to avoid collisions between cards in diff providerGroups and sections
                              return view;
                            })}
                          </div>
                        </Accordion>
                      );
                    })}
                  {openingCards.length === 0 && (
                    <p className="error-msg">
                      No results were found for this criteria. Please adjust the
                      filters and try again.
                    </p>
                  )}
                </>
              )}
            </Content>
          </div>
          <div className="footer-ss-cls">
            <SmartFormFooter
              setVisible={setVisible}
              action={action}
              noSelectedSlots={
                methods.getValues('results').filter(it => it.isChecked)
                  .length === 0 || isUnavailableAndPTOEventsLoading
              }
              appointmentSubTypeId={appointmentSubTypeId}
              hasSingleDT={hasSingleDT}
            />
          </div>
        </div>
      </FormProvider>
    </ResultListWrapper>
  );
};
export default React.memo(RecurringSSResultList);
