import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import Header from '../../../components/header';
import { ContentWrapper } from './style';
import plus from '../../../assets/img/Plus.png';
import Trash from '../../../assets/img/Trash.png';
import searchIcon from 'assets/img/bars-search-search-bar.svg';

import {
  useForm,
  FormProvider,
  Controller,
  ControllerRenderProps,
  useWatch,
  useFieldArray
} from 'react-hook-form';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import {
  GET_PROVIDER_OPTIONS_DATA,
  GET_CLINIC_ASSISTANT_PROVIDERS,
  GET_PROVIDER_SUPERVISOR,
  PET_ALLERGIES,
  GET_PROVIDER_INITIAL_LOAD_DATA
} from 'api/graphql/v2/queries/Providers';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  STAFF_EDIT_FORM_SCHEMA,
  STAFF_ADD_FORM_SCHEMA
} from 'utils/validators/StaffForm';
import EditableLabel from './EditableLabel';
import MainButton from 'views/components/button';
import { Checkbox, Grid, Input } from 'semantic-ui-react';
import Content from 'views/components/ui/content';
import {
  IAppointmentType,
  IClinic,
  IEditProviderForm,
  IProvider,
  IProviderSpecialty,
  Provider,
  PROVIDER_EDIT_TYPE,
  PROVIDER_EMPLOYMENT_LIST,
  PROVIDER_SPECIALITY_LIST,
  PROVIDER_STATUS_LIST,
  SUPERVISOR_SPECIALITY_LIST_ID
} from 'model/v2';
import {
  GET_PROVIDERS_LIST,
  GET_PROVIDER_MEMBER,
  IMPORT_PROVIDER_FROM_MS
} from 'api/graphql/v2/queries/Providers';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import {
  ADD_NEW_PROVIDER,
  UPDATE_PROVIDER
} from 'api/graphql/v2/mutations/Providers';
import {
  MapProviderPetAllergies,
  cloneWithoutTypename,
  mapProviderAddress
} from 'utils/common';
import { mapProviderSubmittedForm } from 'utils/mappers/request/Provider';
import { mapProviderFormDefaultValues } from 'utils/mappers/response/Provider';
import FormItem from 'antd/lib/form/FormItem';
import { Departments } from 'utils/mappers/availabilityTemplate';
import TelehealthSelect from './TelehealthSelect';
import {
  mapAppointmentTypesToOptions,
  mapStringArrayToOptions,
  mapTitleToOptions,
  mapAssistantsToOptions,
  mapToOption,
  mapCorticaProgramOptions,
  mapCertificationOptions
} from 'utils/mappers/form';
import { formatClinicAbbreviation } from 'utils/format';
import { DEFAULT_TELEHEALTH } from 'utils/constants/default';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { DEFAULT_SORT } from 'utils/constants/default';
import { Icon } from 'semantic-ui-react';
import TelehealthLinksInput from './telehealthLinksInput';
import { NURSE_PRACTITIONER_SPECIALTY_ID_MAPPING } from 'utils/constants/appointmentsTypes';
import { GET_ALL_APPT_TYPES } from 'api/graphql/v2/queries/AppointmentTypes';
import { PROVIDER_GENDER_LIST, TRUE_FALSE_LIST } from 'utils/constants/lists';
import { US_STATES_LIST } from 'utils/constants/commonLists';
import { GET_ALL_CORTICA_PROGRAMS } from 'api/graphql/v2/queries/Programs';
import { CLIENT_LANGUAGES_HEADER } from 'utils/constants/clientList';
import MultiSelectDropDown from '../input/multiSelectDropDown';
import { updateFinalResults as updatedProviderOption } from 'utils/mappers/response/clients';
import { GET_ALL_CERTIFICATIONS } from 'api/graphql/v2/queries/Certifications';
import { List, ListItem, TextField } from '@material-ui/core';

const EMAIL_PLACEHOLDER = 'example@corticadev.onmicrosoft.com';

interface IntProvider {
  find: any;
  filter: (arg0: (mappingData: any) => boolean) => any;
  value?: number;
  label?: string;
}

interface IAssistant {
  value: number;
  label: string;
}

interface ParamTypes {
  id: string;
}

//detects props and navigates to edit or add flow
const AddProvider: React.FC = () => {
  const history = useHistory();
  const [validEmail, setValidEmail] = useState(true);
  const [onChangeClinic, setChangeClinic] = useState(true);

  const { id } = useParams<ParamTypes>();

  const { loading: providerDataLoading, data: providerData } = useQuery(
    GET_PROVIDER_MEMBER,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        id: Number(id)
      },
      onCompleted: providerData => {
        const newDefaultValues = mapProviderFormDefaultValues(
          providerData.provider
        );
        methods.reset(newDefaultValues);
      },
      onError: error => {
        console.error('Error when querying to fetch a provider:', error);
      }
    }
  );

  // const props: IProps = location.state as IProps;
  const [previousSelectedState, setPreviousSelectedState] = useState<
    string | undefined
  >(providerData?.provider?.address?.state);
  const [providerSelectedGender, setProviderSelectedGender] = useState<
    string | null
  >(providerData?.provider?.gender!);
  const [providerAllergies, setProviderAllergies] = useState([]);
  const stateOptions = mapToOption(US_STATES_LIST);

  const { loading: providerOptionsLoading, error, data } = useQuery(
    GET_PROVIDER_OPTIONS_DATA,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [GetPetAllergyType] = useLazyQuery(PET_ALLERGIES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: Number(id)
    },
    onCompleted: data => {
      const Allergic_Mapped = data.ProviderPetAllergies.map((item: any) => ({
        value: item.id,
        label: item.value
      }));
      setProviderAllergies(Allergic_Mapped);
    }
  });

  useEffect(() => {
    GetPetAllergyType();
  }, [GetPetAllergyType]);

  const methods = useForm<IEditProviderForm>({
    defaultValues: mapProviderFormDefaultValues(providerData?.provider),
    resolver: yupResolver(
      providerData?.provider
        ? STAFF_EDIT_FORM_SCHEMA(data?.allAppointmentTypes)
        : STAFF_ADD_FORM_SCHEMA(data?.allAppointmentTypes)
    )
  });

  const [onAssistantSelect, setOnAssistantSelect] = useState<
    { value: string; label: string }[]
  >([]);
  const [onAssistantDefault, setOnDefaultAssistant] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [onAssistantView, setOnAssistantView] = useState<IAssistant[]>();
  const [getSpecialitySelect, setOnSpecialitySelect] = useState<number>();
  const [getClinicSelect, setOnClinicSelect] = useState<number>();

  var [addressId, setAddressId] = useState(
    providerData?.provider?.address?.id
      ? providerData?.provider?.address?.id
      : null
  );
  const [telehealthLinks, setTelehealthLinks] = useState<string[]>(
    providerData?.provider?.telehealthLinks!
  );

  const [filteredTelehealthLinks, setfilteredLinks] = useState<string[]>(
    telehealthLinks
  );
  let AddressTrigger = [
    Provider.addressLine1,
    Provider.city,
    Provider.state,
    Provider.zipCode
  ];

  const onGoBack = useCallback(() => {
    if (history.length > 1) {
      history.goBack();
    } else {
      const newPath = history.location.pathname.replace('/edit', '');
      history.replace(newPath);
    }
  }, [history]);

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
    getValues,
    setValue,
    trigger,
    control
  } = methods;

  const [isFormPrestine, setFormPrestine] = useState<boolean>(isDirty);

  const dirtyField = methods.formState.isDirty;

  useEffect(() => {
    setFormPrestine(dirtyField);
  }, [dirtyField]);

  const genderType = useMemo(() => {
    return PROVIDER_GENDER_LIST.map(item => ({
      value: item.value,
      label: item.id
    }));
  }, [PROVIDER_GENDER_LIST]);

  const trueFalseDropdownOptions = useMemo(() => {
    return TRUE_FALSE_LIST.map(item => ({
      value: item.value,
      label: item.id
    }));
  }, []);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'assistantsInput'
  });

  const { status, telehealth } = useWatch({
    control,
    name: [Provider.status, Provider.telehealth]
  });

  const specialityOptions = useMemo(
    () => mapTitleToOptions(data?.specialties),
    [data]
  );

  const onEmploymentSelect = useCallback(
    employment => {
      const statusVal = control.getValues(`${Provider.status}`);
      if (
        statusVal === PROVIDER_STATUS_LIST.TERMINATED ||
        statusVal === PROVIDER_STATUS_LIST.LEAVE_OF_ABSENCE
      )
        return;
      if (employment === PROVIDER_EMPLOYMENT_LIST.PART_TIME_HOURLY) {
        setValue(Provider.capacity, 16, { shouldDirty: true });
      } else {
        setValue(Provider.capacity, 32, { shouldDirty: true });
      }
    },
    [setValue, control]
  );

  const onGenderSelect = useCallback(
    gender => {
      if (providerSelectedGender === gender) {
        setValue(Provider.gender, null, { shouldDirty: true });
        setProviderSelectedGender(null);
      } else {
        setProviderSelectedGender(gender);
        setValue(Provider.gender, gender, { shouldDirty: true });
      }
    },
    [setValue, control, setProviderSelectedGender, providerSelectedGender]
  );

  const onSpecialitySelect = useCallback(
    specialityId => {
      const specialties = data?.specialties as IProviderSpecialty[];
      const specialty = specialties.find(
        (speciality: IProviderSpecialty) => speciality.id === specialityId
      );
      if (
        specialty?.title === PROVIDER_SPECIALITY_LIST.ABA_SUPERVISOR ||
        specialty?.title === PROVIDER_SPECIALITY_LIST.BCBA ||
        specialty?.title ===
          PROVIDER_SPECIALITY_LIST.BEHAVIOR_INTERVENTIONIST_ASSISTANT_SUPERVISOR
      ) {
        setValue(Provider.capacity, 25, { shouldDirty: true });
      } else if (specialty?.department === Departments.DT) {
        setValue(
          Provider.employment,
          PROVIDER_EMPLOYMENT_LIST.FULL_TIME_SALARY
        );
        onEmploymentSelect(PROVIDER_EMPLOYMENT_LIST.FULL_TIME_SALARY);
      } else if (specialty?.department === Departments.ABA) {
        setValue(
          Provider.employment,
          PROVIDER_EMPLOYMENT_LIST.PART_TIME_HOURLY
        );
        onEmploymentSelect(PROVIDER_EMPLOYMENT_LIST.PART_TIME_HOURLY);
      } else if (specialty?.department === Departments.MED) {
        setValue(
          Provider.employment,
          PROVIDER_EMPLOYMENT_LIST.FULL_TIME_SALARY
        );
        onEmploymentSelect(PROVIDER_EMPLOYMENT_LIST.FULL_TIME_SALARY);
      }

      setOnSpecialitySelect(specialty?.id);
    },
    [data, onEmploymentSelect, setValue, setOnSpecialitySelect]
  );

  const toggleAllowSelfScheduling = useCallback((_e, data) => {
    setValue(Provider.allowSelfScheduling, data.checked, {
      shouldDirty: true
    });
  }, []);

  const [add] = useMutation(ADD_NEW_PROVIDER);
  const [update] = useMutation(UPDATE_PROVIDER);

  const { addressLine1 } = getValues();

  const prepareSubmitData = (data: IEditProviderForm) => {
    data = {
      ...data,
      providerCapacity: {
        ...providerData?.provider?.providerCapacity,
        capacity: data.capacity
      },
      telehealthLinks: filteredTelehealthLinks?.length
        ? filteredTelehealthLinks
        : []
    };

    delete data.providerCapacity?.rampingPercentage;
    delete data.capacity;
    delete data.certificationMappings;
    return data;
  };

  const onSubmit = handleSubmit(async (data: IEditProviderForm) => {
    data = prepareSubmitData(data);
    let provider = cloneWithoutTypename(
      mapProviderSubmittedForm(
        data,
        providerData?.provider,
        msImportedData?.importProviderFromMs?.msId
      )
    );
    mapProviderAddress(data, provider, addressId);

    delete provider.addressLine1;
    delete provider.addressLine2;
    delete provider.city;
    delete provider.state;
    delete provider.zipCode;
    delete provider.rampingPercentage;
    delete provider.name;
    if (providerData?.provider) {
      const isProviderCapacityUpdateRequired =
        methods.formState.dirtyFields.capacity || false;
      await updateProvider(provider, isProviderCapacityUpdateRequired);
    } else {
      await addProvider(provider);
    }
  });

  const updateProvider = useCallback(
    async (provider: IProvider, isProviderCapacityUpdateRequired: boolean) => {
      await update({
        variables: {
          data: provider,
          isProviderCapacityUpdateRequired
        },
        refetchQueries: [
          {
            query: GET_PROVIDER_MEMBER,
            variables: {
              id: provider.id
            }
          }
        ]
      })
        .then(onGoBack)
        .catch(error => {
          console.log('Error while saving staff', JSON.stringify(error));
        });
    },
    [onGoBack, update]
  );

  const addProvider = useCallback(
    async (provider: IProvider) => {
      await add({
        variables: {
          data: provider
        },
        refetchQueries: [
          {
            query: GET_PROVIDERS_LIST
          }
        ]
      })
        .then(onGoBack)
        .catch(error => {
          console.log('Error while saving staff', JSON.stringify(error));
        });
    },
    [onGoBack, add]
  );

  const [
    importStaffFromMS,
    { loading: importLoading, data: msImportedData }
  ] = useLazyQuery(IMPORT_PROVIDER_FROM_MS, {
    variables: {
      email: getValues(Provider.email)
    },
    onCompleted: data => {
      if (data?.importProviderFromMs?.id || data?.importProviderFromMs?.msId) {
        const msData = data.importProviderFromMs;
        setValidEmail(true);
        setValue(Provider.isObservable, true);
        setValue(Provider.onAccommodation, false);
        setValue(Provider.isTestProvider, false);
        setValue(Provider.transportation, true);
        setValue(Provider.firstName, msData.firstName);
        setValue(Provider.lastName, msData.lastName);
        setValue(Provider.displayName, msData.displayName);
        setValue(Provider.mobilePhone, msData.mobilePhone);
        setValue(Provider.athenaId, msData.athenaId);
        setValue(Provider.crId, msData.crId);
        setValue(Provider.gender, msData.gender);
        setProviderSelectedGender(msData.gender);
        setValue(
          Provider.petAllergies,
          msData.petAllergies
            ? MapProviderPetAllergies(msData.petAllergies)
            : undefined
        );
        setValue(Provider.msId, msData.id);
        setValue(Provider.telehealth, msData.telehealth || null);
        setValue(`${Provider.speciality}.id`, msData.speciality?.id);
        setValue(`${Provider.clinic}.id`, msData.clinic?.id);
        setValue(
          Provider.appointmentTypes,
          msData.appointmentTypes?.map((item: IAppointmentType) => item.id)
        );
        if (msData.address) {
          setValue(Provider.addressLine1, msData.address.addressLine1);
          setValue(Provider.addressLine2, msData.address.addressLine2);
          setValue(Provider.city, msData.address.city);
          setValue(Provider.state, msData.address.state);
          setValue(Provider.zipCode, msData.address.zipCode);
          setPreviousSelectedState(msData.address.state);
          setAddressId(msData.address.id);
        }
        trigger([
          Provider.firstName,
          Provider.lastName,
          Provider.displayName,
          Provider.mobilePhone,
          `${Provider.speciality}.id`
        ]);
      } else {
        setValidEmail(false);
      }
    },
    onError: _err => {
      setValidEmail(false);
    }
  });

  const importUser = useCallback(() => {
    if (
      getValues(Provider.email) &&
      (getValues(Provider.email) as string).length !== 0
    ) {
      importStaffFromMS();
    }
  }, [getValues, importStaffFromMS]);

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false); // Flag to indicate if data is loaded

  const [verifyAssistant, { data: supervisorData }] = useLazyQuery(
    GET_PROVIDER_SUPERVISOR,
    {
      variables: {
        assistantId: providerData?.provider?.id
      }
    }
  );

  useEffect(() => {
    if (supervisorData && supervisorData?.getSupervisor) {
      const mappedData: IAssistant[] = [
        {
          value: supervisorData?.getSupervisor.id,
          label:
            supervisorData?.getSupervisor?.firstName +
            ' ' +
            supervisorData?.getSupervisor?.lastName
        }
      ];
      if (mappedData) {
        setOnAssistantView(mappedData);
        setIsDataLoaded(true);
        setValue('assistantsInput', null, { shouldDirty: false });
      }
    }
  }, [supervisorData, setValue]);

  useEffect(() => {
    if (providerData?.provider?.id) {
      verifyAssistant(); // Call the API to get array data
    }
  }, [providerData?.provider, verifyAssistant]);

  const employmentOptions = useMemo(
    () => mapStringArrayToOptions(data?.providerEmployments),
    [data]
  );

  const eventTypes = ['ABA', 'DT', 'MED'];

  const { data: allCorticaProgramOptions } = useQuery(
    GET_ALL_CORTICA_PROGRAMS,
    {
      onError: (error: any) => {
        console.error('Error fetching Cortica Program options: ', error);
      }
    }
  );

  const allCorticaProgramOptionsOptions = useMemo(() => {
    return mapCorticaProgramOptions(allCorticaProgramOptions);
  }, [allCorticaProgramOptions]);

  const { data: allCertificationOptions } = useQuery(GET_ALL_CERTIFICATIONS, {
    fetchPolicy: 'network-only',
    onError: (e: Error) => {
      console.error('Error fetching provider certification options: ', e);
    }
  });
  const certificationOptions: {
    value: number;
    certificationName: string;
    date: string;
  }[] = useMemo(() => {
    return mapCertificationOptions(
      allCertificationOptions?.providerCertifications,
      providerData?.provider?.certificationMappings
    );
  }, [allCertificationOptions, providerData]);

  const { data: appointmentActivityStatusdata } = useQuery(GET_ALL_APPT_TYPES, {
    variables: {
      eventTypes
    },
    fetchPolicy: 'cache-and-network'
  });

  const allAppointmentTypes =
    appointmentActivityStatusdata?.allAppointmentTypesConfig;

  const activeAppointmentTypesOptions = useMemo(() => {
    let activeAppointmentTypes: IAppointmentType[] = [];
    if (allAppointmentTypes) {
      activeAppointmentTypes = allAppointmentTypes.filter(
        (appointment: { isActive: any }) => appointment.isActive
      );
    }
    return mapAppointmentTypesToOptions(activeAppointmentTypes);
  }, [allAppointmentTypes]);

  const clinicsOptions = useMemo(
    () =>
      data?.clinicOptionsList.map((item: IClinic) => ({
        value: item.id,
        label: formatClinicAbbreviation(item, 'name', 'abbreviation')
      })),
    [data]
  );

  const getTelehealthLink = useCallback(() => {
    const telehealthValue = getValues('telehealth');
    if (telehealthValue && telehealthValue.length > 0) return telehealthValue;
    else {
      const clinic = data?.clinicOptionsList.find(
        (clinic: IClinic) =>
          `${clinic.id}` === `${providerData?.provider?.clinic?.id}`
      );
      if (clinic) {
        const defaultTeleHealthLink = [
          DEFAULT_TELEHEALTH.PREFIX +
            clinic?.address?.state +
            DEFAULT_TELEHEALTH.SUFFIX
        ];
        setValue('telehealth', defaultTeleHealthLink, {
          shouldDirty: true
        });
        return defaultTeleHealthLink;
      }
    }
    return [];
  }, [data, setValue, getValues, providerData?.provider]);

  const [
    importAssistantsData,
    { loading: importAssistLoading, data: importAssistdata }
  ] = useLazyQuery(GET_CLINIC_ASSISTANT_PROVIDERS, {
    variables: {
      clinicId: String(
        getClinicSelect !== undefined
          ? getClinicSelect
          : control.getValues(`${Provider.clinic}.id`) !== undefined
          ? control.getValues(`${Provider.clinic}.id`)
          : `${providerData?.provider?.clinic?.id}`
      ),
      providerId: providerData?.provider
        ? `${providerData?.provider?.id}`
        : String(control.getValues(`${Provider}.id`)),
      status: 'Edit',
      from: undefined,
      size: undefined,
      sort: DEFAULT_SORT
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      console.log(
        importAssistdata,
        importAssistLoading,
        'importAssistant Data'
      );
      const mappedData: IntProvider = mapAssistantsToOptions(
        data?.clinicAssistantProviders?.providers
      );
      const providertoRemove: any = `${providerData?.provider?.id}`;
      let setMappedData = mappedData?.filter(
        mappingData => mappingData.value != providertoRemove
      );
      const assistantArray: any = mapAssistantsToOptions(
        data?.getAssistantProvidersByProvider?.providers
      );
      if (assistantArray) {
        const setAssistantData: any = assistantArray.map(
          (providerData: any) => {
            return { value: providerData.value, label: providerData.label };
          }
        );
        const AssistantFieldValue = assistantArray.map((providerData: any) => {
          return {
            name: providerData.value,
            title: '',
            default: false,
            id: ''
          };
        });
        setValue(Provider.assistantsInput, AssistantFieldValue);
        setOnDefaultAssistant(setAssistantData);
      }
      setOnAssistantSelect(setMappedData);
      //setAssistantList(setMappedData);
      setValue('assistantsInput', '', {
        shouldDirty: false
      });
    },
    onError: _err => {
      console.log(_err, 'Error in assistant fetch');
      //setOnAssistantSelect([]);
    }
  });
  useEffect(() => {
    setValue(
      Provider.assistantsInput,
      onAssistantDefault.map((data: any) => {
        return {
          name: data.value,
          title: '',
          default: false,
          id: ''
        };
      })
    );
  }, [onAssistantDefault]);
  useEffect(() => {
    const providerSpeciality: number =
      getSpecialitySelect !== undefined
        ? Number(getSpecialitySelect)
        : control.getValues(`${Provider.speciality}.id`) !== undefined
        ? Number(control.getValues(`${Provider.speciality}.id`))
        : Number(`${providerData?.provider?.speciality?.id}`);
    if (getSpecialitySelect !== undefined) {
      for (let i = fields.length - 1; i >= 0; i--) {
        if (fields.length > 0) remove(i);
      }
      setOnAssistantSelect([]);
    }
    if (!isDataLoaded) {
      if (
        SUPERVISOR_SPECIALITY_LIST_ID[process.env.REACT_APP_STAGE!].includes(
          providerSpeciality
        )
      ) {
        importAssistantsData();
      } else {
        for (let i = fields.length - 1; i >= 0; i--) {
          if (fields.length > 0) remove(i);
        }
        setOnAssistantSelect([]);
        setChangeClinic(true);
      }
    }
  }, [
    importAssistantsData,
    setOnAssistantSelect,
    //fields,
    isDataLoaded,
    getSpecialitySelect,
    remove,
    providerData?.provider,
    control
  ]);

  const checkClinicSelect = (clinic: { id: number | undefined }) => {
    setOnClinicSelect(clinic?.id);
    const providerClinic = providerData?.provider
      ? providerData?.provider?.clinic?.id
      : Number(control.getValues(`${Provider.clinic}.id`));
    if (clinic?.id !== providerClinic) {
      if (isDataLoaded || fields.length > 0) {
        setValue(`${Provider.clinic}.id`, providerClinic, {
          shouldDirty: false
        });
        setChangeClinic(false);
        return;
      } else {
        const providerSpeciality: any = getSpecialitySelect
          ? getSpecialitySelect
          : Number(control.getValues(`${Provider.speciality}.id`));
        if (
          SUPERVISOR_SPECIALITY_LIST_ID[
            process.env.REACT_APP_STAGE || 'DEV'
          ].includes(providerSpeciality)
        ) {
          importAssistantsData();
        }
        setChangeClinic(true);
      }
    } else {
      setChangeClinic(true);
    }
  };
  useEffect(() => {
    // Update the defaultValues of the assistantsInput field
    const providerClinic = Number(
      providerData?.provider ? providerData?.provider?.clinic?.id : 0
    );
    const clinicNew = Number(
      getClinicSelect !== undefined
        ? getClinicSelect
        : control.getValues(`${Provider.clinic}.id`) !== undefined
        ? control.getValues(`${Provider.clinic}.id`)
        : `${providerData?.provider?.clinic?.id}`
    );
    if (onAssistantDefault && providerClinic === clinicNew) {
      const newValues = onAssistantDefault.map(data => {
        return { id: data.value, name: data.label };
      });
      setValue('assistantsInput', newValues, {
        shouldDirty: false
      });
    }
  }, [
    control,
    getClinicSelect,
    onAssistantDefault,
    providerData?.provider,
    setValue
  ]);

  const handleZipCodeChange = useCallback((_e, data) => {
    setValue(Provider.zipCode, data.value.replace(/[^0-9]/g, ''), {
      shouldDirty: true
    });
    trigger([Provider.zipCode]);
    handleAddressError();
  }, []);

  const handleAddressChange = useCallback(
    (event, addressType) => {
      if (addressType) {
        setValue(Provider.addressLine1, event.target.value, {
          shouldDirty: true
        });
      } else
        setValue('addressLine2', event.target.value, { shouldDirty: true });
      handleAddressError();
    },
    [addressLine1]
  );

  const handleAddressError = () => {
    trigger(AddressTrigger);
  };
  useEffect(() => {
    const elements = document.getElementsByName(Object.keys(methods.errors)[0]);
    if (isSubmitting) return;
    if (Object.keys(methods.errors).length > 0) {
      if (elements.length > 0) {
        elements[0].focus();
      }
    }
  }, [isSubmitting]);

  const [
    getProviderInitalLoadContent,
    { loading: ProviderInitialLoad, data: filterData }
  ] = useLazyQuery(GET_PROVIDER_INITIAL_LOAD_DATA, {
    fetchPolicy: 'cache-and-network'
  });

  const languages = useMemo(() => {
    if (filterData) {
      let {
        getProviderIntialLoadData: { filterList }
      } = filterData;
      return filterList?.languages || [];
    } else return [];
  }, [filterData]);

  useEffect(() => {
    getProviderInitalLoadContent();
  }, []);

  const handleCityChange = useCallback((_e, data) => {
    setValue(Provider.city, data.value, { shouldDirty: true });
    handleAddressError();
  }, []);

  const handleStateChange = useCallback(
    (_e, data) => {
      const selectedState = data.value;
      setPreviousSelectedState(data.value);
      if (previousSelectedState === selectedState) {
        setPreviousSelectedState(undefined);
        setValue(Provider.state, undefined);
      }
      handleAddressError();
    },
    [previousSelectedState, methods]
  );

  const handleProviderLanguageSelect = useCallback(
    (fieldType, optionType) => {
      return function(value: any, selectedValues: any) {
        let mapSelectedProviderFieldFunc: any = updatedProviderOption({
          selectedValues,
          editType: fieldType,
          calendarFilterData: { languages },
          optionType,
          value,
          selectedOptions: [],
          id: providerData?.provider?.id,
          filterLanguages: [],
          additionalUpdateFields: []
        });
        const mappedTabbleData = mapSelectedProviderFieldFunc?.next().value;

        setValue(Provider.languages, mappedTabbleData, { shouldDirty: true });
      };
    },
    [languages]
  );

  return (
    <>
      <Header
        title={providerData?.title || 'Edit Staff Info'}
        onSubmit={onSubmit}
        onCancel={onGoBack}
        buttonText="Save Staff"
        disabled={isSubmitting || !isFormPrestine}
        loading={isSubmitting}
      >
        {!providerData?.provider && (
          <MainButton
            onClick={importUser}
            title={'Import'}
            loading={importLoading}
            disabled={importLoading || providerOptionsLoading}
          />
        )}
      </Header>

      <Content
        loading={
          providerOptionsLoading || providerDataLoading || ProviderInitialLoad
        }
        error={error}
        data={data}
        fullHeight={true}
      >
        {() => (
          <ContentWrapper>
            <FormProvider {...methods}>
              <Grid className="provider-info-outer-cont">
                {/* <Grid.Row> */}

                <Grid.Row columns={1} className={'grid-row'}>
                  <Grid.Column>
                    <label className="basic-info">Basic Information</label>
                  </Grid.Column>
                </Grid.Row>

                {/* <Grid.Row> */}

                <Grid>
                  <Grid.Row
                    columns={4}
                    className={'grid-row basic-grid-row basic-info-grid-one'}
                  >
                    <Grid.Column className="label-style">
                      <EditableLabel
                        title={'Staff First Name'}
                        name={Provider.firstName}
                        label={providerData?.provider?.firstName}
                        placeholder={'Staff First Name'}
                        disabled={true}
                        edit={!providerData?.provider}
                      />
                    </Grid.Column>
                    <Grid.Column className="label-style grid-second-Cont">
                      <EditableLabel
                        title={'Staff Last Name'}
                        name={Provider.lastName}
                        label={providerData?.provider?.lastName}
                        placeholder={'Staff Last Name'}
                        disabled={true}
                        edit={!providerData?.provider}
                      />
                    </Grid.Column>
                    <Grid.Column className="label-style">
                      <EditableLabel
                        title={'Display Name'}
                        name={Provider.displayName}
                        label={providerData?.provider?.displayName}
                        placeholder={'Display Name'}
                        className="DisplayName"
                        disabled={true}
                        edit={!providerData?.provider}
                      />
                    </Grid.Column>

                    <Grid.Column className="info-last-container">
                      <Grid.Column
                        className={
                          !providerData?.provider
                            ? 'narrow-column-provider-opt '
                            : 'narrow-column-provider-opt basic-cont '
                        }
                      >
                        <FormItem
                          label="Gender:"
                          labelAlign="left"
                          className={
                            'grid-column-emp-provider-opt inner-Grid-last-cont provider-label'
                          }
                        >
                          <Controller
                            control={methods.control}
                            name={Provider.gender}
                            render={(
                              fieldProps: ControllerRenderProps<IProvider>
                            ) => (
                              <Select
                                onSelect={onGenderSelect}
                                options={genderType}
                                placeholder="Select Gender"
                                field={fieldProps}
                                errors={methods.errors}
                              />
                            )}
                          />
                        </FormItem>
                      </Grid.Column>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row
                    columns={4}
                    className={'grid-row basic-grid-row grid-second-row'}
                  >
                    <Grid.Column className="label-style">
                      <EditableLabel
                        title={'Email'}
                        name={Provider.email}
                        label={providerData?.provider?.email}
                        placeholder={EMAIL_PLACEHOLDER}
                        disabled={false}
                        edit={!providerData?.provider}
                        className={'email'}
                      />
                      {!validEmail && (
                        <label className={'warning'}>
                          {'Email is not valid'}
                        </label>
                      )}
                    </Grid.Column>
                    <Grid.Column className="label-style grid-second-Cont">
                      <EditableLabel
                        title={'Phone'}
                        name={Provider.mobilePhone}
                        label={providerData?.provider?.mobilePhone}
                        placeholder={'310-222-2222'}
                        disabled={true}
                        edit={!providerData?.provider}
                      />
                    </Grid.Column>
                    <Grid.Column
                      className={
                        !providerData?.provider ? 'label-style ' : 'label-style'
                      }
                    >
                      <FormItem
                        label="Pet Allergies"
                        labelAlign="left"
                        className={
                          'grid-column-emp-provider-opt inner-Grid-last-cont provider-label'
                        }
                      >
                        <Controller
                          control={control}
                          name={Provider.petAllergies}
                          render={(
                            fieldProps: ControllerRenderProps<IProvider>
                          ) => (
                            <Select
                              options={providerAllergies}
                              placeholder="Select Allergies"
                              field={fieldProps}
                              errors={methods.errors}
                              mode="multiple"
                              value={undefined}
                            />
                          )}
                        />
                      </FormItem>
                    </Grid.Column>

                    <Grid.Column className="info-last-container">
                      <label
                        className={
                          !providerData?.provider
                            ? 'narrow-column-provider-opt provider-label-save'
                            : 'narrow-column-provider-opt basic-cont provider-label'
                        }
                      >
                        Provider Address
                      </label>
                      <Grid.Row className="basic-cont">
                        <FormItem
                          // label="Address(Line 1)"
                          labelAlign="left"
                          className={'grid-column-appt address-inpt'}
                        >
                          <Controller
                            control={methods.control}
                            name={Provider.addressLine1}
                            label="Address(Line 1)"
                            render={(
                              fieldProps: ControllerRenderProps<IProvider>
                            ) => (
                              <Input
                                className="input-provider-address"
                                field={fieldProps}
                                value={methods.getValues('addressLine1')}
                                placeholder="Street Address"
                                name={Provider.addressLine1}
                                onChange={e => handleAddressChange(e, true)}
                                disabled={true}
                              />
                            )}
                          />
                          <div className="error-grid">
                            {methods.errors?.addressLine1?.message}
                          </div>
                        </FormItem>
                      </Grid.Row>
                      <Grid.Row>
                        <FormItem
                          labelAlign="left"
                          className={'grid-column-appt address-inpt-sec'}
                        >
                          <Controller
                            control={control}
                            name={Provider.addressLine2}
                            render={(
                              fieldProps: ControllerRenderProps<IProvider>
                            ) => (
                              <Input
                                field={fieldProps}
                                placeholder="Apt,Suit,Unit(Optional)"
                                className="input-provider-address"
                                value={methods.getValues('addressLine2')}
                                onChange={e => handleAddressChange(e, false)}
                                disabled={true}
                              />
                            )}
                          />
                        </FormItem>
                      </Grid.Row>
                      <Grid.Column className="city-row">
                        <FormItem
                          labelAlign="left"
                          className={'grid-column-appt address-inpt'}
                        >
                          <Controller
                            control={control}
                            name={Provider.city}
                            render={(
                              fieldProps: ControllerRenderProps<IProvider>
                            ) => (
                              <Input
                                field={fieldProps}
                                placeholder={'City'}
                                value={methods.getValues('city')}
                                name={Provider.city}
                                errors={methods.errors?.city?.message}
                                className="input-provider-address"
                                onChange={handleCityChange}
                                disabled={true}
                              />
                            )}
                          />
                          <div className="error-grid">
                            {methods.errors?.city?.message}
                          </div>
                        </FormItem>
                      </Grid.Column>
                      <Grid.Row className="provider-state-row">
                        <Grid.Column className="provider-state-col">
                          <FormItem
                            style={{ width: '100%' }}
                            labelAlign="left"
                            className={'grid-column-appt '}
                          >
                            <Controller
                              control={control}
                              name={Provider.state}
                              placeholder={'State'}
                              render={(
                                fieldProps: ControllerRenderProps<IProvider>
                              ) => (
                                <Select
                                  options={stateOptions}
                                  placeholder="State"
                                  field={fieldProps}
                                  errors={methods.errors}
                                  name={Provider.state}
                                  className="State-Select"
                                  onSelect={handleStateChange}
                                  disabled={true}
                                />
                              )}
                            />
                          </FormItem>
                        </Grid.Column>
                        <Grid.Column className="provider-zip-col">
                          <FormItem
                            style={{ width: '100%' }}
                            labelAlign="left"
                            className={'grid-column-appt'}
                          >
                            <Controller
                              control={control}
                              name={Provider.zipCode}
                              render={fieldProps => {
                                return (
                                  <Input
                                    field={fieldProps}
                                    placeholder="Zip Code"
                                    className="input-provider-address"
                                    onChange={handleZipCodeChange}
                                    value={methods.getValues('zipCode')}
                                    name={Provider.zipCode}
                                    disabled={true}
                                  />
                                );
                              }}
                            />
                            <div className="error-grid">
                              {methods.errors?.zipCode?.message}
                            </div>
                          </FormItem>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <Grid className={'provider-details-container'}>
                  <Grid.Row className={'grid-row'}>
                    <Grid.Column>
                      <label className="basic-info">Provider Details</label>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row className={'grid-row'}>
                    <Grid.Column
                      className="narrow-column"
                      style={{
                        height: '32px',
                        padding: '6px 15px',
                        alignItems: 'center'
                      }}
                    >
                      <EditableLabel
                        title={'Status:'}
                        name={Provider.status}
                        disabled={true}
                        edit={false}
                        label={providerData?.provider?.status || 'N/A'}
                      />
                    </Grid.Column>
                    <Grid.Column className="narrow-column">
                      <FormItem
                        label="On Accommodation"
                        labelAlign="left"
                        className={'grid-column-emp'}
                      >
                        <Controller
                          control={methods.control}
                          name={Provider.onAccommodation}
                          render={(
                            fieldProps: ControllerRenderProps<IProvider>
                          ) => (
                            <Select
                              value={fieldProps.value}
                              onChange={(selectedValue: String) =>
                                fieldProps.onChange(selectedValue)
                              }
                              options={trueFalseDropdownOptions}
                              placeholder="Accommodation"
                              field={fieldProps}
                              errors={methods.errors}
                            />
                          )}
                        />
                      </FormItem>
                    </Grid.Column>

                    {getFeatureAvailability(FEATURES.SHADOW_SCHEDULING) && (
                      <Grid.Column className="narrow-column">
                        <FormItem
                          label="Observable"
                          labelAlign="left"
                          className={'grid-column-emp'}
                        >
                          <Controller
                            control={control}
                            name={Provider.isObservable}
                            defaultValue={providerData?.provider?.isObservable}
                            render={(
                              fieldProps: ControllerRenderProps<IProvider>
                            ) => (
                              <Select
                                value={fieldProps.value}
                                onChange={(selectedValue: String) =>
                                  fieldProps.onChange(selectedValue)
                                }
                                options={trueFalseDropdownOptions}
                                placeholder="Status"
                                field={fieldProps}
                                errors={methods.errors}
                              />
                            )}
                          />
                        </FormItem>
                      </Grid.Column>
                    )}
                    <Grid.Column className="narrow-column">
                      <FormItem
                        label="Employment"
                        labelAlign="left"
                        className={'grid-column-emp'}
                      >
                        <Controller
                          control={methods.control}
                          name={Provider.employment}
                          render={(
                            fieldProps: ControllerRenderProps<IProvider>
                          ) => (
                            <Select
                              options={employmentOptions}
                              onSelect={onEmploymentSelect}
                              placeholder="Employment"
                              field={fieldProps}
                              errors={methods.errors}
                            />
                          )}
                        />
                      </FormItem>
                    </Grid.Column>

                    <Grid.Column className="narrow-column">
                      <EditableLabel
                        title={'Capacity Hours :'}
                        name={Provider.capacity}
                        disabled={
                          status === PROVIDER_STATUS_LIST.TERMINATED ||
                          status === PROVIDER_STATUS_LIST.LEAVE_OF_ABSENCE
                        }
                        edit={true}
                        label={`${providerData?.provider?.providerCapacity?.capacity}`}
                      />
                    </Grid.Column>
                    <Grid.Column className="narrow-column">
                      <EditableLabel
                        title={'Ramping Percentage :'}
                        name={Provider.rampingPercentage}
                        disabled={true}
                        edit={true}
                      />
                    </Grid.Column>
                    <Grid.Column className="narrow-column">
                      <FormItem
                        label="Test Provider"
                        labelAlign="left"
                        className={'grid-column-emp'}
                      >
                        <Controller
                          control={control}
                          name={Provider.isTestProvider}
                          defaultValue={providerData?.provider?.isTestProvider}
                          render={(
                            fieldProps: ControllerRenderProps<IProvider>
                          ) => (
                            <Select
                              value={fieldProps.value}
                              onChange={(selectedValue: String) =>
                                fieldProps.onChange(selectedValue === 'true')
                              }
                              options={trueFalseDropdownOptions}
                              placeholder="Is Test Provider"
                              field={fieldProps}
                              errors={methods.errors}
                            />
                          )}
                        />
                      </FormItem>
                    </Grid.Column>
                    <Grid.Column className="narrow-column">
                      <FormItem
                        label="Cortica Programs"
                        labelAlign="left"
                        className={'grid-column-emp'}
                      >
                        <Controller
                          control={control}
                          name={Provider.corticaPrograms}
                          render={(
                            fieldProps: ControllerRenderProps<IProvider>
                          ) => (
                            <Select
                              options={allCorticaProgramOptionsOptions}
                              placeholder="Select Cortica Programs"
                              field={fieldProps}
                              errors={methods.errors}
                              mode="multiple"
                            />
                          )}
                        />
                      </FormItem>
                    </Grid.Column>
                    <Grid.Column className="wide-column">
                      <FormItem
                        label="Languages"
                        labelAlign="left"
                        className={'grid-column-emp'}
                      >
                        <Controller
                          control={control}
                          name={Provider.languages}
                          render={(
                            fieldProps: ControllerRenderProps<IProvider>
                          ) => (
                            <MultiSelectDropDown
                              placeholder={
                                <div>
                                  <span style={{ marginRight: '14px' }}>
                                    Search Languages
                                  </span>
                                  <img
                                    src={searchIcon}
                                    className="search-icon-waitList"
                                    alt=""
                                  />
                                </div>
                              }
                              maxTagCount={1}
                              dropdownClassName={'custom-dropdown-select'}
                              optionLabelProp="label"
                              header={
                                <div className="Provider-select-header">
                                  {CLIENT_LANGUAGES_HEADER}
                                </div>
                              }
                              handleChange={handleProviderLanguageSelect(
                                PROVIDER_EDIT_TYPE.languages,
                                'name'
                              )}
                              filterOption={(input: any, option: any) =>
                                option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              className={'provider-select-languages'}
                              overAllOptions={languages}
                              selectedOptions={fieldProps?.value || []}
                              selectId={id}
                              selectType={PROVIDER_EDIT_TYPE.languages}
                              displayContent={'name'}
                              optionMapType={'name'}
                              key={languages}
                            ></MultiSelectDropDown>
                          )}
                        />
                      </FormItem>
                    </Grid.Column>
                    <Grid.Column className="wide-column">
                      <FormItem
                        label="Certifications"
                        labelAlign="left"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'left'
                        }}
                      >
                        <List>
                          {certificationOptions.map(opt => (
                            <ListItem disableGutters>
                              {`${opt.certificationName}: `}
                              <TextField
                                style={{
                                  marginLeft: '10px'
                                }}
                                disabled
                                value={opt.date ?? `MM/DD/YYYY`}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </FormItem>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row columns={4} className={'grid-row'}>
                    <Grid.Column className="wide-column">
                      <FormItem
                        label="Home Clinic"
                        labelAlign="left"
                        className={'grid-column-speciality'}
                      >
                        <Controller
                          control={control}
                          name={`${Provider.clinic}.id`}
                          render={(
                            fieldProps: ControllerRenderProps<IProvider>
                          ) => (
                            <Select
                              options={clinicsOptions}
                              placeholder="Home Clinic"
                              field={fieldProps}
                              errors={methods.errors}
                              onSelect={checkClinicSelect}
                            />
                          )}
                        />
                      </FormItem>
                      {!onChangeClinic && (
                        <label className={'warning'}>
                          {`Please remove the Supervisor-assistant mapping before updating the Clinic`}
                        </label>
                      )}
                    </Grid.Column>
                    <Grid.Column className="wide-column">
                      <FormItem
                        label="Licensed For"
                        labelAlign="left"
                        className={'grid-column-speciality'}
                      >
                        <Controller
                          control={control}
                          name={Provider.telehealth}
                          render={() => (
                            <TelehealthSelect
                              telehealthLink={telehealth || getTelehealthLink()}
                            ></TelehealthSelect>
                          )}
                        />
                      </FormItem>
                    </Grid.Column>
                    <Grid.Column className="narrow-column">
                      <FormItem
                        label="Transportation"
                        labelAlign="left"
                        className={'grid-column-emp'}
                      >
                        <Controller
                          control={control}
                          name={Provider.transportation}
                          defaultValue={providerData?.provider?.transportation}
                          render={(
                            fieldProps: ControllerRenderProps<IProvider>
                          ) => (
                            <Select
                              value={fieldProps.value}
                              onChange={(selectedValue: String) =>
                                fieldProps.onChange(selectedValue)
                              }
                              options={trueFalseDropdownOptions}
                              placeholder="transportation"
                              field={fieldProps}
                              errors={methods.errors}
                            />
                          )}
                        />
                      </FormItem>
                    </Grid.Column>
                    <Grid.Column className="narrow-column">
                      <EditableLabel
                        title={'Athena ID :'}
                        name={Provider.athenaId}
                        label={`${providerData?.provider?.athenaId}`}
                        placeholder={'Athena ID'}
                        disabled={false}
                        edit={true}
                      />
                    </Grid.Column>
                    <Grid.Column className="narrow-column">
                      <EditableLabel
                        title={'CR ID :'}
                        name={Provider.crId}
                        label={`${providerData?.provider?.crId}`}
                        placeholder={'CR ID'}
                        disabled={false}
                        edit={true}
                      />
                    </Grid.Column>
                    <Grid.Column className="narrow-column">
                      <EditableLabel
                        title={'Salesforce ID :'}
                        name={Provider.salesForceId}
                        label={`${providerData?.provider?.salesForceId}`}
                        placeholder={'Salesforce ID'}
                        disabled={false}
                        edit={true}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row columns={4} className={'grid-row-speciality'}>
                    <Grid.Column className="wide-column">
                      <FormItem
                        label="Speciality"
                        labelAlign="left"
                        className={'grid-column-speciality'}
                      >
                        <Controller
                          control={control}
                          name={`${Provider.speciality}.id`}
                          render={(
                            fieldProps: ControllerRenderProps<IProvider>
                          ) => (
                            <Select
                              options={specialityOptions}
                              placeholder="Speciality"
                              field={fieldProps}
                              errors={methods.errors}
                              onSelect={onSpecialitySelect}
                            />
                          )}
                        />
                      </FormItem>
                    </Grid.Column>
                    {isDataLoaded ? (
                      <Grid.Column className="narrow-column-supervisor ">
                        <EditableLabel
                          title={'Supervisor:'}
                          name={Provider.assistantsInput}
                          className={'grid-column-supervisor'}
                          label={
                            onAssistantView && onAssistantView.length > 0
                              ? onAssistantView[0].label
                              : ''
                          }
                          placeholder={'SuperVisor'}
                          disabled={true}
                          edit={false}
                        />
                      </Grid.Column>
                    ) : (
                      <Grid.Column className="narrow-column assistant-base-col">
                        <FormItem
                          label="Assistant(s)"
                          labelAlign="left"
                          className={'grid-column-assitants'}
                        >
                          {fields.map((item, index) => {
                            return (
                              <div
                                key={`${item.id} + ${index}`}
                                className={'grid-column-assitant-items'}
                              >
                                <div className={'grid-column-assitant-item'}>
                                  <Controller
                                    as={
                                      <Select
                                        options={onAssistantSelect}
                                        field={undefined}
                                        errors={undefined}
                                      />
                                    }
                                    control={control}
                                    name={`assistantsInput[${index}].name`}
                                    showSearch
                                    placeholder={
                                      <p style={{ paddingTop: '5px' }}>
                                        <Icon name="search"></Icon>
                                        {`Assistant ${index + 1}`}
                                      </p>
                                    }
                                    filterOption={(
                                      input: string,
                                      option: any
                                    ) =>
                                      option?.children
                                        ?.toLowerCase()
                                        ?.indexOf(input?.toLowerCase()) >= 0
                                    }
                                  />
                                </div>
                                <div className="trash-icon-assistant">
                                  <div className={'append-icon-assitant'}>
                                    <div>
                                      <img
                                        src={plus}
                                        alt="Add Item"
                                        className="assistant-inc-btn"
                                        onClick={() => {
                                          if (onAssistantSelect?.length) {
                                            append({
                                              name: '',
                                              title: '',
                                              id: '',
                                              default: false
                                            });
                                            setChangeClinic(true);
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <img
                                    src={Trash}
                                    alt="Trash Icon"
                                    className={
                                      'trash-icon-assistants asst-trash-icon '
                                    }
                                    onClick={() => {
                                      remove(index);
                                      setChangeClinic(true);
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                          {fields?.length <= 0 && (
                            <div className={'append-icon-assitant-sec'}>
                              <div>
                                <img
                                  src={plus}
                                  alt="Add Item"
                                  className="assistant-inc-btn"
                                  onClick={() => {
                                    if (onAssistantSelect?.length) {
                                      append({
                                        name: '',
                                        title: '',
                                        id: '',
                                        default: false
                                      });
                                      setChangeClinic(true);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </FormItem>
                      </Grid.Column>
                    )}
                    <Grid.Column className={'grid-row-links custom-grid-width'}>
                      <FormItem
                        label="Telehealth Link(s)"
                        labelAlign="left"
                        className={'grid-column-links'}
                      >
                        <div className="width-100">
                          <TelehealthLinksInput
                            providerName={providerData?.provider?.firstName}
                            telehealthLinks={telehealthLinks}
                            setTelehealthLinks={setTelehealthLinks}
                            setFormPrestine={setFormPrestine}
                            setfilteredLinks={setfilteredLinks}
                          ></TelehealthLinksInput>
                        </div>
                      </FormItem>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row className={'grid-row'}>
                    <Grid.Column className="extra-wide-colummn">
                      <FormItem
                        label="Appointment Type(s)"
                        labelAlign="left"
                        className={'grid-column-appt'}
                        style={{ width: '100%' }}
                      >
                        <Controller
                          control={control}
                          name={Provider.appointmentTypes}
                          // defaultValue={
                          //   providerData?.provider?.appointmentTypes.title
                          // }
                          // defaultValue
                          render={(
                            fieldProps: ControllerRenderProps<IProvider>
                          ) => (
                            <Select
                              options={activeAppointmentTypesOptions}
                              placeholder="Appointment Type(s)"
                              mode="multiple"
                              field={fieldProps}
                              errors={methods.errors}
                              filterOption={false}
                            />
                          )}
                        />
                      </FormItem>
                    </Grid.Column>
                    {providerData?.provider?.speciality?.id ===
                      NURSE_PRACTITIONER_SPECIALTY_ID_MAPPING[
                        process.env.REACT_APP_STAGE || 'DEV'
                      ] && (
                      <Grid.Column className="wide-column">
                        <FormItem
                          label="Allow Self Scheduling"
                          labelAlign="left"
                          className={'grid-column-allow-self-scheduling'}
                        >
                          <Controller
                            control={control}
                            name={Provider.allowSelfScheduling}
                            render={(
                              fieldProps: ControllerRenderProps<IProvider>
                            ) => (
                              <Checkbox
                                toggle
                                defaultChecked={fieldProps.value}
                                onChange={toggleAllowSelfScheduling}
                              />
                            )}
                          />
                        </FormItem>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                </Grid>
              </Grid>
            </FormProvider>
          </ContentWrapper>
        )}
      </Content>
    </>
  );
};
export default React.memo(AddProvider);
